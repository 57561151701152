/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import Filter from "../../shared/Filter";
import MaterialDetail from "./MaterialDetail";
import useApiMaterial from "../../../api/catalogs/useApiMaterial";
import {AppContext} from "../../Main";

export default function Material() {
    const {t} = useTranslation();
    const api = useApiMaterial();

    const filtroDefault = {materialesIds: [], activoIds: []};

    const [grid, setGrid] = useState({data: []});
    const [materialId, setMaterialId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);

    const buscar = async (filtro) => {
        const result = await api.filter(filtro);
        setGrid({data: result});
    };

    const onSave = async () => {
        setAbrirDetalle(false);
        await buscar();
    };

    const verDetalle = (id) => {
        setTemporalId(Math.random())
        setMaterialId(id);
        setAbrirDetalle(true)
    };

    const HeaderAddButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => verDetalle(0)}>
                {t('Agregar')}
            </Button>
        </div>
    );

    const ViewDetailButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    );

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    return (
        <>
            <Filter titulo={t('Materiales')} valorDefault={filtroDefault} buscar={buscar}>
                <FormaFiltros/>
            </Filter>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            headerAddButton: HeaderAddButton,
                            estatusValueCell: EstatusValueCell,
                            viewDetailButton: ViewDetailButton,
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={grid.data}
                >
                    <AgGridColumn field="name" headerName={t('Material')}/>
                    <AgGridColumn field="valor" headerName={`${t("Valor")} ($ USD)`} cellClass="right"
                                  headerClass="ag-header-cell-center"/>
                    <AgGridColumn field="porcentajeOcupacion" headerName={`${t("Ocupación en transporte")} (%)`}
                                  cellClass="right" headerClass="ag-header-cell-center"/>
                    <AgGridColumn field="activo" headerName={t('Activo')} cellRenderer="estatusValueCell"/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <MaterialDetail materialId={materialId} open={abrirDetalle} temporalId={temporalId}
                            close={() => setAbrirDetalle(false)} onSave={onSave}/>
        </>
    );
}

const FormaFiltros = ({filtro, onChange}) => {
    const {t} = useTranslation();
    const {appLanguage} = useContext(AppContext);
    const estatusActivo = [{id: 1, name: t("Activo")}, {id: 0, name: t("Inactivo")}];

    useEffect(()=>{
        onChange("language", appLanguage)
    },[appLanguage])

    return (
        <>
            <FieldDropDownMultiselect label={t('Material')} name="materialesIds" source="materiales" sm={6} md={3}
                                      value={filtro.materialesIds} onChange={onChange} valueById
                                      filter={{language: filtro.language}}/>

            <FieldDropDownMultiselect label={t('Estatus')} name="estatusIds" options={estatusActivo} sm={6} md={3}
                                      value={filtro.estatusIds} onChange={onChange} valueById
                                      showSelectButtons={false}/>
        </>
    )
};
