/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

import {formatDate} from "../../../utility/dateUtility";
import {Button, TextField, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Delete, Edit, Save} from "@mui/icons-material";
import DialogUi from "../../shared/modal/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useApiViaje from "../../../api/operation/useApiViaje";
import useNotification from "../../../hooks/useNotification";
import useMessage from "../../../hooks/useMessage";

export default function TripComments({viajeId, plantaId, isReadonly}) {
    const {t} = useTranslation();
    const api = useApiViaje();
    const notification = useNotification();
    const message = useMessage();

    const [listaComentarios, setListaComentarios] = useState([]);
    const [comentarioSeleccionado, setComentarioSeleccionado] = useState({});
    const [errorComentario, setErrorComentario] = useState(false);
    const [modalAbierta, setModalAbierta] = useState(false);

    const loadComentarios = async () => {
        const result = await api.getComentarios(viajeId);
        setListaComentarios(result.comentarios);
    }

    useEffect(() => {
        loadComentarios().then();
    }, []);

    const openModalComentarios = (comentario) => {
        setComentarioSeleccionado(!comentario ? {id: 0, plantaId} : comentario);
        setModalAbierta(true);
    };

    const onChangeComentarios = ({target}) => {
        setComentarioSeleccionado({...comentarioSeleccionado, comentarios: target.value});
    }

    const guardarComentarios = async () => {
        if (!comentarioSeleccionado.comentarios) {
            setErrorComentario(true);
            notification.error(t('Favor de revisar la información requerida o inválida'));
            return;
        }

        await api.guardarComentario(viajeId, comentarioSeleccionado);
        await loadComentarios();
        setModalAbierta(false);
    }

    const eliminarComentario = async (id) => {
        await message.confirm(t("Se quitará el comentario"), t("¿Desea Continuar?"));

        await api.quitarComentario(id);

        const copiaComentarios = listaComentarios.filter(e => e.id !== id);
        setListaComentarios(copiaComentarios);
    }

    return (
        <>
            <table className="table-custom">
                <thead>
                <tr>
                    <th>{t("Usuario")}</th>
                    <th>{t("Fecha")}</th>
                    <th>{t("Comentarios")}</th>

                    <th style={{textAlign: "center", width: "90px"}}>
                        {!isReadonly &&
                        <Tooltip title={t('Agregar Comentarios')}>
                            <IconButton color="primary" onClick={() => openModalComentarios()}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                        }
                    </th>
                </tr>
                </thead>

                <tbody>
                {listaComentarios?.map(row => (
                    <tr key={row.id}>
                        <td>{row.usuario}</td>
                        <td>{formatDate(row.fecha, true)}</td>
                        <td>{row.comentarios}</td>

                        <td style={{textAlign: "center"}}>
                            {!isReadonly && row.sePuedeEditar &&
                            <>
                                <IconButton onClick={() => {
                                    openModalComentarios(row)
                                }}>
                                    <Edit color="success"/>
                                </IconButton>

                                <IconButton onClick={() => {
                                    eliminarComentario(row.id)
                                }}>
                                    <Delete color="error"/>
                                </IconButton>
                            </>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <DialogUi open={modalAbierta} title={"Guardar comentario"} onClose={() => setModalAbierta(false)}
                      width="sm">
                <Box>
                    <label>{t("Comentarios")}</label>
                    <TextField onChange={onChangeComentarios} multiline rows={3} fullWidth size="small"
                               value={comentarioSeleccionado.comentarios} error={errorComentario}
                               inputProps={{maxLength: 200}}/>
                </Box>

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>}
                            onClick={() => setModalAbierta(false)}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="primary" size="small" startIcon={<Save/>}
                            onClick={guardarComentarios}>
                        {t("Guardar")}
                    </Button>
                </div>
            </DialogUi>
        </>
    )
}
