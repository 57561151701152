/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react'
import {Autocomplete, Grid, TextField} from "@mui/material";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import useSourceUtils from "./useSouceUtils";
import {useTranslation} from "react-i18next";

const FieldDropDown = ({
                           xs, sm, md, lg, xl, label, name, options, source, onChange, value, className, required,
                           placeholder, valueById, error, filter, disabled, orderBy, disableClearable, inputWidth,
                           translate, variant,ignoreAllDisabled
                       }) => {

    const {t} = useTranslation();

    valueById = valueById ?? false;

    const {readOnlyContext} = useContext(ReadOnlyContext);
    const {errors} = useContext(ValidationContext);
    const {allDisabled} = useContext(ValidationContext);

    const {
        currentOptions,
        setSelectedId,
        getSelected
    } = useSourceUtils(value, options, source, filter, false, valueById, orderBy);

    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);

    function onDropChange(event, value) {
        setSelectedId([value?.id])
        fireChange(name, value)
    }


    function fireChange(itemName, itemValue) {
        if (onChange != null) {
            const value = itemValue == null ? null : (valueById === true ? itemValue.id : itemValue);
            onChange(itemName, value, itemValue);
        }
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{t(label)}</label>
                }
                <Autocomplete
                    fullWidth
                    value={getSelected() ?? {id: 0, name: ''}}
                    disableClearable={disableClearable}
                    options={currentOptions}
                    disabled={readOnly || (!ignoreAllDisabled && allDisabled)}
                    getOptionLabel={(option) => translate === true ? t(option?.name ?? '') : (option?.name ?? '')}
                    onChange={onDropChange}
                    classes={className}
                    renderInput={(params) =>
                        <TextField {...params} variant={variant || 'outlined'} size="small"
                                   error={error || errors?.[name]}/>
                    }
                />
            </Grid>
        </>
    )
};

export default FieldDropDown

