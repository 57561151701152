import React from "react";
import moment from "moment";

const DateTimeFormatter = ({value}) => (
    <>
        { value != null ? moment(value).format('MMM-DD-YY HH:mm') : '' }
    </>
);

export default DateTimeFormatter;
