import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from "@mui/material/ListItem";
import {Collapse, Tooltip} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";

import List from "@mui/material/List";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function MainMenuOption({menu, expanded}) {
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openList, setOpenList] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (menu.children.length === 0) return;

        if (expanded)
            setOpenList(!openList)
        else
            setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {menu.children.length > 0 ?
                <ListItem button onClick={handleClick} sx={{paddingLeft: '0'}}>
                    <Tooltip title={expanded ? '' : t(menu.title)} followCursor>
                        <ListItemIcon className="menuIcons" color="primary" sx={{width: 45, justifyContent: 'center'}}>
                            {menu.active ? menu.activeIcon : menu.icon}
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={t(menu.title)}/>
                    {(openList ? <ExpandLess/> : <ExpandMore/>)}
                </ListItem>
                :
                <ListItem button sx={{paddingLeft: '0'}}>
                    <Tooltip title={expanded ? '' : t(menu.title)} followCursor>
                        <Link to={menu.url} className="link-menu">
                            <ListItemIcon className="menuIcons" sx={{width: 45, justifyContent: 'center'}}>
                                {menu.active ? menu.activeIcon : menu.icon}
                            </ListItemIcon>
                            {t(menu.title)}
                        </Link>
                    </Tooltip>
                </ListItem>
            }

            <Collapse in={openList && expanded} timeout="auto" unmountOnExit>
                {menu.children.map(item =>
                    <List key={item.id} component="div" disablePadding>
                        <ListItem button>
                            <Link to={item.url} className="link-menu">
                                <ListItemIcon sx={{minWidth: 45}}>
                                    {item.icon}
                                </ListItemIcon>
                                {t(item.title)}
                            </Link>
                        </ListItem>
                    </List>
                )}
            </Collapse>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
            >
                {menu.children.map(item =>
                    <MenuItem key={item.id} onClick={handleClose}>
                        <Link to={item.url} className="link-menu">
                            {t(item.title)}
                        </Link>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
