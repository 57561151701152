import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, Modal
} from "@mui/material";

export const ConfirmContext = React.createContext({
  open: false, alert: {
    open: false,
    messages: [],
    severity: ''
  },
  loading:{
    open:false
  }
})

export const Confirm = function() {

  const { confirmContext, setConfirmContext } = useContext(ConfirmContext)
  //const [loading] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    // confirmContext.onRejectionHandler()
    setConfirmContext({ ...confirmContext, open: false })
  }

  const confirm = async () => {
    confirmContext.onConfirmHandler()
    setConfirmContext({ ...confirmContext, open: false })
  }

  return (
    <>
      <Dialog
          open={confirmContext.open}
      >
        <DialogTitle>{confirmContext.title}</DialogTitle>
        <DialogContent dividers>
          {confirmContext.message}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            {t('Cancelar')}
          </Button>
          <Button onClick={confirm}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Modal open={confirmContext.loading.open} className="modal-loading">
        <div className="loading-container">
          <div className="loading">
            <div style={{ paddingTop: '1rem' }}>
              <CircularProgress size={80} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}




