import * as yup from "yup";

const transferSchema = yup.object().shape({
    plantaId: yup.number().required(),
    almacenOrigenId: yup.number().required(),
    almacenDestinoId: yup.number().required(),
    areaOrigenId: yup.number().required(),
    areaDestinoId: yup.number().required(),
    fechaSolicitud: yup.date().nullable(),
    //requerimientos: yup.array().min(1,"Debe agregar al menos un tipo de rack")
})

export  default transferSchema;
