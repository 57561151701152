import padStart from "lodash/padStart";
import * as numeral from "numeral";

export const formatNumber = ( number, decimals, nanToNull ) =>{
    if (number == null)
        return null;

    if (isNaN(number) && nanToNull === true){
        return null;
    }
    return number?.toLocaleString(window.localStorage.i18nextLng, {minimumFractionDigits: decimals??2, maximumFractionDigits:decimals??2 })
}

export const formatAsAccountingNumber = ( number, decimals, nanToNull ) =>{
    if (number == null)
        return null;

    if (isNaN(number) && nanToNull === true){
        return null;
    }
    const positiveValue = (Math.abs(number))?.toLocaleString(window.localStorage.i18nextLng, {minimumFractionDigits: decimals??2, maximumFractionDigits:decimals??2 });
    return `${number < 0 ? '(' : ''}${positiveValue}${number < 0 ? ')' : ''}`


}

export const formatDecimal = ( number, decimals, nanToNull ) =>{
    if (number == null)
        return null;

    if (isNaN(number) && nanToNull === true){
        return null;
    }

    const decimalsString = decimals === 0? '': padStart('0', decimals?? 2, '0')

    return numeral(number).format('0,0.' + decimalsString);
}
