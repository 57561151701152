/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import SidebarRight from "../../shared/modal/SidebarRight";
import {useEffect, useState} from "react";
import {ValidationContext} from "../../shared/validationContext";
import {Button, Checkbox, FormControlLabel, Grid, InputAdornment, Collapse} from "@mui/material";
import FieldDateTime from "../../shared/fields/FieldDateTime";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import produce from "immer";
import {newGuid, setDeepValue} from "../../../utility/objectUtility";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import useValidate from "../../../hooks/useValidate";
import rackManagementPurchaseSchema from "../rackManagmentPurchase/rackManagementPurchaseValidation";
import useMessage from "../../../hooks/useMessage";
import {Print} from "@mui/icons-material";
import useApiRackManagementPurchase from "../../../api/settings/useApiRackManagmentPurchase";
import {removeAtIndex} from "../../../utility/arrayUtility";
import padStart from "lodash/padStart";
import useNotification from "../../../hooks/useNotification";
import rackManagementPurchasePrintValidation from "./rackManagementPurchasePrintValidation";
import {getCoordenada, zplPrintCode} from "../../../utility/zplPrintCode";
import FieldDropDown from "../../shared/fields/FieldDropDown";

export default function RackManagementPurchaseDetail( {open, close, onChangeRack,temporalId,onSave,compraId}) {
    const defaultEntity = {id: 0, tipoRacks: [],activo:true};
    const {t} = useTranslation();
    const [title, setTitle] = useState('');
    const [errors, setErrors] = useState({});
    const [compra, setCompra] = useState(defaultEntity);
    const [muestraTablaImpresion, setMuestraTablaImpresion] = useState(false);
    const [repeatedRack, setRepeatedRack] = useState(false);
    const [racksImprimir, setRacksImprimir] = useState([]);
    const [validate, clearValidation] = useValidate(rackManagementPurchaseSchema, compra, setErrors);
    const [validatePrint, clearValidationPrint] = useValidate(rackManagementPurchasePrintValidation, racksImprimir, setErrors);
    const message = useMessage();
    const api = useApiRackManagementPurchase()
    const notification = useNotification();
    const opcionesCopias = [{id: 1, name: "1"}, {id: 2, name: "2"}];
    useEffect(() => {

        const load = async (id) => {
            clearValidation();
            clearValidationPrint();
            const result = await api.getById(id);
            setCompra(result);
        }

        if (compraId > 0) {
            setTitle(t("Editar Orden de Compra"));
            load(compraId).then();
        } else {
            setTitle(t("Agregar Orden de Compra"));
            setCompra(defaultEntity);
        }
        setMuestraTablaImpresion(false);
        clearValidation();
    },[temporalId])


    const onChange = (name, value, idChanged) => {
        const newEntity = produce(compra, (draft) => {
            setDeepValue(name, draft, value);
            var splittedName = name.split('.');

            if(name==='activo')
            {
                draft.tipoRacks.forEach(a=>{
                    a.racks.forEach(b=>{
                        b.activo=value
                    })
                })
            }
            if(splittedName.length===3 && splittedName[2]==='activo')
            {
                let activeRacks =0;
                draft.tipoRacks.forEach(a=>{
                    a.racks.forEach(b=>{
                        if(b.activo)
                            activeRacks++;
                    })
                })
                draft.activo= activeRacks>0;
            }
        });

        setCompra(newEntity);
    };

    const onChangeImprimir = (name, value, index) => {
        const newEntity = produce(racksImprimir, (draft) => {
            draft[index][name] = value;
        });

        setRacksImprimir(newEntity);
    };

    const onChangeNumeroRack = (id, index) => (name, value) => {
        const rackRepetido = compra.tipoRacks.some((e, i) => i !== index && e.numeroRack === value);

        const racksCopy = compra.tipoRacks.map((e, i) => {
                return i === index ? {...e, numeroRack: value, repeated: rackRepetido} : {...e, repeated: false};
            }
        );

        setRepeatedRack(racksCopy.some(e => e.repeated));
        onChange(name, value, id);
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;

        const newCompra = produce(compra, (draft) => {
            let racks = [];

            compra.tipoRacks.forEach(e => {
                const newRacks = e.racks.map(r => (
                    {
                        id: r.id,
                        cantidad: r.cantidad,
                        numeroRack: r.numeroRack,
                        tipoRack: r.tipoRack,
                        tipoRackId: r.tipoRackId,
                        activo: r.activo,
                    }));
                newRacks.forEach(e => {
                    racks.push(e);
                })
            })
            draft.racks = racks;

        })

        let existeRepetido = false;

        newCompra.tipoRacks.map((tipoRack, index) => {
            tipoRack.racks.map((rack, index2) => {
                let repeated = tipoRack.racks.some((r, i) => index2 !== i && rack.numeroRack === r.numeroRack)
                if (repeated)
                    existeRepetido = true;
                return true;
            })
            return true;
        })

        if (existeRepetido) {
            notification.warning(t("Existen racks repetidos, favor de verificar la información"))
            return
        }

        let errores = await api.validate({id: newCompra.id, ordenCompra: newCompra.ordenCompra,racks: newCompra.racks})
        if (errores.length > 0)
        {
            errores = obtenerMensajeError(errores);
            notification.warning(errores.join(", "));
            return;
        }

        let result = await api.save(newCompra)
        if(result.length===0)
        {
            onClose();
            onSave();
        }
        else
        {
            setRacksImprimir(result);
            let response = await message.confirmReject(t("¿Desea imprimir las etiquetas de los racks dados de alta?"), t("¿Desea Continuar?"));
            if (response === true) {
                const newEntity = produce(compra, (draft) => {
                    draft.cantidadHeader=1;
                    draft.imprimirHeader=true;
                });
                setCompra(newEntity);
                setMuestraTablaImpresion(true);
            } else {
                onClose();
                onSave();
            }
        }
    }

    const obtenerMensajeError = (errores) =>{
        let newErrores = [];
        for (let i=0;i< errores.length; i++)
        {
            let error = errores[i].split("|");
            let mensaje = t(error[0]).replace("#OC#",error[1]).replace("#RACK#",error[1]);
            newErrores.push(mensaje);
        }
        return newErrores;
    }

    const obtenerNumeroRack = (index)=>{
        let consecutivo = 1;

        if(compra.tipoRacks[index]!==undefined)
        {
            if (compra.tipoRacks[index].racks.length > 0) {
                const numbers = compra.tipoRacks[index].racks.map(e => {
                    return /^\d+$/.test(e.numeroRack) ? Number(e.numeroRack) : 0;
                });
                consecutivo = Math.max(...numbers) + 1;
            }
        }
        return  padStart(consecutivo, (consecutivo.toString().length>4)?8:4, '0');
    }

    const deleteRack = async (index, rowIndex) => {
        let response = await message.confirmReject(t("¿Está seguro que desea eliminar el rack?"), t("¿Desea Continuar?"));
        if(response===true)
        {
            if (compra.tipoRacks[index].racks.length === 1) {
                onChange(`tipoRacks`, removeAtIndex(compra.tipoRacks, index))
            } else
                onChange(`tipoRacks[${index}].racks`, removeAtIndex(compra.tipoRacks[index].racks, rowIndex))
        }

    }

    const deleteTipoRack = async(index)=> {
        let response = await message.confirmReject(t("¿Está seguro que desea eliminar los racks de este modelo?"), t("¿Desea Continuar?"));
        if (response === true)
        {
            onChange(`tipoRacks`, removeAtIndex(compra.tipoRacks, index))
        }
    }

    const imprimir = async () => {
        const valid = await validatePrint();
        if (!valid) return;

        const racksaImprimir = racksImprimir.filter(e => e.imprimir);
        const totalImpresiones = racksaImprimir.reduce((sum, e) => sum + (e.cantidad ? e.cantidad : 0), 0);
        const totalRacks = racksaImprimir.length;
        if(racksaImprimir.length===0)
        {
            notification.warning(t("Favor de seleccionar al menos un rack para imprimir"))
            return;
        }

        await message.confirm(t("Imprimir"),
            `${t("Total de racks")}: ${totalRacks}. ${t("Total de etiquetas")}: ${totalImpresiones}, ${t("¿Desea Continuar?")}`)

        racksaImprimir.forEach(e => {
            let rfid = `B${padStart(e.id, 6, '0')}C${e.numeroRack}`;
            rfid = e.numeroRack.length > 4 ? rfid : `A${e.numeroSap}${rfid}`;
            rfid = padStart(rfid, 24, '0');

            const variables = {
                "#Alias#": e.alias,
                "#Descripcion#": e.descripcion,
                "#NumeroRack#": e.numeroRack,
                "#NumeroSap#": e.numeroSap,
                "#RFID#": rfid,
                "#Coordenada1#": getCoordenada(e.alias),
                "#Coordenada2#": getCoordenada(e.descripcion),
                "#Coordenada3#": e.numeroRack.length === 4 ? 130 : 95,
                "#Coordenada4#": e.numeroRack.length === 4 ? 82 : 42,
                "#Copias#": e.cantidad
            };

            const expresion = new RegExp(Object.keys(variables).join("|"), "gi");
            const codigoImpresion = zplPrintCode.replace(expresion, matched => variables[matched]);

            console.log(codigoImpresion);
        });

        // eslint-disable-next-line no-undef
        BrowserPrint.getDefaultDevice("printer", function (device) {
            if (device.name) {
                racksaImprimir.forEach(e => {
                    let rfid = `B${padStart(e.id, 6, '0')}C${e.numeroRack}`;
                    rfid = e.numeroRack.length > 4 ? rfid : `A${e.numeroSap}${rfid}`;
                    rfid = padStart(rfid, 24, '0');
                    const variables = {
                        "#Alias#": e.alias,
                        "#Descripcion#": e.descripcion,
                        "#NumeroRack#": e.numeroRack,
                        "#NumeroSap#": e.numeroSap,
                        "#RFID#": rfid,
                        "#Coordenada1#": getCoordenada(e.alias),
                        "#Coordenada2#": getCoordenada(e.descripcion),
                        "#Coordenada3#": e.numeroRack.length === 4 ? 130 : 95,
                        "#Coordenada4#": e.numeroRack.length === 4 ? 82 : 42,
                        "#Copias#": e.cantidad
                    };

                    const expresion = new RegExp(Object.keys(variables).join("|"), "gi");
                    const codigoImpresion = zplPrintCode.replace(expresion, matched => variables[matched]);

                    device.send(codigoImpresion, undefined, function (errorMessage) {
                        notification.error(errorMessage);
                    });
                })
            } else {
                notification.warning(t("La impresora no esta configurada"));
            }
        });
        onClose();
        onSave();
    }

    const addRack = (index,tipoRack)=>{

        let numeroRack = obtenerNumeroRack(index);
        onChange(`tipoRacks[${index}].racks`, [{
            _guid: newGuid(),
            tipoRack: tipoRack.tipoRack,
            tipoRackId: tipoRack.tipoRackId,
            numeroRack: numeroRack,
            activo:true,
            cantidad:1,
            id:0
        }, ...compra.tipoRacks[index].racks])
    }

    const addTipoRack =  () => {
        if (compra.cantidad === null || compra.cantidad === 0 || compra.tipoRack === null) {
            notification.warning(t("Favor de seleccionar el Tipo de Rack y la Cantidad"))
            return
        }
        let newRacks = [];
        let index = compra.tipoRacks.findIndex(a => a.tipoRackId === compra.tipoRack.id);
        let numeroRack = obtenerNumeroRack(index);
        for (let i = 0; i < compra.cantidad; i++) {
            newRacks.push({
                _guid: newGuid(),
                tipoRack: compra.tipoRack.name,
                tipoRackId: compra.tipoRack.id,
                numeroRack: numeroRack,
                activo: true,
                cantidad: 1,
                id: 0
            })

            let consecutivo = /^\d+$/.test(numeroRack) ? Number(numeroRack) : 0;
            consecutivo += 1;
            numeroRack = padStart(consecutivo, (consecutivo.toString().length>4)?8:4, '0');
        }

        if (index === -1) {
            onChange('tipoRacks', [
                {
                    _guid: newGuid(),
                    cantidad: compra.cantidad,
                    tipoRackId: compra.tipoRack.id,
                    tipoRack: compra.tipoRack.name,
                    expandido: true,
                    racks: newRacks
                }, ...compra.tipoRacks])
        } else {
            onChange(`tipoRacks[${index}].racks`, [...newRacks, ...compra.tipoRacks[index].racks])
        }

    }

    const onClose =()=>{
        setMuestraTablaImpresion(false);
        close();
        onSave();
    }

    const obtenerTotalCantidad = (index)=>{
        return compra.tipoRacks[index].racks.length;

    }

    const onChangeImprimirHeader=(name,value)=>{

        const newEntity = produce(racksImprimir, (draft) => {
            draft.forEach((a,i) => {
                if(name==='cantidadHeader')
                {
                    a.cantidad = value;
                }
                else
                {
                    a.imprimir = value;
                }

            })
        });

        onChange(name,value);
        setRacksImprimir(newEntity);


    }

    return (
        <>
            <SidebarRight title={t(title)} open={open} close={onClose} size={800}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    {!muestraTablaImpresion &&
                    <div>

                        <Grid container spacing={2} mb={2}>
                            <FieldDateTime label={t("Fecha de Compra")} name="fechaCompra" value={compra.fechaCompra}
                                           xs={6} onChange={onChange}/>

                            <FieldTextBox label={t("OC")} name="ordenCompra" value={compra.ordenCompra} xs={6}
                                          onChange={onChange} maxLength={20}/>

                            <FieldAutoComplete label={t('Proveedor')} name={`proveedorId`} value={compra.proveedorId}
                                               xs={6}
                                               source="proveedores" valueById onChange={onChange}/>

                            <FieldTextBox label={t("Precio")} name="precio" value={compra.precio} xs={6}
                                          isNumeric precision={2} onChange={onChange} maxLength={12}/>

                        </Grid>
                        <br/>
                        <Grid container spacing={2} mb={2} style={{paddingLeft:"20px"}}>
                            <FormControlLabel label={t("Activo")} xs={6}
                                              control={<Checkbox checked={compra.activo} onChange={(e, value) => {
                                                  onChange("activo", value)
                                              }}/>
                                              }
                            />
                        </Grid>
                        <br/>
                        <Grid container spacing={2} mb={2}>
                            <FieldAutoComplete label={t('Tipo de Rack')} name="tipoRack" value={compra.tipoRackId}
                                               xs={7}
                                               source="tiposRack" onChange={(name, value) => {
                                onChange(name, value)
                            }} variant="standard"/>

                            <FieldTextBox label={t("Cantidad")} name="cantidad" value={compra.cantidad} xs={4}
                                          isNumeric precision={2} onChange={onChange} variant="standard" maxLength={3}/>

                            <IconButton color="primary" xs={1} onClick={() => {
                                addTipoRack()
                            }}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Grid>
                        <br/>
                        <Box variant="label" sx={{fontWeight: 'bold'}}>
                            {t('Racks')}
                        </Box>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '60%'}}>
                                        {t('Nombre')}
                                    </TableCell>
                                    <TableCell sx={{width: '15%'}}>
                                        {t('Cantidad')}
                                    </TableCell>
                                    <TableCell sx={{width: '10%'}}>
                                        {t('Activo')}
                                    </TableCell>
                                    <TableCell sx={{width: '20%'}}>
                                        {t('Cantidad')}
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {compra.tipoRacks.map((tipoRack, index) => (
                                    <>
                                        <TableRow key={tipoRack.id}
                                                  sx={{'td': {border: 0}, backgroundColor: "lightgray"}}>
                                            <TableCell sx={{width: '2%', padding: 0}}>
                                                <IconButton size="small" onClick={() => {
                                                    onChange(`tipoRacks[${index}].expandido`, !tipoRack.expandido)
                                                }}>
                                                    {tipoRack.expandido ? <KeyboardArrowUpIcon/> :
                                                        <KeyboardArrowDownIcon/>}
                                                </IconButton>
                                                {tipoRack.tipoRack}
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                {obtenerTotalCantidad(index)}
                                            </TableCell>
                                            <TableCell align={"center"}>

                                            </TableCell>

                                            <TableCell align={"center"}>

                                                    <IconButton color="primary" xs={6} onClick={() => {
                                                        addRack(index,tipoRack);

                                                    }}>
                                                        <AddCircleOutlineIcon/>
                                                    </IconButton>

                                                {compra.tipoRacks[index].racks.length>0 &&
                                                    <IconButton color="error" xs={6}
                                                                onClick={() => deleteTipoRack(index)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                }






                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{padding: 0}} colSpan={4}>
                                                <Collapse in={tipoRack.expandido} timeout="auto" unmountOnExit>
                                                    <Table size="small">
                                                        <TableBody sx={{'tr:last-child td': {borderBottom: 0}}}>
                                                            {tipoRack.racks?.map((rack, rowIndex) => (
                                                                <>
                                                                    <TableRow key={rowIndex}>

                                                                        <TableCell sx={{width: '60%'}}>
                                                                            <FieldTextBox value={rack.numeroRack}
                                                                                          xs
                                                                                          variant="standard"
                                                                                          error={rack.repeated}
                                                                                          name={`tipoRacks[${index}].racks[${rowIndex}].numeroRack`}
                                                                                          onChange={onChangeNumeroRack(rack.id, rowIndex)}
                                                                                          helperText={rack.repeated ? t("El nombre del rack esta repetido") : ''}
                                                                                          startAdornment={
                                                                                              <InputAdornment
                                                                                                  position="start"
                                                                                                  sx={{pb: 0.5}}>
                                                                                                  {rack.tipoRack}-</InputAdornment>}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '15%'}} align={"right"}>
                                                                            {rack.cantidad}
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '15%'}}>
                                                                            {compra.activo}
                                                                            <Checkbox

                                                                                checked={rack.activo}
                                                                                      onChange={(e, value) => {
                                                                                          onChange(`tipoRacks[${index}].racks[${rowIndex}].activo`, value)
                                                                                      }}/>
                                                                        </TableCell>

                                                                        <TableCell sx={{width: '15%'}} align={"right"}>
                                                                            <IconButton color="error"
                                                                                        onClick={() => deleteRack(index,rowIndex)}>
                                                                                <DeleteIcon/>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>}

                    {muestraTablaImpresion &&
                    <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '50%'}}>
                                        {t('Nombre')}
                                    </TableCell>
                                    <TableCell sx={{width: '25%'}}>
                                        {t('Cantidad')}
                                        <FieldDropDown  variant="standard"
                                                        onChange={(e,value)=>{onChangeImprimirHeader('cantidadHeader',value)}}
                                                        options={opcionesCopias} value={compra.cantidadHeader}
                                                        valueById />
                                    </TableCell>
                                    <TableCell sx={{width: '25%'}}>
                                        {t('Imprimir')}
                                        <Checkbox checked={compra.imprimirHeader}
                                                  onChange={(e, value) => {
                                                      onChangeImprimirHeader('imprimirHeader', value)
                                                  }}/>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {racksImprimir.map((rack,index)=>(
                                    <>
                                        <TableRow key={rack.id} sx={{'td': {border: 0}}}>
                                            <TableCell>
                                                {rack.nombre}
                                            </TableCell>
                                            <TableCell align="right">
                                                <FieldDropDown  variant="standard"
                                                               onChange={(e,value)=>{onChangeImprimir('cantidad',value,index)}}
                                                               options={opcionesCopias} value={rack.cantidad}
                                                               valueById />

                                            </TableCell>
                                            <TableCell>
                                                <Checkbox checked={rack.imprimir}
                                                          onChange={(e, value) => {
                                                              onChangeImprimir('imprimir', value, index)
                                                          }}/>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    }
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={onClose}>
                            {t('Cerrar')}
                        </Button>
                        {!muestraTablaImpresion &&
                            <Button variant="contained" size="small" startIcon={<SaveIcon/>}
                                    disabled={repeatedRack} onClick={save}>
                                {t('Guardar')}
                            </Button>
                        }
                        {muestraTablaImpresion &&
                        <Button variant="contained" size="small" startIcon={<Print/>}
                                disabled={repeatedRack} onClick={imprimir}>
                            {t('Imprimir')}
                        </Button>
                        }

                    </Box>
                </div>
            </SidebarRight>
        </>)
}
