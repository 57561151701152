import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function DialogUi({open, title, subTitle, width, onClose, scroll, ...props}) {
    const {t} = useTranslation();
    const elements = React.Children.toArray(props.children);
    const body = elements[0];
    const footer = elements.length > 1 ? elements[1] : null;

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog fullWidth open={open} maxWidth={width} scroll={scroll || 'body'}>
            <DialogTitle>
                {t(title)}
                {onClose &&
                <IconButton onClick={onClose} sx={{position: 'absolute', right: 8, top: 8}}>
                    <Close/>
                </IconButton>
                }
            </DialogTitle>
            <DialogContent dividers>
                {React.cloneElement(body, {})}
            </DialogContent>
            <DialogActions style={props.footerInline ? {display: "inline"} : {}}>
                {footer != null && React.cloneElement(footer)}
            </DialogActions>
        </Dialog>
    );
}
