import useServiceFactory from "../useServiceFactory";
const apiUrl = '/operation/conteoInventario';

const useApiConteoInventario = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params),
        completar: async (id) => service.patch(`${apiUrl}/${id}/completar`),
        desactivar: async (id) => service.patch(`${apiUrl}/${id}/desactivar`),
        reporte: async (id, params, nombreReporte) => service.get(`${apiUrl}/${id}/reporte`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${nombreReporte}.xlsx`
            }),
    }
};

export default useApiConteoInventario;
