/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {ValidationContext} from "../../shared/validationContext";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import useApiConteoInventario from "../../../api/operation/useApiConteoInventario";
import * as yup from "yup";
import {Check, Download} from "@mui/icons-material";
import {CONTEO_INVENTARIO_ESTATUS, MENUS_TIPO_OPCION as opciones} from "../../../utility/constants";
import {UserContext} from "../../security/AuthProvider";
import useMessage from "../../../hooks/useMessage";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";

const validationSchema = yup.object().shape({
    plantaId: yup.number().required()
});

export default function InventoryCountDetail({conteoId, open, close, onSave, temporalId}) {
    const defaultEntity = {id: 0, plantaIds: []};
    const api = useApiConteoInventario();
    const {t} = useTranslation();
    const {user} = useContext(UserContext);
    const message = useMessage();
    const puedeCompletarParcial = user.menus.includes(opciones.edicionDatosMaestros);
    const {readOnlyContext} = useContext(ReadOnlyContext);

    const [titulo, setTitulo] = useState('');
    const [conteo, setConteo] = useState(defaultEntity);
    const [errors, setErrors] = useState({});

    const [validate, clearValidation] = useValidate(validationSchema, conteo, setErrors);

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            setConteo(result);
        }

        if (conteoId > 0) {
            setTitulo("Ver");
            load(conteoId).then();
        } else {
            setTitulo("Agregar");
            setConteo(defaultEntity);
        }

        clearValidation();
    }, [temporalId]);

    const onChange = (name, value) => {
        setConteo({...conteo, [name]: value});
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;

        await api.save(conteo);
        onSave();
    }

    async function completar() {
        await message.confirm(t("El conteo finalizará"), t("¿Desea Continuar?"))

        await api.completar(conteo.id);
        onSave();
    }

    const puedeCompletar = () => {
        return !readOnlyContext?.current && puedeCompletarParcial
            && conteo.estatus === CONTEO_INVENTARIO_ESTATUS.completadoParcial;
    }

    const descargarReporte = async () => {
        await message.confirm(t("Descargar reporte"), t("¿Desea Continuar?"))

        const titulosCeldas = {
            A1: t("Datos Generales"),
            A2: t("Ubicación actual"),
            A3: t("Planta"),
            A4: t("Almacén"),
            A5: t("Área"),
            A6: t("Cantidad de racks escaneados"),
            A7: t("Cantidad de racks confirmados en ubicación"),
            A8: t("Cantidad de racks fuera de ubicación"),
            A9: t("Cantidad de racks sin ubicación"),
            A11: t("Resumen de registros"),
            A12: t("Nombre"),
            B12: t("Última ubicación")
        }

        const nombreReporte = `${t("ReporteConteo")}_${conteoId}`;

        await api.reporte(conteoId, titulosCeldas, nombreReporte);
    }

    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Conteo")}`} open={open} close={close} size={800}>
                <ValidationContext.Provider
                    value={{errors, setErrors, allDisabled: !conteo.programado && conteoId !== 0}}>
                    <Grid container spacing={2}>
                        <FieldDropDown label="Planta" xs={12} name="plantaId" options={user.plantas}
                                       valueById value={conteo.plantaId} onChange={onChange}/>

                        <FieldDropDown label="Almacén" xs={6} name="almacenId" source="almacenes"
                                       valueById value={conteo.almacenId} onChange={onChange}
                                       filter={{plantaId: conteo.plantaId}}/>

                        <FieldDropDown label="Área" xs={6} name="areaId" source="areas"
                                       valueById value={conteo.areaId} onChange={onChange}
                                       filter={{almacenId: conteo.almacenId}}/>

                        <Grid item xs={2}>{t("Cant Sistema")}</Grid>
                        <Grid item xs={2}>{conteo.cantidadEnSistema}</Grid>
                        <Grid item xs={2}>{t("Cant Real")}</Grid>
                        <Grid item xs={2}>{conteo.cantidadReal}</Grid>
                    </Grid>

                    {conteo.racks?.length > 0 &&
                    <>
                        <Box display="flex" mt={2} gap={2} alignItems="center" style={{fontSize: 13}}>
                            <div style={{fontWeight: 'bold', fontSize: 16}}>
                                {t('Racks')}
                            </div>

                            <Box color="green">{t("Confirmados en ubicación")}</Box>
                            <Box color="orange">{t("Sin ubicación anterior")}</Box>
                            <Box color="red">{t("Fuera de ubicación")}</Box>
                        </Box>

                        <table className="table-detail">
                            <thead>
                            <tr>
                                <th>{t("Nombre")}</th>
                                <th>{t("Ubicación Anterior")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {conteo.racks?.map(rack => (
                                <tr key={rack.id}
                                    style={{color: rack.enSistema ? 'green' : rack.ubicacionAnterior ? 'red' : 'orange'}}>
                                    <td>{rack.name}</td>
                                    <td>{rack.ubicacionAnterior}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                    }
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        {conteo.racks &&
                        <Button variant="contained" color="success" size="small" startIcon={<Download/>}
                                onClick={descargarReporte}>
                            {t('Reporte')}
                        </Button>
                        }

                        {!readOnlyContext?.current && (conteo.programado || conteoId === 0) &&
                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                        }

                        {puedeCompletar() &&
                        <Button variant="contained" color="success" size="small" startIcon={<Check/>}
                                onClick={completar}>
                            {t('Confirmar')}
                        </Button>
                        }
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
