import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const GoToDetailFormatter = ({data, colDef}) => {
    const {t} = useTranslation();

    return (
        <>
            {data.goToDetailVisible !== false &&
                <Link to={{
                    pathname: `${colDef.path}/${data.id}`,
                    state: {data}
                }}
                >{t('View')}</Link>
            }
        </>
    )
};

export default GoToDetailFormatter;
