import React, {useContext, useEffect, useState} from 'react'
import {Grid, TextField} from "@mui/material";
import NumberFormatCustom from "./NumberFormatCustom";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import {useTranslation} from "react-i18next";

const FieldTextBox = ({
                          xs, sm, md, lg, xl, label, name, onChange, value, required, disabled, multiline, rows,
                          isNumeric, maxLength, disabledBackgroundColor, variant, width, precision,
                          endAdornment, className, error, debounceTimeout, helperText, thousandSeparator,type,
                          ignoreAllDisabled, isPositiveNumber, startAdornment
                      }) => {

    const {t} = useTranslation();
    const {readOnlyContext} = useContext(ReadOnlyContext);
    const [readOnly, setReadOnly] = useState(false);
    const {errors, allDisabled} = useContext(ValidationContext);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);


    function onValueChange(event) {
        if (value?.toString() === event.target.value || readOnly === true)
            return;

        let newValue = event.target.value;
        if (isNumeric === true) {
            if (isNaN(parseFloat(newValue))) {
                newValue = null;
            } else {
                newValue = parseFloat(newValue);
                newValue = isPositiveNumber && newValue < 0? -newValue: newValue;
            }
        }

        if (onChange != null)
            onChange(name, newValue);
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{t(label)}</label>
                }
                <TextField variant={variant || 'outlined'}
                           value={value ?? ''}
                           disabled={readOnly || (!ignoreAllDisabled && allDisabled)}
                           className={className}
                           style={{width: width ?? '100%', backgroundColor: disabledBackgroundColor}}
                           onChange={onValueChange}
                           multiline={multiline}
                           type={type}
                           name={name ?? 'textField'}
                           rows={rows}
                           inputProps={{
                               className: `${isNumeric ? 'numeric' : ''}`,
                               maxLength: maxLength ?? (isNumeric ? 12 : 50),
                               precision: precision?.toString() ?? '2',
                               thousandSeparator: thousandSeparator
                           }}
                           InputProps={isNumeric ? {
                               inputComponent: isNumeric ? NumberFormatCustom : null,
                               endAdornment: endAdornment
                           } : {
                               startAdornment: startAdornment
                           }}
                           size="small"
                           error={error || errors?.[name]}
                           helperText={helperText ||( errors?.[name]?.message != null ? t(errors[name].message): "")}
                />
            </Grid>
        </>
    )
}
export default FieldTextBox;
