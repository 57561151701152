import useServiceFactory from "../useServiceFactory";
const apiUrl = 'catalogs/perfiles';

const useApiPerfiles = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params)
    }
};

export default useApiPerfiles;
