/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import {Grid} from "@mui/material";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import {HeaderAddButton, ViewDetailButton} from "../../shared/ag-grid-helper/goToDetail";
import InventoryCountDetail from "./InventoryCountDetail";
import {CONTEO_INVENTARIO_LIST, CONTEO_INVENTARIO_ESTATUS as estatusConteo} from "../../../utility/constants";
import useApiConteoInventario from "../../../api/operation/useApiConteoInventario";
import useMessage from "../../../hooks/useMessage";
import {UserContext} from "../../security/AuthProvider";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";

export default function InventoryCount({datos, filtro, setFiltro, buscar}) {
    const {t} = useTranslation();
    const api = useApiConteoInventario();
    const message = useMessage();
    const {user} = useContext(UserContext);

    const [conteoId, setConteoId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);

    const onChange = (name, value) => {
        setFiltro({...filtro, [name]: value});
    };

    const verDetalle = (id) => {
        setTemporalId(Math.random())
        setConteoId(id);
        setAbrirDetalle(true)
    };

    const onSave = async () => {
        setAbrirDetalle(false);
        await buscar();
    };

    const eliminar = async (id) => {
        await message.confirm(t('Se eliminará el registro'), t("¿Desea Continuar?"))

        await api.desactivar(id);
        await buscar();
    };

    const Agregar = () => {
        const {readOnlyContext} = useContext(ReadOnlyContext);

        return readOnlyContext?.current ? <></> : <HeaderAddButton verDetalle={verDetalle}/>
    };

    const editar = ({data}) => <ViewDetailButton data={data} verDetalle={verDetalle} eliminar={eliminar}
                                                 eliminarDeshabilitado={data.estatus !== estatusConteo.programado}/>;

    return (
        <>
            <Grid container spacing={2} alignItems="center" pt={0}>
                <FieldDropDownMultiselect label="Planta" sm={6} md={3} name="plantasIds" options={user.plantas}
                                          value={filtro.plantasIds} onChange={onChange} valueById disabled={false}/>

                <FieldDropDownMultiselect label="Almacén" sm={6} md={3} name="almacenesIds" source="almacenes"
                                          value={filtro.almacenesIds} onChange={onChange} valueById
                                          filter={{plantasIds: filtro.plantasIds}} disabled={false}/>

                <FieldDropDownMultiselect label="Área" sm={6} md={3} name="areasIds" source="areas"
                                          value={filtro.areasIds} onChange={onChange} valueById
                                          filter={{almacenesIds: filtro.almacenesIds}} disabled={false}/>

                <FieldDropDownMultiselect label={t('Estatus')} sm={3} name="estatusIds" source="estatus"
                                          value={filtro.estatusIds} onChange={onChange} valueById translate
                                          filter={{name: 'ConteoInventario'}} disabled={false}/>
            </Grid>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            dateFormatter: agGridHelper.DateFormatter,
                            mapArrayFormatter: agGridHelper.MapArrayFormatter,
                            headerAddButton: Agregar,
                            viewDetailButton: editar
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={datos}
                    localeTextFunc={function (key, defaultValue) {
                        return t(defaultValue);
                    }}
                >
                    <AgGridColumn field="id" headerName="ID"/>
                    <AgGridColumn field="ubicacion" headerName={t('Ubicación')} minWidth={500}
                                  wrapText={true} autoHeight={true}/>
                    <AgGridColumn field="fecha" headerName={t('Fecha')} cellRenderer="dateFormatter" minWidth={150}/>
                    <AgGridColumn field="cantidadEnSistema" headerName={t('Cant Sistema')}/>
                    <AgGridColumn field="cantidadReal" headerName={t('Cant Real')}/>
                    <AgGridColumn field="diferencia" headerName={t('Diferencia')}/>
                    <AgGridColumn field="estatus" headerName={t('Estatus')} cellRenderer="mapArrayFormatter"
                                  source={CONTEO_INVENTARIO_LIST} minWidth={150}/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <InventoryCountDetail conteoId={conteoId} open={abrirDetalle} temporalId={temporalId} onSave={onSave}
                                  close={() => setAbrirDetalle(false)}/>
        </>
    );
}
