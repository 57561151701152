/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Checkbox, Collapse, Paper, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    VIAJE_ESTATUS as estatusViaje,
    VIAJE_TIPO as tipoViaje,
} from "../../../utility/constants";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {newGuid} from "../../../utility/objectUtility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import {removeAtIndex} from "../../../utility/arrayUtility";
import useNotification from "../../../hooks/useNotification";
import {BorderLinearProgress} from "../../shared/StyledComponents";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../Main";

const estiloAcordion = {cursor: 'pointer', borderBottom: '1px solid rgba(224, 224, 224, 1)'}

const colores = {
    red: ["#ff4b4b", "#d50505"],
    green: ["#00d661", "#00b050"],
    yellow: ["#ffeb3b", "#e5d225"]
};

export default function TripRacks({
                                      viaje,
                                      setViaje,
                                      section,
                                      onChange,
                                      viajeEnTransito,
                                      viajeRecibido,
                                      camposDeshabilitados,
                                      isReadonly,
                                      esPlantaOrigen
                                  }) {
    const esEnvio = section.name === "enviosCargados" || section.name === "enviosVacios";
    const esViajeCargado = section.tipo === tipoViaje.cargado;

    const {t} = useTranslation();
    const notification = useNotification();
    const {appLanguage} = useContext(AppContext);
    const [racksExpanded, setRacksExpanded] = React.useState(true);
    const [materialesExpanded, setMaterialesExpanded] = React.useState(true);
    const [background, setBackground] = useState("");
    const [language, setLanguage] = useState(appLanguage);

    useEffect(() => {
        setLanguage(appLanguage);
    }, [appLanguage])

    const onChangeRack = (tipoRackId, rackId, name, value) => {
        const estatusValidos = [estatusViaje.enTransito, estatusViaje.reciboParcial];
        //En envíos no se puede recibir y tampoco si el estatus no es en tránsito o recibido parcial
        if (esEnvio || !estatusValidos.includes(viaje.estatus)) return;

        let tiposRack = [...viaje.tiposRack];
        const tipoRack = tiposRack.find(e => e.id === tipoRackId);
        let racks = [...tipoRack.racks];

        racks = racks.map(e => {
            if (e.id === rackId) {
                let cantidadRecibida = 0;

                if (e.esGenerico && !esViajeCargado)
                    cantidadRecibida = name === "cantidadRecibida" ? value : e.cantidadRecibida;
                else
                    cantidadRecibida = value ? 1 : 0;

                return {...e, [name]: value, cantidadRecibida}
            }
            return e;
        });

        const cantidadRecibida = racks.reduce((sum, e) => sum + e.cantidadRecibida, 0);
        tiposRack = tiposRack.map(e => e.id === tipoRackId ? {...e, racks, cantidadRecibida} : e);

        const totalRacksRecibidos = tiposRack.reduce((sum, e) => sum + e.cantidadRecibida, 0);

        setViaje({...viaje, tiposRack, racksRecibidos: totalRacksRecibidos});
    }

    const onCheckRack = (tipoRackId, rackId) => (e, value) => {
        onChangeRack(tipoRackId, rackId, "recibido", value)
    }

    const onChangeCantidadRack = (tipoRackId, rackId) => (name, value) => {
        onChangeRack(tipoRackId, rackId, "cantidadRecibida", value)
    }

    const getTotalRacks = (rack) => {
        const totalRacks = rack ? rack.cantidad : viaje.totalRacks;
        const racksRecibidos = rack ? rack.cantidadRecibida : viaje.racksRecibidos;

        return viajeRecibido || viajeEnTransito ? `(${racksRecibidos} ${t('de')} ${totalRacks})` : `(${totalRacks ?? ""})`;
    }

    const getOcupacionTotal= () => {
        
        const ocupacionTotal = parseFloat(viaje.ocupacionMateriales + viaje.ocupacionRacks).toFixed(2);
        
        return ocupacionTotal;
    }

    const getTotalLotes = (rack) => {

        const totalLotes = viaje.racks ? viaje.racks.filter(e => e.lote !== "" && e.lote !== null).length : "";

        return `(${totalLotes ?? ""})`;
    }

    const getColorFila = (rack) => {
        if (!viaje.recibido) return 'black'
        //Si la cantidad recibida es mayor a cero, los racks no se escanearon en el origen
        return rack.cantidadRecibida > rack.cantidad || viaje.creadoEnDestino ? 'orange' : rack.recibido ? 'green' : 'red';
    }

    
    const getRacksOrigen = () => {

        return viaje.racks ? viaje.racks.filter(e => e.rackIntermedio).length : "";
    }

    const getTotalOcupacion = (materiales) => {
        return materiales.reduce((sum, e) => sum + ((e.porcentajeOcupacion ?? 0) * (e.cantidad ?? 0)), 0);
    }

    const onChangeMaterial = (index) => (name, materialId, material) => {
        if (viaje.materiales.some(e => !e.deshabilitado && e.materialId === materialId)) {
            notification.error("El material ya fue agregado");
            const materiales = [{_guid: newGuid()}, ...removeAtIndex(viaje.materiales, index)];
            onChange('materiales', materiales);
            return;
        }

        const copiaMateriales = viaje.materiales.map((e, i) => i === index ? {
            ...e,
            ...material,
            id: e.id,
            materialId,
            esMaterial: true,
            pesoTotal: (e.cantidad ?? 0) * (material.peso ?? 0)
        } : {...e});

        const ocupacion = getTotalOcupacion(copiaMateriales);

        setViaje({...viaje, materiales: copiaMateriales, ocupacionMateriales: ocupacion});
    }

    const onChangeCantidadMaterial = (index) => (name, cantidad) => {
        const copiaMateriales = viaje.materiales.map((e, i) => i === index ? {
            ...e,
            cantidad,
            pesoTotal: (cantidad ?? 0) * (e.peso ?? 0)
        } : {...e});
        const ocupacion = getTotalOcupacion(copiaMateriales);

        setViaje({...viaje, materiales: copiaMateriales, ocupacionMateriales: ocupacion});
    }

    const quitarMaterial = (index) => () => {
        const copiaMateriales = removeAtIndex(viaje.materiales, index);
        const ocupacion = getTotalOcupacion(copiaMateriales);

        setViaje({...viaje, materiales: copiaMateriales, ocupacionMateriales: ocupacion});
    }

    const habilitarMateriales = () => {
        return (!camposDeshabilitados && !viaje.puedeEditarViajeIncompleto)
            || (esEnvio && !isReadonly && !esPlantaOrigen && viaje.enTransito1)
    }

    const Header = ({titulo, ocupacion, expanded, setExpanded}) => {
        useEffect(() => {
            const ocupacionTotal = viaje.ocupacionRacks + viaje.ocupacionMateriales;
            const color = ocupacionTotal >= 95 ? "green" : ocupacionTotal >= 85 ? "yellow" : "red"
            const color1 = colores[color][0];
            const color2 = colores[color][1];

            setBackground(`repeating-linear-gradient(125deg,${color1},${color1} 10px,${color2} 5px,${color2} 13px)`);

        }, [viaje.ocupacionMateriales])

        return (
            <Box display="flex" sx={estiloAcordion} onClick={() => setExpanded(!expanded)}
                 alignItems="center">
                <Box sx={{width: 100}}><b>{t(titulo)}</b></Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <BorderLinearProgress variant="determinate" value={ocupacion} background={background}/>
                    <Box textAlign="center" fontSize={10}>
                        {t("Ocupación en transporte")} {ocupacion} %
                    </Box>
                </Box>
                <Box pr={2} flexGrow={4} textAlign="right">
                    {expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box display="flex" flexDirection="column" mb={1}>
                <BorderLinearProgress variant="determinate" value={viaje.ocupacionRacks + viaje.ocupacionMateriales}
                                      background={background}/>
                <Box textAlign="center" fontSize={12}>
                    {`${t('Ocupación total')} ${getOcupacionTotal()}`} %
                </Box>
            </Box>

            {/*RACKS*/}
            <Paper elevation={2}>
                <Box p={1}>
                    <Header titulo="Racks" ocupacion={viaje.ocupacionRacks} expanded={racksExpanded}
                            setExpanded={setRacksExpanded}/>

                    {racksExpanded &&
                    <>
                        <Box display="flex" mt={1} gap={2} alignItems="center" style={{fontSize: 13}}>
                            <Box color="green">{t("Recibido correctamente")} ({viaje.racksRecibidosCorrectos})</Box>
                            <Box color="orange">{t("Diferencia en envío")} ({viaje.racksNoCargados})</Box>
                            <Box color="red">{t("No recibido")} ({viaje.racksNoRecibidos})</Box>
                            <Box color="blue">{t("Origen")} ({getRacksOrigen()})</Box>
                        </Box>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '5%'}}/>

                                    {esViajeCargado &&
                                        <TableCell sx={{width: '25%'}}>
                                            {`${t('Lote')} ${getTotalLotes()}`}
                                        </TableCell>
                                    }

                                    <TableCell sx={{width: '47%'}}>
                                        {`${t('Racks')} ${getTotalRacks()}`}
                                    </TableCell>

                                    {!esViajeCargado &&
                                    <>
                                        <TableCell align="center" sx={{width: '12%'}}>{t('Recibidos')}</TableCell>
                                        <TableCell align="center" sx={{width: '11%'}}>{t('Cantidad')}</TableCell>
                                    </>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {viaje.tiposRack.map((tipoRack, index) => (
                                    <React.Fragment key={tipoRack.id}>
                                        <TableRow sx={{'td': {border: 0}}}>
                                            <TableCell sx={{width: '2%', padding: 0}}>
                                                <IconButton size="small" onClick={() => {
                                                    onChange(`tiposRack[${index}].expandido`, !tipoRack.expandido)
                                                }}>
                                                    {tipoRack.expandido ? <KeyboardArrowUpIcon/> :
                                                        <KeyboardArrowDownIcon/>}
                                                </IconButton>
                                            </TableCell>

                                            <TableCell scope="row" sx={{fontWeight: 'bold'}}
                                                       colSpan={esViajeCargado ? 2 : 1}>
                                                {`${tipoRack.name}  ${getTotalRacks(tipoRack)}`}
                                            </TableCell>

                                            {!esViajeCargado &&
                                            <>
                                                <TableCell align="right" sx={{fontWeight: 'bold'}}>
                                                    {tipoRack.cantidadRecibida}
                                                </TableCell>
                                                <TableCell align="right" sx={{fontWeight: 'bold'}}>
                                                    {tipoRack.cantidad}
                                                </TableCell>
                                            </>
                                            }
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={{padding: 0}} colSpan={esViajeCargado ? 3 : 4}>
                                                <Collapse in={tipoRack.expandido} timeout="auto" unmountOnExit>
                                                    <Table size="small">
                                                        <TableBody sx={{'tr:last-child td': {borderBottom: 0}}}>
                                                            {tipoRack.racks.map((rack) => (
                                                                <TableRow key={rack.id}>
                                                                    <TableCell sx={{width: '5%', p: 0}}>
                                                                        <Checkbox checked={rack.recibido}
                                                                                  onChange={onCheckRack(tipoRack.id, rack.id)}/>
                                                                      
                                                                    </TableCell>

                                                                

                                                                    {esViajeCargado &&
                                                                    <TableCell
                                                                        sx={{width: '25%', color: getColorFila(rack)}}>
                                                                        {rack.rackIntermedio && <span style={{color:"blue"}}>*</span>}
                                                                        {rack.lote}
                                                                    </TableCell>
                                                                    
                                                                    }

                                                                    <TableCell
                                                                        sx={{width: '47%', color: getColorFila(rack)}}>
                                                                        {`${rack.name}  ${getTotalRacks(rack)}`}
                                                                    </TableCell>

                                                                    {!esViajeCargado &&
                                                                    <>
                                                                        <TableCell scope="row" align="right"
                                                                                   sx={{
                                                                                       width: '11%',
                                                                                       paddingRight: '25px'
                                                                                   }}>
                                                                            {rack.esGenerico && !esEnvio
                                                                            && (viajeEnTransito || viaje.estatus === estatusViaje.reciboParcial) ?
                                                                                <FieldTextBox
                                                                                    value={rack.cantidadRecibida}
                                                                                    variant="standard"
                                                                                    ignoreAllDisabled
                                                                                    isNumeric precision={0}
                                                                                    isPositiveNumber
                                                                                    onChange={onChangeCantidadRack(tipoRack.id, rack.id)}/>
                                                                                :
                                                                                <>{rack.cantidadRecibida}</>
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell scope="row" align="right"
                                                                                   sx={{width: '12%'}}>
                                                                            {rack.cantidad}
                                                                        </TableCell>
                                                                    </>
                                                                    }
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    }
                </Box>
            </Paper>

            <Box my={2}/>

            {/*MATERIALES*/}
            <Paper elevation={2}>
                <Box p={1}>
                    <Header titulo="Materiales" ocupacion={viaje.ocupacionMateriales} expanded={materialesExpanded}
                            setExpanded={setMaterialesExpanded}/>

                    {materialesExpanded &&
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '60%'}}>{t('Material')}</TableCell>

                                <TableCell sx={{width: '30%'}}>{t('Cantidad')}</TableCell>

                                {habilitarMateriales() &&
                                <TableCell sx={{width: '10%'}} align="center">
                                    <Tooltip title={t('Agregar Material')}>
                                        <IconButton color="primary" onClick={() => {
                                            onChange('materiales', [{_guid: newGuid()}, ...viaje.materiales])
                                        }}>
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viaje.materiales.map((row, index) => (
                                <TableRow key={`${row.id}_${row._guid}`} sx={{'td': {border: 0}}}>
                                    <TableCell scope="row">
                                        <FieldDropDown name={`materiales[${index}].materialId`} valueById
                                                       value={row.materialId} source="materiales" translate
                                                       variant="standard" filter={{language}}
                                                       disabled={!habilitarMateriales() || row.deshabilitado}
                                                       onChange={onChangeMaterial(index)}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <FieldTextBox isNumeric value={row.cantidad} variant="standard" precision={0}
                                                      name={`materiales[${index}].cantidad`}
                                                      disabled={!habilitarMateriales() || row.deshabilitado}
                                                      onChange={onChangeCantidadMaterial(index)}/>
                                    </TableCell>

                                    {habilitarMateriales() && !row.deshabilitado &&
                                    <TableCell align="center">
                                        <IconButton color="error" onClick={quitarMaterial(index)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }
                </Box>
            </Paper>
        </>
    )
}
