import useServiceFactory from "../useServiceFactory";
const apiUrl = '/operation/inventario';

const useApiInventario = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        reporteHistorialRack: async (id, params, nombreReporte) => service.get(`${apiUrl}/${id}/reporteHistorial`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${nombreReporte}.xlsx`
            }),
        reporteHistorial: async (params, nombreReporte) => service.get(`${apiUrl}/reporteHistorial`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${nombreReporte}.xlsx`
            }),
    }
};

export default useApiInventario;
