/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, Grid, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {newGuid} from "../../../utility/objectUtility";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {removeAtIndex} from "../../../utility/arrayUtility";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import {Add, Save} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DialogUi from "../../shared/modal/Dialog";
import useNotification from "../../../hooks/useNotification";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";
import {BorderLinearProgress} from "../../shared/StyledComponents";

const colores = {
    red: ["#ff4b4b", "#d50505"],
    green: ["#00d661", "#00b050"],
    yellow: ["#ffeb3b", "#e5d225"]
};

let count = 1;

export default function TripRequeriments({viaje, camposDeshabilitados, onChange, esViajeCargado}) {
    const {t} = useTranslation();
    const notification = useNotification();
    const apiTipoRack = useApiRackManagement();

    const [totalRequerimientos, setTotalRequerimientos] = useState(0);
    const [modalMaterial, setModalMaterial] = useState(false);
    const [requerimiento, setRequerimiento] = useState({});
    const [ocupacion, setOcupacion] = useState(0);
    const [background, setBackground] = useState("");

    useEffect(() => {
        getTotalRequerimientos(viaje.requerimientos);
    }, [viaje.id]);

    const getOcupacion = (requerimientos) => {
        const ocupacion = requerimientos.reduce((sum, e) => sum + (e.porcentajeOcupacion * (e.cantidad ?? 0)), 0);
        const color = ocupacion >= 95 ? "green" : ocupacion >= 85 ? "yellow" : "red"
        const color1 = colores[color][0];
        const color2 = colores[color][1];

        setBackground(`repeating-linear-gradient(125deg,${color1},${color1} 10px,${color2} 5px,${color2} 13px)`);
        setOcupacion(ocupacion);
    }

    const getTotalRequerimientos = (requerimientos) => {
        const totalRequerimientos = requerimientos.reduce((acc, e) => acc + (isNaN(e.cantidad) ? 0 : e.cantidad), 0);

        setTotalRequerimientos(totalRequerimientos);
        getOcupacion(requerimientos);
    };

    const onChangeTipoRack = (index) => (name, tipoRackId, tipoRack) => {
        const requerimientos = viaje.requerimientos.map((e, i) => i === index ? {
            ...e,
            tipoRackId,
            porcentajeOcupacion: !tipoRack ? 0 : esViajeCargado ? tipoRack.porcentajeOcupacion : tipoRack.porcentajeOcupacionVacio,
            materialId: null
        } : {...e});

        onChange("requerimientos", requerimientos);
        getOcupacion(requerimientos);
    };

    const onChangeCantidad = (index) => (name, cantidad) => {
        const requerimientos = viaje.requerimientos.map((e, i) => i === index ? {...e, cantidad} : {...e});

        onChange('requerimientos', requerimientos);
        getTotalRequerimientos(requerimientos);
    };

    const deleteRequerimiento = (index) => {
        const requerimientos = removeAtIndex(viaje.requerimientos, index);

        onChange('requerimientos', requerimientos);
        getTotalRequerimientos(requerimientos);
    }

    const abrirModalMaterial = (requerimiento) => () => {
        setModalMaterial(true);
        setRequerimiento({...requerimiento, numeroMaterial: '', error: false})
    }

    const guardarMaterial = async () => {
        if (!requerimiento.numeroMaterial) {
            setRequerimiento({...requerimiento, error: true});
            notification.error(t("Favor de revisar la información requerida o inválida"));
            return;
        }

        const nuevoMaterial = await apiTipoRack.guardarMaterial(requerimiento.tipoRackId, requerimiento.numeroMaterial);
        setModalMaterial(false);

        const requerimientos = viaje.requerimientos.map(e => e.id === requerimiento.id ? {
            ...e,
            materialId: nuevoMaterial.id
        } : {...e});

        onChange("requerimientos", requerimientos);
    }

    return (
        <>
            {/*REQUERIMIENTOS*/}
            <Grid container spacing={2}>
                <Grid item xs={3} sx={{fontWeight: 'bold'}}>
                    {t('Requerimientos')}
                </Grid>

                <Grid item xs={4}>
                    <BorderLinearProgress variant="determinate" value={ocupacion} background={background}/>
                    <Box textAlign="center" fontSize={10}>
                        {t("Ocupación en transporte")} {ocupacion} %
                    </Box>
                </Grid>
            </Grid>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '50%'}}>
                            {`${t('Racks')} ${!!totalRequerimientos ? `(${totalRequerimientos})` : ''}`}
                        </TableCell>

                        <TableCell sx={{width: '15%'}}>{t('Cantidad')}</TableCell>

                        {esViajeCargado && <TableCell sx={{width: '30%'}}>{t('Material')}</TableCell>}

                        {!camposDeshabilitados && !viaje.recibido &&
                        <TableCell sx={{width: '5%', px: 0}} align="center">
                            <Tooltip title={t('Agregar Requerimiento')}>
                                <IconButton color="primary" onClick={() => {
                                    onChange('requerimientos', [{_guid: newGuid()}, ...viaje.requerimientos])
                                }}>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {viaje.requerimientos.map((tipo, index) => (
                        <TableRow key={`${tipo.id}_${tipo._guid}`} sx={{'td': {border: 0}}}>
                            <TableCell scope="row">
                                <FieldAutoComplete name={`requerimientos[${index}].tipoRackId`} valueById
                                                   value={tipo.tipoRackId} source="tiposRack" xs
                                                   variant="standard" disabled={camposDeshabilitados}
                                                   onChange={onChangeTipoRack(index)}/>
                            </TableCell>

                            <TableCell>
                                <FieldTextBox name={`requerimientos[${index}].cantidad`} xs isNumeric
                                              value={tipo.cantidad} variant="standard" precision={0}
                                              disabled={camposDeshabilitados} onChange={onChangeCantidad(index)}/>
                            </TableCell>

                            {esViajeCargado &&
                            <TableCell>
                                <Box display="flex">
                                    <FieldDropDown name={`requerimientos[${index}].materialId`} xs valueById
                                                   value={tipo.materialId} variant="standard"
                                                   disabled={camposDeshabilitados || !tipo.tipoRackId}
                                                   source='tiposRackMaterial'
                                                   filter={{id: tipo.tipoRackId, name: count++}}
                                                   onChange={(e, value) => {
                                                       onChange(`requerimientos[${index}].materialId`, value)
                                                   }}/>

                                    {!camposDeshabilitados && !!tipo.tipoRackId &&
                                    <IconButton color="primary" size="small" onClick={abrirModalMaterial(tipo)}>
                                        <Add sx={{fontSize: 18}}/>
                                    </IconButton>
                                    }
                                </Box>
                            </TableCell>
                            }

                            {!camposDeshabilitados && !viaje.recibido &&
                            <TableCell align="center" sx={{px: 0}}>
                                <IconButton color="error" onClick={() => deleteRequerimiento(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <DialogUi open={modalMaterial} title={t("Agregar número de material")}
                      onClose={() => setModalMaterial(false)} width="sm">
                <Grid container spacing={2}>
                    <FieldTextBox value={requerimiento.numeroMaterial} error={requerimiento.error}
                                  onChange={(_, value) => setRequerimiento({
                                      ...requerimiento,
                                      numeroMaterial: value,
                                      error: false
                                  })}/>
                </Grid>

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>}
                            onClick={() => setModalMaterial(false)}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="success" size="small" startIcon={<Save/>}
                            onClick={guardarMaterial}>
                        {t("Guardar")}
                    </Button>
                </div>
            </DialogUi>
        </>
    )
}
