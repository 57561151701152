import * as yup from "yup";

const userValidationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    email: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    username: yup.string().trim().required(),
    password: yup.string().trim().required(),
    destinosIds: yup.array().min(1,'Debe agregar al menos una planta'),
    profileId: yup.string().trim().required()
        
});

export default userValidationSchema



