/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Grid, InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import materialSchema from "./materialValidation";
import useApiMaterial from "../../../api/catalogs/useApiMaterial";

export default function MaterialDetail({materialId, open, close, onSave, temporalId}) {
    const defaultEntity = {id: 0, activo: true, nameTraducido: ""};
    const api = useApiMaterial();
    const {t} = useTranslation();

    const [title, setTitle] = useState('');
    const [material, setMaterial] = useState(defaultEntity);
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(materialSchema, material, setErrors);

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            setMaterial(result);
        }

        if (materialId > 0) {
            setTitle(`${t("Editar")} ${t("Material")}`);
            load(materialId).then();
        } else {
            setTitle(`${t("Agregar")} ${t("Material")}`);
            setMaterial(defaultEntity);
        }

        clearValidation();
    }, [temporalId]);

    const onChange = (name, value) => {
        setMaterial({...material, [name]: value});
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;
        
        await api.save({...material});
        onSave();
    }

    return (
        <>
            <SidebarRight title={t(title)} open={open} close={close} size={750}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Grid container spacing={2} mb={2}>
                        <FieldTextBox label={`${t("Nombre")} (${t("Inglés")})`} name="name" xs={5}
                                      value={material.name} onChange={onChange}/>

                        <FieldTextBox label={`${t("Nombre")} (${t("Español")})`} name="nameTraducido" xs={5}
                                      value={material.nameTraducido} onChange={onChange}/>

                        <FieldCheckBox label="Activo" name="activo" value={material.activo} onChange={onChange}
                                       xs={2} mt={2}/>

                        <FieldTextBox label={t("Ocupación en transporte")} name="porcentajeOcupacion" xs={4}
                                      value={material.porcentajeOcupacion} onChange={onChange} isNumeric
                                      precision={0} maxLength={3}
                                      endAdornment={<InputAdornment position="end">%</InputAdornment>}/>

                        <FieldTextBox label={`${t("Valor")} (usd)`} name="valor" value={material.valor} xs={4}
                                      onChange={onChange} isNumeric/>

                        <FieldTextBox label={`${t("Peso")} (lbs)`} name="peso" value={material.peso} xs={4}
                                      onChange={onChange} isNumeric/>

                        <FieldTextBox label={`${t("Largo")} (in)`} name="largo" value={material.largo} xs={4}
                                      onChange={onChange} isNumeric precision={0}/>

                        <FieldTextBox label={`${t("Ancho")} (in)`} name="ancho" value={material.ancho} xs={4}
                                      onChange={onChange} isNumeric precision={0}/>

                        <FieldTextBox label={`${t("Alto")} (in)`} name="alto" value={material.alto} xs={4}
                                      onChange={onChange} isNumeric precision={0}/>

                        <FieldTextBox label="Descripción logística" name="descripcionLogistica" xs={8}
                                      value={material.descripcionLogistica} onChange={onChange}/>

                        <FieldTextBox label="Material SAT" name="materialSat" xs={4} maxLength={20}
                                      value={material.materialSat} onChange={onChange}/>

                        <FieldTextBox label="Descripción material SAT" name="descripcionMaterialSat" xs={8}
                                      value={material.descripcionMaterialSat} onChange={onChange}/>

                        <FieldTextBox label="Fracción arancelaria" name="fraccionArancelaria" xs={4} maxLength={20}
                                      value={material.fraccionArancelaria} onChange={onChange}/>
                    </Grid>
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        <Button variant="contained" size="small" startIcon={<SaveIcon/>} onClick={save}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
