import useServiceFactory from "../useServiceFactory";
const apiUrl = 'catalogs/menus';

const useApiMenu = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        getAll: async () => service.get(`${apiUrl}`, {showLoading: false})
    }
};

export default useApiMenu;
