/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types';

const ExColumn = () => {
    return (
        <>
        </>
    )
}

export default ExColumn;

ExColumn.propTypes = {
    field: PropTypes.string.isRequired,
    title:PropTypes.string,
    width:PropTypes.string,
    headerRenderer:PropTypes.func,
    cellHeaderClass:PropTypes.string,
    headerStyle:PropTypes.object,
    cellRenderer:PropTypes.func,
    cellControl:PropTypes.element,
    cellClass:PropTypes.string,
    cellStyle:PropTypes.object,
};