/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react'
import {
    Switch,
    Route
} from "react-router-dom";
import {UserContext} from "../security/AuthProvider";
import Trip from "../operation/trip/Trip";
import Login from "../login/Login";
import Home from "../Home";
import Plant from "../catalogs/plant/Plant";
import RackManagementMain from "../settings/rackManagementMain/RackManagementMain";
import MainInventory from "../operation/inventory/MainInventory";
import User from '../catalogs/user/User';
import Material from "../settings/material/Material";
import GeneralInformation from "../settings/GeneralInformation";
import Carrier from "../catalogs/carrier/Carrier";
import Profile from "../catalogs/profile/Profile";

const GuardRoute = ({allowAnonymous, path, menuId, ...props}) => {
    const {user} = useContext(UserContext);

    const permisoPantalla = () => {
        const tienePermiso = !menuId || user?.menus?.includes(menuId);
        const autenticado = user?.authenticated === true || allowAnonymous;
        return tienePermiso && autenticado;
    }

    return (
        <>
            {permisoPantalla() ?
                <Route {...props} /> :
                <Login/>
            }
        </>
    )
};

const Routes = () => {
    return (
        <Switch>
            <GuardRoute path="/login" exact={true} component={Login}/>
            <GuardRoute path="/trip" exact={true} component={Trip} menuId={1}/>
            <GuardRoute path="/trip/:plantaId" exact={true} component={Trip} menuId={1}/>
            <GuardRoute path="/racksManagementMain/:tab" exact={true} component={RackManagementMain} menuId={3}/>
            <GuardRoute path="/plant" exact={true} component={Plant} menuId={4}/>
            <GuardRoute path="/mainInventory/:tab" exact={true} component={MainInventory} menuId={6}/>
            <GuardRoute path="/user" exact={true} component={User} menuId={7}/>
            <GuardRoute path="/material" exact={true} component={Material} menuId={8}/>
            <GuardRoute path="/informacionGeneral" exact={true} component={GeneralInformation} menuId={9}/>
            <GuardRoute path="/perfiles" exact={true} component={Profile} menuId={10}/>
            <GuardRoute path="/transportistas" exact={true} component={Carrier} menuId={11}/>
            <GuardRoute component={Home}/>
        </Switch>

    )
};

export default Routes;
