import useServiceFactory from "../useServiceFactory";

const apiUrl = '/settings/rackManagement';

const useApiRackManagement = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params),
        saveImpresiones: async (params) => service.post(`${apiUrl}/saveImpresiones`, params),
        activarRack: async (id, params) => service.patch(`${apiUrl}/${id}/activarRack`, params),
        deleteRack: async (id, rackId) => service.patch(`${apiUrl}/${id}/deleteRack/${rackId}`),
        obtenerRacksExcel: async (formData) => service.post(`${apiUrl}/obtenerRacksExcel`, formData),
        guardarCargaRacks: async (params) => service.post(`${apiUrl}/guardarCargaRacks`, params),
        guardarMaterial: async (id, numeroMaterial) => service.patch(`${apiUrl}/${id}/guardarMaterial/${numeroMaterial}`),
        deleteMaterial: async (materialId) => service.patch(`${apiUrl}/deleteMaterial/${materialId}`)
    }
};

export default useApiRackManagement;
