/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import FieldTextBox from "../shared/fields/FieldTextBox";
import {Save} from "@mui/icons-material";
import useApiInformacionGeneral from "../../api/settings/useApiInformacionGeneral";
import * as yup from "yup";
import useValidate from "../../hooks/useValidate";
import {ValidationContext} from "../shared/validationContext";

const informacionSchema = yup.object().shape({
    rfc: yup.string().trim().required(),
    razonSocial: yup.string().trim().required(),
    descripcionLogistica: yup.string().trim().required(),
    materialSat: yup.string().trim().required(),
    descripcionMaterialSat: yup.string().trim().required(),
    fraccionArancelaria: yup.string().trim().required(),
    propietario: yup.string().required().email("Email inválido"),
    soporte1: yup.string().email("Email inválido"),
    soporte2: yup.string().email("Email inválido")
});

export default function GeneralInformation() {
    const {t} = useTranslation();
    const api = useApiInformacionGeneral();

    const [datos, setDatos] = useState({});
    const [errors, setErrors] = useState({});
    const [validate] = useValidate(informacionSchema, datos, setErrors);

    useEffect(() => {
        const load = async () => {
            const result = await api.get();
            setDatos(result);
        }

        load().then();
    }, [])

    const onChange = (name, value) => {
        setDatos({...datos, [name]: value});
    }

    const onSave = async () => {
        const valid = await validate();
        if (!valid) return;

        await api.save(datos);
    };

    return (
        <ValidationContext.Provider value={{errors, setErrors}}>
            <Grid container>
                <Grid item xs={12} sm={6} md={8}>
                    <Typography variant="h6" fontWeight={700}>
                        {t("Información General")}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} textAlign="right">
                    <Button variant="contained" startIcon={<Save/>} onClick={onSave}>
                        {t('Guardar')}
                    </Button>
                </Grid>
            </Grid>

            <div className="subtitle">
                {t("Datos Generales")}
            </div>

            <Grid container spacing={2} mb={1}>
                <FieldTextBox label="RFC (Sociedad) Vitro" name="rfc" xs={2} value={datos.rfc} onChange={onChange}
                              maxLength={20}/>

                <FieldTextBox label="Denominación Social" name="razonSocial" xs={5} maxLength={100}
                              value={datos.razonSocial} onChange={onChange}/>

                <FieldTextBox label="Propietario" name="propietario" xs={3}
                              value={datos.propietario} onChange={onChange}/>

                <FieldTextBox label={`${t("Soporte")} 1`} name="soporte1" xs={3}
                              value={datos.soporte1} onChange={onChange}/>

                <FieldTextBox label={`${t("Soporte")} 2`} name="soporte2" xs={3}
                              value={datos.soporte2} onChange={onChange}/>

                <FieldTextBox label="Segunda alerta pedimento (hrs)" name="tiempoAlertaPedimento" xs={3}
                              value={datos.tiempoAlertaPedimento} onChange={onChange} isNumeric precision={0}/>
            </Grid>

            <div className="subtitle">
                {t("Datos de Racks (Modelos)")}
            </div>

            <Grid container spacing={2} mb={2}>
                <FieldTextBox label="Descripción logística" name="descripcionLogistica" xs={3}
                              value={datos.descripcionLogistica} onChange={onChange}/>

                <FieldTextBox label="Fracción arancelaria" name="fraccionArancelaria" xs={2} maxLength={20}
                              value={datos.fraccionArancelaria} onChange={onChange}/>

                <FieldTextBox label="Material SAT" name="materialSat" xs={2} maxLength={20}
                              value={datos.materialSat} onChange={onChange}/>

                <FieldTextBox label="Descripción material SAT" name="descripcionMaterialSat" xs={7}
                              value={datos.descripcionMaterialSat} onChange={onChange} maxLength={100}/>
            </Grid>
        </ValidationContext.Provider>
    );
}
