import * as yup from "yup";
import {VIAJE_ESTATUS as estatusViaje} from "../../../utility/constants";

const estatusRequeridos = [estatusViaje.porEnviar, estatusViaje.reciboParcial];

const viajeSchema = yup.object().shape({
    fechaEstimadaEnvio: yup.date().nullable()
        .when("section", {
            is: a => a === 'enviosCargados' || a === 'enviosVacios',
            then: yup.date().required().nullable()
        }),
    origenId: yup.number().required(),
    destinoId: yup.number().required(),
    numeroEntrega: yup.string().trim()
        .when("section", {
            is: a => a === 'enviosCargados' || a === 'recepcionesCargadas',
            then: yup.string().required()
        }),
    numeroViaje: yup.string().trim().nullable()
        .when(["section", "esRecepcion", "estatus"], {
            is: (a, b, c) => a === "enviosVacios" || b || (c != null && c !== estatusViaje.guardado),
            then: yup.string().required()
        }),
    transportista: yup.mixed().nullable()
        .when(["esRecepcion", "estatus"], {
            is: (a, b) => a || (b != null && estatusRequeridos.includes(b)),
            then: yup.mixed().required()
        }),
    unidad: yup.string().trim()
        .when(["esRecepcion", "estatus"], {
            is: (a, b) => a || (b != null && estatusRequeridos.includes(b)),
            then: yup.string().required()
        }),
   
    tipoTransporte: yup.number()
        .when(["esRecepcion", "estatus"], {
            is: (a, b) => a || estatusRequeridos.includes(b),
            then: yup.number().required()
        }),
    plantaIntermediaId: yup.number()
        .when("esDobleDestino", {
            is: true,
            then: yup.number().required().min(1)
        }),
    requerimientos: yup.array()
        .of(
            yup.object().shape({
                tipoRackId: yup.number().required(),
                cantidad: yup.number().required().max(999999999, '')
            })
        ),
    materiales: yup.array()
        .of(
            yup.object().shape({
                materialId: yup.number().required(),
                cantidad: yup.number().required().min(1).max(999999999, '')
            })
        )
});

export default viajeSchema;
