import * as yup from "yup";

const plantValidationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    abreviacion: yup.string().trim().required(),
    zonaHorariaId: yup.number().required(),
    calle: yup.string().trim().required(),
    codigoPostal: yup.string().trim().required(),
    paisId: yup.number().required(),
    estado: yup.string().trim().required(),
    municipio: yup.string().trim().required(),
    contacto: yup.string().trim().required(),
    email: yup.string().trim().required(),
    telefono: yup.string().trim().required(),
    correoProveedor: yup.array()
        .transform(function(value, originalValue) {
            if (this.isType(value) && value !== null) {
                return value;
            }
            return originalValue ? originalValue.split(/[\s;]+/) : [];
        })
        .of(yup.string().email("Email inválido")),
    almacenes: yup.array().min(1,'Debe agregar al menos un almacen')
        .of(yup.object().shape({
            nombre: yup.string().trim().required(),
            areas: yup.array().min(1,'Debe agregar al menos un área')
                .of(yup.object().shape({
                    nombre: yup.string().trim().required()
                }))
        }))
});

export default plantValidationSchema;


