/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Grid, Tooltip, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import RackManagementDetail from "./RackManagementDetail";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import {UserContext} from "../../security/AuthProvider";
import {MENUS_TIPO_OPCION as opciones} from "../../../utility/constants";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {Download} from "@mui/icons-material";
import InputClipboardFile from "../../shared/InputClipboardFile";
import UploadRacksLog from "./UploadRacksLog";

const estatusActivo = [{id: 1, name: "Activo"}, {id: 0, name: "Inactivo"}];
const boxStyle = {alignItems: "center", border: '1.6px solid #03a9f4', borderRadius: 1, px: 1};

export default function RackManagement() {
    const {t} = useTranslation();
    const api = useApiRackManagement()
    const {user} = useContext(UserContext);
    const puedeAgregar = user.menus.includes(opciones.edicionDatosMaestros);

    const filtroDefault = {tipoRacksIds: [], plantasIds: [], activoIds: []};

    const [grid, setGrid] = useState({data: []});
    const [filtro, setFiltro] = useState(filtroDefault);
    const [tipoRackId, setTipoRackId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);
    const [resultadoCargaRacks, setResultadoCargaRacks] = useState({});
    const [verLogRacksCargados, setVerLogRacksCargados] = useState(false);
    const [totalRacks, setTotalRacks] = useState(0);

    useEffect(() => {
        buscar(filtroDefault).then();
    }, [])

    const buscar = async (copiaFiltro) => {
        copiaFiltro = copiaFiltro ?? filtro;
        const nuevoFiltro = {
            ...copiaFiltro,
            tipoRacksIds: copiaFiltro.tipoRacksIds.join(),
            plantasIds: copiaFiltro.plantasIds.join()
        };
        const result = await api.filter(nuevoFiltro);

        setTotalRacks(result.reduce((sum, e) => sum + e.totalRacks, 0));
        setGrid({data: result});
    };

    const limpiarFiltros = async () => {
        setFiltro(filtroDefault);
        await buscar(filtroDefault);
    };

    const onChange = (name, value) => {
        setFiltro({...filtro, [name]: value});
    };

    const onSave = async () => {
        setAbrirDetalle(false);
        setVerLogRacksCargados(false);
        await buscar(filtro);
    };

    const viewDetail = (id) => {
        setTemporalId(Math.random())
        setTipoRackId(id);
        setAbrirDetalle(true)
    };

    const obtenerRacksExcel = async (archivo) => {
        const formData = new FormData();
        formData.append("files", archivo.file);

        const result = await api.obtenerRacksExcel(formData);
        const racksCorrectos = result.racks.filter(e => e.error === "");
        const racksConError = result.racks.filter(e => e.error !== "");

        setResultadoCargaRacks({...result, racksCorrectos, racksConError});
        setVerLogRacksCargados(true);
    }

    const HeaderAddButton = () => (
        <div className="ag-grid row-icons">
            {puedeAgregar &&
            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => viewDetail(0)}>
                {t('Agregar')}
            </Button>
            }
        </div>
    );

    const ViewDetailButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => viewDetail(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    );

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    return (
        <>
            <Grid container mb={2}>
                <Grid item xs={12} sm={6} md={8}>
                    <Box display="flex">
                        <Typography variant="h6" fontWeight={700}>
                            {t('Administración de Racks')}
                        </Typography>

                        <Box display="flex" ml={2} sx={boxStyle}>
                            <Box><b>{totalRacks} {t('Racks')}</b></Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} textAlign="right">
                    <ButtonGroup variant="contained">
                        <InputClipboardFile title="Cargar Racks" onChange={obtenerRacksExcel} accept=".xlsx"
                                            icon={<Download/>}/>
                        <Button variant="contained" startIcon={<SearchIcon/>} onClick={() => buscar()}>
                            {t('Filtrar')}
                        </Button>
                        <Tooltip title={t('Limpiar Filtros')}>
                            <Button onClick={limpiarFiltros}>
                                <ClearIcon sx={{fontSize: 18}}/>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
                <FieldDropDownMultiselect label={t('Tipo de Rack')} name="tipoRacksIds" source="tiposRack"
                                          value={filtro.tipoRacksIds} onChange={onChange} sm={6} md={3} valueById/>

                <FieldDropDownMultiselect label={t('Planta')} name="plantasIds" source="plantas" sm={6} md={3}
                                          value={filtro.plantasIds} onChange={onChange} valueById/>

                <FieldDropDownMultiselect label={t('Estatus')} name="estatusIds" options={estatusActivo} sm={6} md={3}
                                          value={filtro.estatusIds} onChange={onChange} valueById translate
                                          showSelectButtons={false}/>
            </Grid>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            headerAddButton: HeaderAddButton,
                            estatusValueCell: EstatusValueCell,
                            viewDetailButton: ViewDetailButton,
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={grid.data}
                >
                    <AgGridColumn field="name" headerName={t('Tipo de Rack')}/>
                    <AgGridColumn field="plantas" headerName={t('Plantas')}/>
                    <AgGridColumn field="totalRacks" headerName={t('Cantidad de Racks')}/>
                    <AgGridColumn field="porcentajeOcupacion" headerName={t('Ocupación en transporte') + ' (%)'}/>
                    <AgGridColumn headerName={`${t("Ocupación en transporte")} (${t("vacío")}) (%)`}
                                  field="porcentajeOcupacionVacio"/>
                    <AgGridColumn field="activo" headerName={t('Activo')} cellRenderer="estatusValueCell"/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <RackManagementDetail tipoRackId={tipoRackId} open={abrirDetalle} temporalId={temporalId}
                                  close={() => setAbrirDetalle(false)} onSave={onSave}/>

            <UploadRacksLog datos={resultadoCargaRacks} open={verLogRacksCargados} onSave={onSave}
                            close={() => setVerLogRacksCargados(false)}/>
        </>
    );
}
