import {styled} from "@mui/material/styles";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {Badge} from "@mui/material";

export const BorderLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== "background",
})(({theme, background}) => ({
    height: 10,
    borderRadius: 4,
    cursor: 'pointer',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 3,
        background
    },
}));

export const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        fontSize: '9px',
        padding: 0,
        height: '12px',
        minWidth: '10px',
        width: '12px',
        lineHeight: 0,
        top: '5px',
        right: '3px',
    }
}));
