import React from 'react';

export function getPersistedStorage(key) {
    return JSON.parse(localStorage.getItem(key))
}

export default function usePersistedState(key, defaultValue) {
    const [state, setState] = React.useState(() => {
        const persistedState = localStorage.getItem(key);
        if ( typeof defaultValue === 'object') {
            return {...defaultValue, ...(persistedState ? JSON.parse(persistedState) : {})};
        }
        return persistedState ?? defaultValue;
    });
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);
    return [state, setState];
}
