import React, {useContext, useEffect, useState} from 'react'
import {Grid, TextField} from "@mui/material";
import moment from "moment";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import TimeField from 'react-simple-timefield';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

const FieldTimeRange = ({
                            xs, sm, md, lg, xl, label, name, onChange, value, required, disabled, error, className
                        }) => {

    const {t} = useTranslation();
    const [currentValue, setCurrentValue] = useState([new Date(), new Date()]);
    const [currentTime, setCurrentTime] = useState(['00:00', '00:00']);
    const {readOnlyContext} = useContext(ReadOnlyContext);
    const {errors} = useContext(ValidationContext);
    const {allDisabled} = useContext(ValidationContext);

    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);

    useEffect(() => {
        const newDate = new Date(2000, 1, 0, 0, 0, 0);
        const newValue = {dateFrom: value?.dateFrom ?? newDate, dateTo: value?.dateTo ?? newDate};

        const timeFrom = moment(newValue.dateFrom).format("HH:mm");
        const timeTo = moment(newValue.dateTo).format("HH:mm");
        const dateFrom = moment(newValue.dateFrom).toDate();
        const dateTo = moment(newValue.dateTo).toDate();

        setCurrentValue([dateFrom, dateTo])
        setCurrentTime([timeFrom, timeTo])
    }, [value])

    const getDateWithTime = (date, value) => {
        const newTime = value.replace(/-/g, ':');
        const time = newTime.substr(0, 5);

        const hour = Number(time.split(":")[0]);
        const minute = Number(time.split(":")[1]);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute);

        return date;
    }

    const startChanged = (event, time) => {
        const dateFrom = getDateWithTime(currentValue[0], time);

        onChange(name, {
            dateFrom: moment(dateFrom).format('YYYY-MM-DDTHH:mm:00'),
            dateTo: moment(currentValue[1]).format('YYYY-MM-DDTHH:mm:00')
        });
    }

    const endChanged = (event, time) => {
        const dateTo = getDateWithTime(currentValue[1], time);

        onChange(name, {
            dateFrom: moment(currentValue[0]).format('YYYY-MM-DDTHH:mm:00'),
            dateTo: moment(dateTo).format('YYYY-MM-DDTHH:mm:00')
        });
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{label}</label>
                }
                <Box display="flex" alignItems="center" gap={1}>
                    <TimeField
                        value={currentTime[0]}
                        onChange={startChanged}
                        style={{width: 70}}
                        input={<TextField value={currentTime} size="small" error={error || errors?.[name]}
                                          disabled={disabled || allDisabled || readOnly}/>}
                    />
                    <label>{t('A')}</label>
                    <TimeField
                        value={currentTime[1]}
                        onChange={endChanged}
                        style={{width: 70}}
                        input={<TextField value={currentTime} size="small" error={error || errors?.[name]}
                                          disabled={disabled || allDisabled || readOnly}/>}
                    />
                </Box>
            </Grid>
        </>
    )
};
export default FieldTimeRange;
