/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import DialogUi from "../../shared/modal/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";
import Box from "@mui/material/Box";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";

export default function UploadRacksLog({datos, open, close, onSave}) {
    const {t} = useTranslation();
    const api = useApiRackManagement();

    const confirmar = async () => {
        await api.guardarCargaRacks(datos.racksCorrectos);
        onSave();
    }

    return (
        <>
            <DialogUi open={open} title={t("Racks Cargados")} onClose={close} width="md" scroll="paper">
                {!!datos.error ?
                    <Typography variant="h5" color="error">
                        {t(datos.error)}
                    </Typography>
                    :
                    <div>
                        <Typography variant="h6">
                            {`${t("Racks correctos")}: ${datos.racksCorrectos?.length}. ${t("Racks con error")}: 
                        ${datos.racksConError?.length}`}
                        </Typography>

                        {datos.racksConError?.length > 0 &&
                        <>
                            <Box py={1}>
                                {t("MensajeRacksConError")}
                            </Box>
                            <table className="table-custom">
                                <thead>
                                <tr>
                                    <th>{t("Fila")}</th>
                                    <th>{t("Tipo Rack ID")}</th>
                                    <th>{t("Número de serie")}</th>
                                    <th>{t("Error")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {datos.racksConError?.map(rack =>
                                    <tr key={rack.fila}>
                                        <td>{rack.fila}</td>
                                        <td>{rack.tipoRackId}</td>
                                        <td>{rack.numeroSerie}</td>
                                        <td>{t(rack.error)}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </>
                        }
                    </div>
                }

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                        {t(!!datos.error ? 'Cerrar' : 'Cancelar')}
                    </Button>

                    {!datos.error && datos.racksCorrectos?.length > 0 &&
                    <Button variant="contained" color="success" size="small" startIcon={<Check/>}
                            onClick={confirmar}>
                        {t("Confirmar")}
                    </Button>
                    }
                </div>
            </DialogUi>
        </>
    );
}
