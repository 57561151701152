/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react'
import {Autocomplete, Grid} from "@mui/material";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import useSourceUtils from "./useSouceUtils";
import ListboxComponent from "./ListboxComponent";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";

const StyledAutocomplete = styled(Autocomplete)(() => ({
    '& .MuiInputBase-root': {
        padding: 0
    },
    '& .MuiInputLabel-root': {
        transform: ' translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)'
    },
    '& .MuiSelect-select': {
        padding: 5,
        height: 10,
        fontSize: 12
    }
}));

export const AutoCompleteContext = React.createContext({});


const FieldDropDownMultiselect = ({xs, sm, md, lg, xl, label, name, options, source, onChange, value, className,
                                      required, valueById, error, filter, disabled, orderBy, showSelectButtons,
                                      limitTags, translate
                                  }) => {
    showSelectButtons = showSelectButtons ?? true;
    const {t} = useTranslation();

    const {readOnlyContext} = useContext(ReadOnlyContext);
    const {errors} = useContext(ValidationContext);

    const {
        currentOptions,
        setSelectedId,
        getSelectedId,
        getSelected,
        getOptions
    } = useSourceUtils(value, options, source, filter, true, valueById, orderBy);

    const [readOnly, setReadOnly] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);

    useEffect(()=>{
        setFilteredOptions(currentOptions);
    },[currentOptions])

    function onDropChange(event, value) {
        const ids= value?.map(e=>e.id)??[];
        setSelectedId(ids)
        fireChange(name, value)
    }

    const multiselectControl = {
        selectAll: () => {
            handleSelected(filteredOptions.map(e => e.id));
        },
        unSelect: () => {
            const currentIds = filteredOptions.map(e => e.id);
            handleSelected(getSelectedId().filter(e => currentIds.indexOf(e) < 0));
        },
        showSelectButtons,
        isOpen
    }

    const handleSelected = (ids) => {
        setSelectedId(ids);
        onChange(name, valueById ? ids : getOptions(ids));
    }

    const fireChange = (itemName, itemValue)  => {
        if (onChange != null) {
            onChange(itemName, itemValue == null ? [] : valueById ? itemValue.map(e=>e.id) : itemValue)
        }
    }

    const  onInputChange = (event, value, reason) =>{
        filterOptions(value);
    }

    const filterOptions = (text) => {
        text = text.toLowerCase();
        if ((text ?? '').trim() === '') {
            setFilteredOptions(currentOptions);
        } else {
            setFilteredOptions(currentOptions.filter(e => e.name.toLowerCase().includes(text)))
        }
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{t(label)}</label>
                }
                <AutoCompleteContext.Provider value={multiselectControl}>
                    <StyledAutocomplete
                        onOpen={()=>setIsOpen(true)}
                        onClose={()=>setIsOpen(false)}
                        value={getSelected()}
                        multiple={true}
                        size="small"
                        limitTags={limitTags??2}
                        disableCloseOnSelect
                        ListboxComponent={ListboxComponent}
                        className={`${(error || errors?.[name]) ? 'error' : ''}`}
                        options={currentOptions}
                        renderTags={(items) => {
                            return <Box sx={{pl:1, color: 'black', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                {items.length === 1? `1 ${t('Seleccionado')}`: `${items.length} ${t('Seleccionados')}`}
                            </Box>
                        }}
                        disabled={readOnly}
                        getOptionLabel={(option) => translate ? t(option?.name ?? '') : (option?.name ?? '')}
                        onChange={onDropChange}
                        classes={className}
                        renderInput={(params) => (
                            <TextField {...params} size="sm"/>
                        )}
                        onInputChange={onInputChange}
                    />
                </AutoCompleteContext.Provider>
            </Grid>
        </>
    )
};

export default FieldDropDownMultiselect

