/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import FieldDropDown from "../../shared/fields/FieldDropDown";

export default function PlantDestiny({planta, onChange}) {
    const {t} = useTranslation();

    const onChangeDestino = (index) => (name, value) => {
        const destinos = planta.destinos.map((e, i) => index !== i ? e : {
            ...e,
            seleccionado: value,
            tiempoLlegada: null
        })

        onChange("destinos", destinos)
    }

    return (
        <table>
            <thead>
            <tr>
                <th>{t("Destino")}</th>
                <th style={{width: '14%'}}>{t("Tiempo de Llegada (hrs)")}</th>
                <th style={{width: '14%'}}>{t("Alerta carta porte (hrs)")}</th>
                <th>{t("Ruta")}</th>
                <th style={{width: '14%'}}>{t("Tipo")}</th>
            </tr>
            </thead>
            <tbody>
            {planta.destinos.map((row, index) => (
                    <tr key={row.id}>
                        <td>
                            <FieldCheckBox label={row.name} value={row.seleccionado}
                                           onChange={onChangeDestino(index)}/>
                        </td>
                        <td>
                            {row.seleccionado &&
                            <FieldTextBox name={`destinos[${index}].tiempoLlegada`} variant="standard"
                                          value={row.tiempoLlegada} isNumeric precision={0}
                                          maxLength={3} onChange={onChange}
                                          disabled={!row.seleccionado}/>
                            }
                        </td>
                        <td>
                            {row.seleccionado &&
                            <FieldTextBox name={`destinos[${index}].tiempoAlertaCartaPorte`}
                                          value={row.tiempoAlertaCartaPorte} isNumeric precision={0}
                                          maxLength={3} onChange={onChange} variant="standard"
                                          disabled={!row.seleccionado}/>
                            }
                        </td>
                        <td>
                            {row.seleccionado &&
                            <FieldTextBox name={`destinos[${index}].ruta`} variant="standard"
                                          value={row.ruta} onChange={onChange}/>
                            }
                        </td>
                        <td>
                            {row.seleccionado &&
                            <FieldDropDown value={row.tipoId} xs variant="standard" source="generales"
                                           translate valueById filter={{name: 'PlantaDestinoTipo'}}
                                           name={`destinos[${index}].tipoId`} onChange={onChange}
                                           disableClearable/>
                            }
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    );
}
