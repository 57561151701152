function mergeObject(properties, destinationObject, value) {
    if (properties.length === 1) {
        destinationObject[properties[0]] = value
        return
    }

    const propertyName = properties.shift()
    const isArray = propertyName.indexOf('[') >= 0;
    let childObject = null;
    if (isArray){
        const index = propertyName.indexOf('[');
        let array = destinationObject[propertyName.substring(0,index)];
        childObject = array[parseInt(propertyName.substring(index+1).replace(']',''))];
    }
    else {
        childObject = destinationObject[propertyName]
        if (childObject == null) {
            childObject = {};
            destinationObject[propertyName] = childObject;
        }
    }



    mergeObject(properties, childObject, value)
}
export const setDeepValue = (propertyName, destinationObject, values) =>  {
    if (propertyName != null){
        mergeObject(propertyName.split('.'), destinationObject, values);
        return;
    }
    for (let [key, value] of values) {
        mergeObject(key.split('.'), destinationObject, value);
    }
}

let timer = null;
export const delay  = ( method ) => {
    return new Promise((resolve) => {
        if (timer != null)
            clearTimeout(timer);

        timer = setTimeout(()=>{
            method().then((result) => resolve(result));
        } ,600);
    })
}

export const isString= (value) =>{
    return typeof value === 'string' || value instanceof String;
}

export const newGuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

