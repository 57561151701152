import React from "react";
import moment from "moment";

const DateFormatter = ({value}) => (
    <>
        { value != null ? moment(value).format('DD/MM/YYYY HH:mm') : '' }
    </>
);

export default DateFormatter;
