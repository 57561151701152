/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button, Checkbox, Grid, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import padStart from "lodash/padStart";
import {Cancel, Print, Save, Search} from "@mui/icons-material";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import useNotification from "../../../hooks/useNotification";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import DialogUi from "../../shared/modal/Dialog";
import {ValidationContext} from "../../shared/validationContext";
import * as yup from "yup";
import useValidate from "../../../hooks/useValidate";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";
import {RACK_ESTATUS_ETIQUETA, RACK_ESTATUS_LIST} from "../../../utility/constants";
import useMessage from "../../../hooks/useMessage";

const validationSchema = yup.object().shape({
    estatusId: yup.number().required(),
    comentariosInactivo: yup.string().trim()
        .when("activo", {
            is: false,
            then: yup.string().required()
        })
});

export default function Racks({
                                  tipoRack,
                                  setTipoRack,
                                  onChange,
                                  allRacks,
                                  setAllRacks,
                                  setRepeatedRack
                              }) {
    const notification = useNotification();
    const {t} = useTranslation();
    const opcionesCopias = [{id: 1, name: "1"}, {id: 2, name: "2"}];
    const api = useApiRackManagement();
    const message = useMessage();

    const [totalCopies, setTotalCopies] = useState();
    const [rackFilter, setRackFilter] = useState();
    const [activarAbierto, setActivarAbierto] = useState(false);
    const [rackAbierto, setRackAbierto] = useState({});
    const [tituloActivar, setTituloActivar] = useState('');
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(validationSchema, rackAbierto, setErrors);

    const onChangeGenerico = (id, index) => (e, checked) => {
        const totalGenericos = allRacks.reduce((sum, e) => sum + (e.esGenerico && e.activo ? 1 : 0), 0);

        if (totalGenericos > 1 && checked) {
            notification.error("No puede haber mas de 2 racks genéricos");
            return;
        }

        const numeroRack = totalGenericos === 0 ? "0000" : "F000";
        const racksCopy = tipoRack.racks.map((e, i) => {
                return i === index ? {
                    ...e,
                    numeroRack: checked ? numeroRack : e.numeroRack,
                    esGenerico: checked,
                    modificado: true
                } : {...e};
            }
        );

        onChange('racks', racksCopy, id);
    };

    const onChangeNumeroRack = (id, index) => (name, value) => {
        const rackRepetido = tipoRack.racks.some((e, i) => i !== index && e.numeroRack === value);

        const racksCopy = tipoRack.racks.map((e, i) => {
                return i === index ? {...e, numeroRack: value, repeated: rackRepetido, modificado: true} : {
                    ...e,
                    repeated: false
                };
            }
        );

        setRepeatedRack(racksCopy.some(e => e.repeated));
        onChange('racks', racksCopy, id);
    };

    const onChangeRack = (id, index, name) => (indexName, value) => {
        const racksCopy = tipoRack.racks.map((e, i) => i === index ? {...e, [name]: value, modificado: true} : {...e});
        onChange('racks', racksCopy, id);
    }

    const onChangeRacks = async (racks, changeType, rackEliminado) => {
        if (changeType === 'add') {
            addRack();
        } else if (changeType === 'remove') {
            if (!rackEliminado.esNuevo) {
                await message.confirm(t("Se eliminará el rack"), t("¿Desea Continuar?"));
                await api.deleteRack(tipoRack.id, rackEliminado.id);
            }

            setRepeatedRack(racks.some(e => e.repeated));
            onChange('racks', racks);
            setAllRacks(racks);
        }
    }

    const onChangeRackAbierto = (name, value) => {
        setRackAbierto({...rackAbierto, [name]: value});
    }

    const addRack = () => {
        let consecutivo = 1;
        let sort = 1;

        if (tipoRack.racks.length > 0) {
            const numbers = tipoRack.racks.map(e => {
                return /^\d+$/.test(e.numeroRack) ? Number(e.numeroRack) : 0;
            });
            consecutivo = Math.max(...numbers) + 1;
            sort = Math.max(...tipoRack.racks.map(e => e.sort)) + 1;
        }

        const numeroRack = padStart(consecutivo, 4, '0');
        const id = -(Math.floor(Math.random() * 100000));//id temporal

        const racksCopy = [{
            id,
            numeroRack,
            activo: true,
            esGenerico: false,
            esNuevo: true,
            sort,
            estatusEtiqueta: RACK_ESTATUS_ETIQUETA.pendienteEtiquetar
        }, ...tipoRack.racks];

        onChange('racks', racksCopy);
        setAllRacks(racksCopy);
    }

    const filterRacks = ({target}) => {
        const valores = target.value.split(",");
        const noFiltrar = valores.length === 1 && valores[0].trim() === "";
        const racksCopy = noFiltrar ? [...allRacks]
            : allRacks.filter((e) => valores.some(v => v.trim() !== "" && e.numeroRack.includes(v.trim())));

        onChange('racks', racksCopy);
        setRackFilter(target.value);
    };

    const onChangeCopies = (name, value) => {
        const racksCopy = tipoRack.racks.map(e => e.imprimir ? {...e, copias: value} : e);

        setTotalCopies(value);
        onChange('racks', racksCopy);
    };

    const checkImprimir = (index) => (e, checked) => {
        const racksCopy = tipoRack.racks.map((e, i) => i !== index ? e : {
            ...e,
            imprimir: checked,
            copias: checked ? (totalCopies ? totalCopies : 1) : null
        });

        const noImprimirTodo = racksCopy.some(e => !e.imprimir);
        setTipoRack({...tipoRack, racks: racksCopy, imprimirTodo: !noImprimirTodo});
    };

    const checkImprimirTodo = (e, checked) => {
        const racksCopy = tipoRack.racks.map((e) => {
            return e.esNuevo ? {...e} : {
                ...e,
                imprimir: checked,
                copias: checked ? (totalCopies ? totalCopies : 1) : null
            };
        });

        setTipoRack({...tipoRack, racks: racksCopy, imprimirTodo: checked});
    };

    const onChangeActivo = (index, rack) => (name, activo) => {
        onChange(`racks[${index}].activo`, activo, rack.id);

        setRackAbierto({...rack, activo});
        setTituloActivar(activo ? 'Activar' : "Desactivar");
        setActivarAbierto(true);
        clearValidation();
    }

    const cancelarCambioActivo = () => {
        const index = tipoRack.racks.findIndex(e => e.id === rackAbierto.id);
        onChange(`racks[${index}].activo`, !rackAbierto.activo, rackAbierto.id);

        setActivarAbierto(false);
    }

    const saveRack = async () => {
        const valid = await validate();
        if (!valid) return;

        if (!rackAbierto.esNuevo)
            await api.activarRack(tipoRack.id, rackAbierto);

        const index = tipoRack.racks.findIndex(e => e.id === rackAbierto.id);
        const copiaRacks = tipoRack.racks.map((e, i) => i !== index ? {...e} : {
            ...e,
            estatusId: rackAbierto.estatusId,
            comentariosInactivo: rackAbierto.comentariosInactivo
        })
        onChange("racks", copiaRacks, rackAbierto.id);

        setActivarAbierto(false);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Typography variant="h6">{t('Racks')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="standard" size="small" onChange={filterRacks} value={rackFilter} fullWidth
                               InputProps={
                                   {endAdornment: (<InputAdornment position="start"><Search/></InputAdornment>)}
                               }
                    />
                    <Box fontSize={10}>{t("BusquedaRacks")}</Box>
                </Grid>
            </Grid>

            <div className="main-grid">
                <ExGrid data={tipoRack.racks}
                        rowHeight={40}
                        showAddDeleteColumn={!rackFilter}
                        buttonsDisabled={!tipoRack.alias || !tipoRack.descripcion}
                        addTitle={t('Agregar Rack')}
                        onChange={onChangeRacks}
                >
                    <ExColumn
                        field="name"
                        width="9.5fr"
                        title={t('Nombre')}
                        cellRenderer={({row, rowIndex}) =>
                            <FieldTextBox value={row.numeroRack} xs
                                          variant="standard"
                                          error={row.repeated}
                                          name={`racks[${rowIndex}].numeroRack`}
                                          maxLength={8}
                                          onChange={onChangeNumeroRack(row.id, rowIndex)}
                                          helperText={row.repeated ? t("El nombre del rack esta repetido") : ''}
                                          startAdornment={<InputAdornment position="start" sx={{pb: 0.5}}>
                                              {tipoRack.name}-</InputAdornment>}
                            />
                        }
                    />
                    <ExColumn
                        title={t('Activo')}
                        field="activo"
                        width="1fr"
                        cellHeaderClass='center'
                        cellClass='center'
                        cellRenderer={({row, rowIndex}) =>
                            <Checkbox checked={row.activo}
                                      onChange={onChangeActivo(rowIndex, row)}/>
                        }
                    />
                    <ExColumn
                        title={t('Genérico')}
                        field="esGenerico"
                        width="1.5fr"
                        cellHeaderClass='center'
                        cellClass='center'
                        cellRenderer={({row, rowIndex}) =>
                            <Checkbox checked={row.esGenerico} onChange={onChangeGenerico(row.id, rowIndex)}/>
                        }
                    />
                    <ExColumn
                        title={t('Estatus')}
                        field="estatusId"
                        width="2fr"
                        cellHeaderClass='center'
                        cellRenderer={({row}) => t(RACK_ESTATUS_LIST.get(row.estatusId))}
                    />
                    <ExColumn
                        title={t('Estatus Etiqueta')}
                        field="estatusEtiqueta"
                        width="3fr"
                        cellRenderer={({row, rowIndex}) =>
                            <FieldDropDown value={row.estatusEtiqueta} xs variant="standard" source="estatus"
                                           disableClearable valueById filter={{name: 'RackEtiqueta'}} translate
                                           onChange={onChangeRack(row.id, rowIndex, "estatusEtiqueta")}/>
                        }
                    />
                    <ExColumn
                        field="imprimir"
                        width="1.5fr"
                        cellHeaderClass='center'
                        cellClass='center'
                        headerRenderer={() =>
                            <Box display="flex" alignItems="center">
                                <Tooltip title={t('Imprimir')}><Print/></Tooltip>
                                <Checkbox checked={tipoRack.imprimirTodo} onChange={checkImprimirTodo}/>
                            </Box>
                        }
                        cellRenderer={({row, rowIndex}) =>
                            <>
                                {!row.esNuevo &&
                                <Checkbox checked={row.imprimir} onChange={checkImprimir(rowIndex)}/>
                                }
                            </>
                        }
                    />
                    <ExColumn
                        field="copias"
                        width="1fr"
                        headerRenderer={() =>
                            <FieldDropDown label={t('Copias')} variant="standard" onChange={onChangeCopies}
                                           options={opcionesCopias} value={totalCopies}
                                           valueById disableClearable/>
                        }
                        cellRenderer={({row, rowIndex}) =>
                            <FieldDropDown value={row.copias} xs variant="standard" onChange={onChange}
                                           name={`racks[${rowIndex}].copias`} options={opcionesCopias}
                                           disabled={!row.imprimir} valueById disableClearable/>
                        }
                    />
                </ExGrid>
            </div>

            <DialogUi open={activarAbierto} title={t(tituloActivar)} width="md" scroll="paper">
                <ValidationContext.Provider value={{errors: errors, setErrors: setErrors}}>
                    <Grid container spacing={2}>
                        <FieldDropDown label={t('Motivo')} name="estatusId" onChange={onChangeRackAbierto}
                                       source="estatus"
                                       filter={{name: rackAbierto.activo ? "rackActivo" : "rackInactivo"}}
                                       value={rackAbierto.estatusId} xs={6} valueById translate/>

                        <Grid item xs={6}/>

                        {!rackAbierto.activo &&
                        <Grid item xs>
                            <label>{t("Comentarios")}</label>
                            <FieldTextBox name="comentariosInactivo" value={rackAbierto.comentariosInactivo}
                                          onChange={onChangeRackAbierto} multiline rows={2} fullWidth size="small"/>
                        </Grid>
                        }
                    </Grid>
                </ValidationContext.Provider>

                <Box className="buttons">
                    <Button variant="outlined" size="small" onClick={cancelarCambioActivo} startIcon={<Cancel/>}>
                        {t("Cancelar")}
                    </Button>

                    <Button variant="contained" color="primary" size="small" startIcon={<Save/>}
                            onClick={saveRack}>
                        {t("Guardar")}
                    </Button>
                </Box>
            </DialogUi>
        </>
    );
}
