/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, ButtonGroup, Grid, Tooltip, Typography} from "@mui/material";
import produce from "immer";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export default function Filter({titulo, valorDefault, buscar, ...props}) {
    valorDefault = valorDefault ?? {};

    const {t} = useTranslation();
    const elements = React.Children.toArray(props.children);
    const forma = elements.length === 1 ? elements[0] : elements[1];
    const header = elements.length > 1 ? elements[0] : null;

    const [filtro, setFiltro] = useState(valorDefault);

    useEffect(() => {
        buscar(filtro);
    }, []);

    const onChange = (name, value) => {
        setFiltro(produce(filtro, (draft) => {
            draft[name] = value
        }));
    };

    const limpiarFiltros = async () => {
        setFiltro(valorDefault);
        await buscar(valorDefault);
    };

    return (
        <>
            <Grid container mb={2}>
                <Grid item xs={12} sm={6} md={8}>
                    {header != null?
                        React.cloneElement(header)
                        :
                        <Typography variant="h6" fontWeight={700}>
                            {titulo}
                        </Typography>
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={4} textAlign="right">
                    <ButtonGroup variant="contained">
                        <Button variant="contained" startIcon={<SearchIcon/>} onClick={() => buscar(filtro)}>
                            {t('Filtrar')}
                        </Button>
                        <Tooltip title={t('Limpiar Filtros')}>
                            <Button onClick={limpiarFiltros}>
                                <ClearIcon sx={{fontSize: 18}}/>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
                {React.cloneElement(forma, {filtro, onChange})}
            </Grid>
        </>
    );
}
