/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import FieldTimeRange from "../../shared/fields/FieldTimeRange";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";

export default function PlantWorkShift({planta, onChange}) {
    const {t} = useTranslation();
    const indexUltimoTurno = (planta.turnos?.length?? 0) - 1;

    const onChangeTurno = (index) => (name, value) => {
        const indexAnterior = index === 0 ? indexUltimoTurno : index - 1;
        const siguienteIndex = index === indexUltimoTurno ? 0 : index + 1;

        const copiaTurnos = planta.turnos.filter((e, i) => ![index, indexAnterior, siguienteIndex].includes(i));

        planta.turnos.forEach((e, i) => {
            let copiaTurno = {...e};

            if (i === indexAnterior) {
                copiaTurno.horas = {dateFrom: planta.turnos[i].horas?.dateFrom, dateTo: value.dateFrom};
            } else if (i === index) {
                copiaTurno.horas = value;
            } else if (i === siguienteIndex) {
                copiaTurno.horas = {dateFrom: value.dateTo, dateTo: planta.turnos[i].horas?.dateTo};
            }

            copiaTurnos.push(copiaTurno);
        });

        onChange("turnos", copiaTurnos);
    }

    return (
        <>
            <div className="subtitle">
                {t("Turnos")}
            </div>

            <Grid container spacing={2} columns={27}>
                {planta.turnos?.map((turno, i) =>
                    <React.Fragment key={i}>
                        <FieldTimeRange label={`${t("Turno")} ${i + 1}`} onChange={onChangeTurno(i)} value={turno.horas}
                                        xs={6} name={`turnos[${i}].horas`} disabled={i === indexUltimoTurno}/>

                        <FieldCheckBox label={"Activo"} value={turno.activo} onChange={onChange} xs={3} mt={2}
                                       name={`turnos[${i}].activo`}/>
                    </React.Fragment>
                )}
            </Grid>
        </>
    );
}
