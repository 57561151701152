/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
    Button,
    Popover,
    TableCell,
    Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodeIcon from '@mui/icons-material/QrCode';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
    VIAJE_TIPO as tipoViaje,
    VIAJESTATUS_LIST,
    VIAJE_ESTATUS as estatusViaje, VIAJE_RETRASO as retraso,
} from "../../../../utility/constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BorderLinearProgress, StyledBadge} from "../../../shared/StyledComponents";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import useApiViaje from "../../../../api/operation/useApiViaje";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../../../utility/dateUtility";

const theme = createTheme({palette: {error: {main: '#ef9a9a'}, warning: {main: '#ffeb3b'}}});

export default function TripGridActions({
                                            data,
                                            setValue,
                                            filter,
                                            verDetalle,
                                            verEliminar,
                                            copiarRacks,
                                            getReporteRacks,
                                            esPerfilSeguridad,
                                            isReadonly
                                        }) {
    const api = useApiViaje();
    const {t} = useTranslation();
    const disableDelete = [estatusViaje.reciboParcial, estatusViaje.recibido];

    const [alertas, setAlertas] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const mostrarAlertas = (event) => {
        api.getAlertas(data.id).then((result) => {
            setValue(0);
            setAlertas(result.alertas);
        });

        setAnchorEl(event.currentTarget);
    };

    const cerrarAlertas = () => {
        setAnchorEl(null);
    };

    const disabled = () => {
        return disableDelete.includes(data.estatus) || (!data.creadoEnDestino && data.estatus === estatusViaje.enTransito);
    }

    const getMensajeAlerta = (alerta) => {
        return `${alerta.horas} ${t("hrs en estatus")} ${t(VIAJESTATUS_LIST.get(alerta.estatusViaje))}`
    }

    return (
        <>
            <div className="ag-grid row-icons">
                {!esPerfilSeguridad &&
                <>
                    <IconButton size="small" onClick={mostrarAlertas} disabled={data.totalAlertas === 0}>
                        <ThemeProvider theme={theme}>
                            <StyledBadge color={data.estiloEstatus === 'background-red' ? 'error' : 'warning'}
                                         badgeContent={data.alertasSinVer}>
                                <NotificationsNoneIcon color={data.totalAlertas === 0 ? "disabled" : "action"}/>
                            </StyledBadge>
                        </ThemeProvider>
                    </IconButton>

                    <Popover open={open} anchorEl={anchorEl} onClose={cerrarAlertas}
                             anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                        <Box p={1}>
                            <Table size="small">
                                <TableBody>
                                    {alertas.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{'&:last-child td': {border: 0}}}
                                        >
                                            <TableCell>
                                                {getMensajeAlerta(row)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Popover>

                    {filter.tipo === tipoViaje.vacio && !filter.esEnvio &&
                    <Tooltip title={t('Reporte de Racks')}>
                            <span>
                            <IconButton size="small" onClick={() => getReporteRacks(data)}
                                        disabled={data.estatus !== estatusViaje.recibido}>
                                <SummarizeOutlinedIcon fontSize="inherit"/>
                            </IconButton>
                            </span>
                    </Tooltip>
                    }

                    {filter.tipo === tipoViaje.cargado &&
                    <Tooltip title={t('Copiar Lotes')}>
                        <IconButton size="small" onClick={copiarRacks(data.id, true)}>
                            <ContentCopyIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                    }

                    <Tooltip title={t('Copiar Racks')}>
                        <IconButton size="small" onClick={copiarRacks(data.id, false)}>
                            <QrCodeIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>

                    {!isReadonly &&
                    <Tooltip title={t('Eliminar Registro')}>
                            <span>
                            <IconButton color="error" size="small" onClick={() => verEliminar(data.id)}
                                        disabled={disabled()}>
                                <DeleteIcon fontSize="small"/>
                            </IconButton>
                            </span>
                    </Tooltip>
                    }
                </>
                }

                <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                    {t('Ver')}
                </Button>
            </div>
        </>
    )
}

const colores = {
    red: ["#ff4b4b", "#d50505"],
    green: ["#00d661", "#00b050"],
    blue: ["#0288d1", "#1d6781"],
    yellow: ["#ffeb3b", "#e5d225"],
    orange: ["#ed6c02", "#d56305"],
};

export const CeldaEstatusViaje = ({data}) => {
    let color = "green";
    let avance = 100;

    if (data.terminado && data.retrasado) {
        color = "blue";
    } else if (!data.terminado) {
        avance = data.avance > 100 ? 100 : data.avance;
        if (data.retraso > 0) {
            color = data.retraso > retraso.horasAlertaEnTransito ? "red"
                : data.retraso > retraso.horasPrecaucionEnTransito ? "orange" : "yellow";
        }
    }

    const color1 = colores[color][0];
    const color2 = colores[color][1];
    let background = `repeating-linear-gradient(125deg,${color1},${color1} 10px,${color2} 5px,${color2} 13px)`;

    return (
        <div>
            <BorderLinearProgress variant="determinate" value={avance} background={background}/>

            {data.tiempoEnViaje > 0 &&
            <Box className="status-bar">
                <Box><b>ETA: </b>{formatDate(data.eta, true)}</Box>
                <Box textAlign="center">{data.tiempoEnViaje}/{data.tiempoEstimado} hrs</Box>
            </Box>
            }
        </div>
    )
}
