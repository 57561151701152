/* eslint-disable react-hooks/exhaustive-deps */
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {ValidationContext} from "../../shared/validationContext";
import {Button, Grid, Collapse} from "@mui/material";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import * as React from "react";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import produce from "immer";
import {newGuid, setDeepValue} from "../../../utility/objectUtility";
import DeleteIcon from "@mui/icons-material/Delete";
import {removeAtIndex} from "../../../utility/arrayUtility";
import useMessage from "../../../hooks/useMessage";
import useNotification from "../../../hooks/useNotification";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import useValidate from "../../../hooks/useValidate";
import transferSchema from "../transfer/transferValidation"
import useApiTransfer from "../../../api/operation/useApiTransfer";
import FieldDateTime from "../../shared/fields/FieldDateTime";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import groupBy from "lodash/groupBy";

export default function TransferDetail({traspasoId, open, close, onSave, temporalId}) {
    const defaultEntity = {id: 0, requerimientos: [], esVacio: false, movimientos: []};
    const {t} = useTranslation();
    const [titulo, setTitulo] = useState('');
    const [traspaso, setTraspaso] = useState(defaultEntity);
    const [errors, setErrors] = useState({});
    const message = useMessage();
    const notification = useNotification();
    const [validate, clearValidation] = useValidate(transferSchema, traspaso, setErrors);
    const api = useApiTransfer();

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            result.tiposRack = getTiposRack(result.racks);
            setTraspaso(result);
        }

        if (traspasoId > 0) {
            setTitulo(t("Ver"));
            load(traspasoId).then();
        } else {
            setTitulo(t("Agregar"));
            setTraspaso(defaultEntity);
        }

        clearValidation();
    }, [temporalId]);

    const getTiposRack = (racks) => {
        const tiposRack = [];
        const tiposRackIds = groupBy(racks, "tipoRackId");

        for (const tipoRackId in tiposRackIds) {
            const primerRack = tiposRackIds[tipoRackId][0];
            const racksAgrupados = tiposRackIds[tipoRackId];
            const cantidad = racksAgrupados.reduce((sum, e) => sum + e.cantidad, 0);
            const id = Number(tipoRackId);

            tiposRack.push({
                id,
                name: primerRack.tipoRack,
                cantidad,
                racks: racksAgrupados,
                expandido: true
            })
        }

        return tiposRack;
    };

    const onChange = (name, value) => {
        const newEntity = produce(traspaso, (draft) => {
            setDeepValue(name, draft, value);
        });

        setTraspaso(newEntity);
    };

    const addRequerimiento = () => {
        if (traspaso.cantidad === null || traspaso.cantidad === 0 || traspaso.tipoRack === null
            || traspaso.cantidad === undefined | traspaso.tipoRack === undefined) {
            notification.warning(t("Favor de seleccionar el Tipo de Rack y la Cantidad"))
            return;
        }

        const existe = traspaso.requerimientos.some((e, i) => e.tipoRackId !== traspaso.tipoRack.id);
        if (!existe && traspaso.requerimientos.length > 0) {
            notification.warning(t("El tipo de rack seleccionado ya fué agregado a la lista"))
            return;
        }

        const requerimientos = [
            {
                _guid: newGuid(),
                cantidad: traspaso.cantidad,
                tipoRackId: traspaso.tipoRack.id,
                tipoRack: traspaso.tipoRack.name,
            }, ...traspaso.requerimientos];

        setTraspaso({...traspaso, tipoRack: null, cantidad: null, requerimientos});
    }

    const deleteRequerimiento = async (index) => {
        let response = await message.confirmReject(t("¿Está seguro que desea eliminar este tipo de rack?"), t("¿Desea Continuar?"));
        if (response === true) {
            onChange(`requerimientos`, removeAtIndex(traspaso.requerimientos, index))
        }

    }

    const save = async () => {
        const valid = await validate();
        if (!valid) return;
        await api.save(traspaso);
        onClose();
    }

    const onClose = () => {
        close();
        onSave();
    }

    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Traspaso")}`} open={open} close={close} size={700}>
                <ValidationContext.Provider value={{errors, setErrors, allDisabled: traspaso.soloLectura}}>
                    <Grid container mb={3} fontWeight={700} fontSize={16}>
                        <Grid xs={3}>
                            <label>{t("Origen")}</label>
                        </Grid>
                        <Grid xs={3} style={{textAlign: "right"}}><label>→</label></Grid>
                        <Grid xs={3}><label>{t("Destino")}</label></Grid>

                    </Grid>
                    <Grid container spacing={2} mb={2}>

                        <FieldDropDown label={t('Planta')} xs={6} name="plantaId" source="plantas"
                                       valueById value={traspaso.plantaId} onChange={onChange}/>

                        <FieldDropDown label={t('Planta')} xs={6} name="plantaId" source="plantas"
                                       valueById value={traspaso.plantaId} disabled={true}/>

                        <FieldDropDown label={t('Almacén')} xs={6} name="almacenOrigenId" source="almacenes"
                                       valueById value={traspaso.almacenOrigenId} onChange={onChange}
                                       filter={{plantaId: traspaso.plantaId}}
                        />

                        <FieldDropDown label={t('Almacén')} xs={6} name="almacenDestinoId" source="almacenes"
                                       valueById value={traspaso.almacenDestinoId} onChange={onChange}
                                       filter={{plantaId: traspaso.plantaId}}
                        />

                        <FieldDropDown label={t('Area')} xs={6} name="areaOrigenId" source="areas"
                                       valueById value={traspaso.areaOrigenId} onChange={onChange}
                                       filter={{almacenId: traspaso.almacenOrigenId}}
                        />

                        <FieldDropDown label={t('Area')} xs={6} name="areaDestinoId" source="areas"
                                       valueById value={traspaso.areaDestinoId} onChange={onChange}
                                       filter={{almacenId: traspaso.almacenDestinoId}}
                        />
                        <FieldCheckBox label={t('Es Vacío')} name="esVacio" value={traspaso.esVacio} xs={6}
                                       onChange={onChange}/>
                    </Grid>

                    <br/>
                    <Grid container spacing={2} mb={2}>
                        <Grid xs={4} item>
                            <label>{t("Fecha de Solicitud")}</label>
                        </Grid>
                        <FieldDateTime name="fechaSolicitud" value={traspaso.fechaSolicitud}
                                       xs={8} onChange={onChange} disabled={traspaso.soloLectura}/>

                        <Grid xs={4} item>
                            <label>{t("Fecha de Transferencia")}</label>
                        </Grid>
                        <FieldDateTime name="fechaTraspaso" value={traspaso.fechaTraspaso}
                                       xs={8} disabled/>
                    </Grid>

                    <br/>

                    {!traspaso.soloLectura &&
                    <Grid container spacing={2} mb={2}>
                        <FieldAutoComplete label={t('Tipo de Rack')} name="tipoRack" value={traspaso.tipoRack}
                                           xs={7}
                                           source="tiposRack" onChange={(name, value) => {
                            onChange(name, value)
                        }} variant="standard"/>

                        <FieldTextBox label={t("Cantidad")} name="cantidad" value={traspaso.cantidad} xs={4}
                                      isNumeric precision={2} onChange={onChange} variant="standard" maxLength={3}/>


                        <IconButton color="primary" xs={1} onClick={() => {
                            addRequerimiento();
                        }}>
                            <AddCircleOutlineIcon/>
                        </IconButton>

                    </Grid>
                    }
                    <Box variant="label" sx={{fontWeight: 'bold'}}>
                        {t('Requerimientos')}
                    </Box>

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '75%'}}>
                                    {t('Tipo de Rack')}
                                </TableCell>
                                <TableCell sx={{width: '20%'}}>
                                    {t('Cantidad')}
                                </TableCell>

                                {!traspaso.soloLectura && <TableCell sx={{width: '5%'}}/>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {traspaso.requerimientos.map((requerimiento, index) => (
                                <>
                                    <TableRow key={requerimiento.id}>
                                        <TableCell>
                                            {requerimiento.tipoRack}
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {requerimiento.tieneMovimientos && requerimiento.cantidadRecibida}
                                            {requerimiento.tieneMovimientos && ' / '}
                                            {requerimiento.cantidad}
                                        </TableCell>

                                        {!traspaso.soloLectura &&
                                        <TableCell align={"center"}>
                                            <IconButton color="error" xs={6}
                                                        onClick={() => deleteRequerimiento(index)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                        }
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                    <br/>

                    {traspaso.tiposRack?.length > 0 &&
                    <Box variant="label" sx={{fontWeight: 'bold'}}>
                        {t('Movimientos')}
                    </Box>
                    }
                    {traspaso.tiposRack?.length > 0 &&
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '5%'}}/>
                                <TableCell sx={{width: '35%'}}>{t('Lote')}</TableCell>
                                <TableCell sx={{width: '60%'}}>{t('Racks')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {traspaso.tiposRack?.map((tipoRack, index) => (
                                <>
                                    <TableRow key={index} sx={{'td': {border: 0}}}>
                                        <TableCell sx={{padding: 0}}>
                                            <IconButton size="small"
                                                        onClick={() => {
                                                            onChange(`tiposRack[${index}].expandido`, !tipoRack.expandido)
                                                        }}>
                                                {tipoRack.expandido ? <KeyboardArrowUpIcon/> :
                                                    <KeyboardArrowDownIcon/>}
                                            </IconButton>
                                        </TableCell>

                                        <TableCell colSpan={2}>
                                            <b>{tipoRack.name} ({tipoRack.cantidad})</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell style={{padding: 0}} colSpan={3}>
                                            <Collapse in={tipoRack.expandido} timeout="auto" unmountOnExit>
                                                <Table size="small">
                                                    <TableBody sx={{'tr:last-child td': {borderBottom: 0}}}>
                                                        {tipoRack.racks?.map((rack, indexDetail) => (
                                                            <>
                                                                <TableRow key={indexDetail}>
                                                                    <TableCell sx={{width: '5%'}}>
                                                                    </TableCell>
                                                                    <TableCell sx={{width: '35%'}}>
                                                                        {rack.lote}
                                                                    </TableCell>
                                                                    <TableCell sx={{width: '60%'}}>
                                                                        {rack.name} ({rack.cantidad})
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Collapse>
                                        </TableCell>

                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                    }


                </ValidationContext.Provider>
                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={onClose}>
                            {t('Cerrar')}
                        </Button>

                        {!traspaso.soloLectura &&
                        <Button variant="contained" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                        }

                    </Box>
                </div>
            </SidebarRight>
        </>
    )
}
