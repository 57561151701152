/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, Grid, Tab, Tabs, Tooltip, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import TabPanel from "../../shared/TabPanel";
import InventoryCount from "./InventoryCount";
import Transfer from "../transfer/Transfer";
import useApiConteoInventario from "../../../api/operation/useApiConteoInventario";
import {CONTEO_INVENTARIO_ESTATUS as estatusConteo, MENUS_IDS} from "../../../utility/constants";
import Inventory from "./Inventory";
import useApiInventario from "../../../api/operation/useApiInventario";
import useApiTransfer from "../../../api/operation/useApiTransfer";
import {AppContext} from "../../Main";
import ReadOnlyProvider from "../../security/ReadOnlyProvider";
import {useParams} from "react-router-dom";

export default function MainInventory() {
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const tabInicial = Number(useParams().tab);
    const filtrosDefault = {
        plantasIds: [],
        almacensIds: [],
        areasIds: []
    };

    const estatusDefaultConteo = [estatusConteo.enOperacion, estatusConteo.programado, estatusConteo.completadoParcial];

    //El api y el filtro default estan ligados al número de tab
    const propiedadesTab = {
        0: {api: useApiInventario(), filtroDefault: {...filtrosDefault, plantasIds: user.plantas.map(e => e.id)}},
        1: {api: useApiTransfer(), filtroDefault: {...filtrosDefault}},
        2: {api: useApiConteoInventario(), filtroDefault: {...filtrosDefault, estatusIds: estatusDefaultConteo}}
    };

    const [numeroTab, setNumeroTab] = useState(tabInicial);
    const [datos, setDatos] = useState([]);
    const [filtro, setFiltro] = useState(propiedadesTab[tabInicial].filtroDefault);

    useEffect(() => {
        buscar(tabInicial).then();
    }, []);

    const onChangeTab = async (event, value) => {
        const filtroDefualtTab = propiedadesTab[value].filtroDefault;

        setNumeroTab(value);
        setFiltro(filtroDefualtTab);

        await buscar(value, filtroDefualtTab);
    };

    const buscar = async (tab, filtroDefault) => {
        tab = tab ?? numeroTab;
        let nuevoFiltro = filtroDefault ?? filtro;
        nuevoFiltro = {
            ...nuevoFiltro,
            tipoRacksIds: nuevoFiltro.tipoRacksIds?.join(),
            plantasIds: nuevoFiltro.plantasIds.join()
        };
        const result = await propiedadesTab[tab].api.filter(nuevoFiltro);

        setDatos(result);
    }

    const limpiarFiltro = async () => {
        const filtroDefualtTab = propiedadesTab[numeroTab].filtroDefault;
        setFiltro(filtroDefualtTab);

        await buscar(numeroTab, filtroDefualtTab);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6} md={8} mb={2}>
                    <Typography variant="h6" fontWeight={700}>
                        <span>{t('Inventario')}</span>
                        {numeroTab === 0 &&
                        <span>, {t('cantidad')}: {datos.length}</span>
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} textAlign="right">
                    <ButtonGroup variant="contained">
                        <Button variant="contained" onClick={() => buscar()} startIcon={<SearchIcon/>}>
                            {t('Filtrar')}
                        </Button>
                        <Tooltip title={t('Limpiar Filtros')}>
                            <Button onClick={limpiarFiltro}>
                                <ClearIcon sx={{fontSize: 18}}/>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Tabs value={numeroTab} onChange={onChangeTab} sx={{mt: -2.5}}>
                <Tab label={t("Inventario")}/>
                <Tab label={t("Traspasos")}/>
                <Tab label={t("Conteo de Inventario")}/>
            </Tabs>
            <TabPanel index={numeroTab} value={1} className="full-tab-panel">
                <ReadOnlyProvider menuOptionId={MENUS_IDS.mainInventory}>
                    <Transfer datos={datos} filtro={filtro} setFiltro={setFiltro} buscar={buscar}/>
                </ReadOnlyProvider>
            </TabPanel>

            <TabPanel value={numeroTab} index={0} className="full-tab-panel">
                <Inventory datos={datos} filtro={filtro} setFiltro={setFiltro}/>
            </TabPanel>

            <TabPanel value={numeroTab} index={2} className="full-tab-panel">
                <ReadOnlyProvider menuOptionId={MENUS_IDS.mainInventory}>
                    <InventoryCount datos={datos} filtro={filtro} setFiltro={setFiltro} buscar={buscar}/>
                </ReadOnlyProvider>
            </TabPanel>
        </>
    );
}
