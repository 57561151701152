/* eslint-disable react-hooks/exhaustive-deps */
import useNotification from "./useNotification";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

export default function useValidate(schema, object, setErrors) {
    const notification = useNotification();
    const {t} = useTranslation();
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        refreshValidation();
    }, [object]);


    const validateSchema = () => {
        return new Promise((resolve, reject) => {
            schema.validate(object, {abortEarly: false})
                .then(() => {
                    resolve(true)
                })
                .catch(function (err) {
                    let errors = {};

                    for (let er of err.inner) {
                        const message = er.type === "required" || er.type === "typeError" ? "" : er.message;
                        errors[er.path] = {type: er.type, message};

                        if (er.path.includes("[")) {
                            const newPath = er.path.substring(0, er.path.indexOf("["));
                            errors[newPath] = {type: er.type, message};
                        }
                    }
                    reject(errors);
                })
        })
    }

    const validate = () => {
        return new Promise((resolve, reject) => {
            validateSchema()
                .then(() => {
                    setErrors({});
                    resolve(true)
                })
                .catch(function (errors) {
                    notification.error(t('Favor de revisar la información requerida o inválida'));
                    setDirty(true);
                    setErrors(errors);
                    resolve(false);
                })
        })
    }

    const refreshValidation = () => {
        if (!dirty) {
            return;
        }
        validateSchema()
            .then(() => {
                setErrors({});
            })
            .catch(function (errors) {
                setErrors(errors);
            });
    }

    const clearValidation = () => {
        setDirty(false);
        setErrors({});
    }

    return [validate, clearValidation]
}





