import React from 'react'
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const {onChange, precision, thousandSeparator, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={thousandSeparator ?? true}
            isNumericString
            decimalScale={props.precision}
        />
    );
});

export default NumberFormatCustom;

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    precision: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
