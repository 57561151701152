export const getTextWidth = (() => {
    const container = document.createElement('canvas');

    return function (inputText, backupRatio = 0.5) {
        let width = 0;
        let text = inputText ?? '';
        text = text.toString();

        let context = container.getContext('2d');

        if (context) {
            context.font = window
                .getComputedStyle(document.body)
                .getPropertyValue('font');
            width = context.measureText(text).width;
            return width;
        } else {
            /* if something goes wrong mounting the canvas, return an estimate calculated using
             * the backup ratio, the average open-sans font height-width ratio of 0.5
             */
            let fontSize = parseFloat(
                window.getComputedStyle(document.body).getPropertyValue('font-size')
            );
            return fontSize * backupRatio * text.length;
        }
    };
})();

export const getCoordenada = (texto) => {
    const largo = getTextWidth(texto) * 1.6;
    //el ancho total del código zpl es de 274, el centro es 137.
    //la relación entre el ancho del código zpl y el ancho del texto es 2 a 1
    //por eso ya no es necesario obtener el equivalente en los anchos, y despues dividr el largo entre 2 para obtener el centro del texto

    const inicioCoordenada = 137 - (largo / 2);

    //el código zpl empieza en la coordenada 25
    return inicioCoordenada < 0 ? 25 : inicioCoordenada + 25;
};

export const zplPrintCode = `
CT~~CD,~CC^~CT~
^XA
~TA000
~JSN
^LT40
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
~SD27
^JUS
^LRN
^CI27
^PA0,1,1,0
^RS8,,,1
^XZ
^XA
^MMT
^LS0
^FO742,41^GFA,337,1912,8,:Z64:eJzlkzEOwjAMRY06ZCw36BW4QTkSIwOiOVqPEsTA2jFDVQPxd1FCSyskoBLu8KTY/rbrhGjCmNnOYUPmTeaBHjxQEepWt+9uJXcRc/aBhhs0aAf7ziSMckmjggk6El9xHXjMhT5zgc1qHjVe81VP9bWe1td+Pm3MboLSH5kajYGY64nZmE4d6TG/7mvx+0jmLrmFTof5OKUd6mOnOl+y2fvGf9F9jpK2kX66t8e+lKLbXiTfn4XNaYTwe41H/h56yg3qrSfezc/vu7GJ7oj+v7yj3hwCwALE3AYsuuiYdHyVhbv3lzgwfUKib1xcP7GF3OcrDwhaPw==:EA39
^FO9,50^GFA,681,1760,8,:Z64:eJydlb9q3EAQxke3ZtfFIbWGHMkzhANjN/Yb+BkEgUuXJk0KYw6uSZVnUpEy5BlcnVuHNCqE1rvzfaNIsgKHt/APr2Znvvn2z4kMo4rNIofhLW6/HOdnlNn6/4035i2e8V/opixLcO2ntPlJfIjIk4jJOIhyfaaTQFbkmhHK4ifojmgj/EWc307p3mGRszo95pMI5ZkwvyVu4UsWYcz6Q2DepKcjW8wj/w+wOHvlD+rFR/UhUX0xypV4rXsP34seDE/kgbYUYuOO/KL1NnKj9W9Y9JbxX5eZ8gp9E/oY2f+EroWuJF51vq/AC7fXPj7+abSvbddon7uy1r53rtYkxk+HWvv43JMR3H1o9Pu2IA97XX8d4dvFA3ie7N9xe5SBvJXyIeuP4u/z9LOsvmXu2Sc3IX133xEffmF9dRznI0Os/SUYsF91ZfvXqmFZSj5HKBBis7pSPmr9dB5UT+La8h/1+8DqNwgdnrqccEMGTs9PC6ZzqD4kQi99GNWFDwE+OG6mUVpZHOE1l+93i3vseB6MqZ7Op/phA67O5aR7cXLebsQ0r3kW+rN7PGF+V9akHzH/KeG3vS8V3yXc6/6fSMbjiXJ8Lx3vZ6Re090N1mJ/hfsty+cuZ75knOmenDvBuRuNN7+/G3A1T8g4e4LnvwfzfCeNF8sW1KY=:3599
^FO622,35^GFA,1561,3984,16,:Z64:eJylV82KG0cQrplZPGJi0AbWUQ4LEjkJnXxIUG7RPkRuASunXHXYMBcHNUsw+xhzHPQUYxzII+S6j6DDgnwQntTvTHV7E4e4QDPq/fRVdf127wZQXvV9fy76/gFgQevV4XBo88OhAahxmdUotwV+AKoAgIjgB4DyiJ9e+R8Qa2O8mHVQnlAeC3wgljdQDXiAInsPU+P3AXJYxnixg5nZx83kVcLfA8yZn+GjY/sep/3NyP6R7AdYBcWD4K9B+aFgPvnPOAi+wTXZ34HYB8UbxUH5HUehG3B5Blg/sn15njoMLSOtBDnATvhI3DM/CB9fB28f2Dy+BDcnBZ/3R6EP+1c6vl8SH+OeUQzIf7avOUJ8f0b+UWOA/G9QAYaYd2f+zbQEBv+Nrvi1loD5byFk/EJUsPnTx/ieis7xueic/o3wwewHVWD76xS3/S8Uz93+SMz/QRLc4pfbnzS+652uNf6r1ivA/F17BWh/YmvJb/cM4Ctel6e/0P+Qc/x0h4LPOSxSv4w3piDAFv2b3UoMyT7pp/yJggA/UP56UAVYX43mn3eI9d9R8I8SQ/Wf6wfG32HsZIdj/QYxEMzwhg2gfS09UWD1g93Ha7LfWv0LTvmfWeTrQb9opmZ8Jrl7zyFEPBe8dfjMSo8ekyE1jKv9nlqA+OK/KiD+lRbfI/HDyD/E9qUFR/tch94+TgC2XxneMv7ja+OfxX4b4zXNx5NuwPlvOMnU8a0yPM6lR8O31tpwOPVu5vjLpZae4rsrGAOA/oehPkWo/rxQ/XnZfALfPo/Xi0T/+hivtbYHofoc4me/Mf9UZub/U/jFv/Pr04MOn0deU1493iX8kPBp/nj7i3j7w3z6R/kEvj9/H61X7VK+5Ob/pbyK2+h3I65Snp/GzX4h/gOrP1gHQEa0VzJ9mdaADVfGZ/Sox+m/GumM03iU+hM+qc8dvgarfzr93f3B7B+H4d/b/iqHU/+Wdex/1cb+T8+x/x7n+8lx9J/7sxnx3/T8M/7iPsZvKP+70X64i/G3b2J+it/8jnxnP9X/c5K2RZLWbAefLb7+SdL++qz++y98Zz8/xPgU567nV3YA69nzbbaV9VreS2jUiLx3oAGayqBZWIdW2r/WP6XgAXRAafw7+Frx85P4ZhP3Xwgxvu1eKq71ExYRXne6P6u/BH+xkaU0opel+559iZFwazqe3KClA+Qsg3hlV0vpHpx/R3oMd5/q/v6ju08W390Ub4ZlYeefSu5wuj9L/0p8VxN3fqH8eUH9j7Zqwe8mUgN2kXqH58sU/2+Aufh/l2vqNH83iJcU+lLnbxX393c/IZ8uD5o/7m+Hk1nuv9LVfwPR/PD9+xTu+3+xjPFtMj8gmS9v/4jt83xw+3v3Jt7/4L/iv1D8dqP/FF8fn+sLnX/zcf8+viTlCYb4C97IRQ6ovuP8kX8+v9vbOP8By8fXB/kn9fMg+7+DqL4ov1J/3eCf1KfgNZ1PNAA+wOAfb0DU0/inDNS/wv+XLLmDJFcYeJ7cWapkBl9exetJiNdfJK07SfSVl4nBhO/lbzWvju4=:3CB4
^FO570,#Coordenada1#^ARR^FD#Alias#^FS
^FO532,#Coordenada2#^ARR^FD#Descripcion#^FS
^FO500,#Coordenada3#^A0R,26,35^FD#NumeroRack#^FS
^FO437,#Coordenada4#^BY2^BCR,65,N,Y,N^FD#NumeroRack#^FS
^FO400,80^A0R,26,35^FD#NumeroSap#^FS
^FO336,18^BY2^BCR,65,N,Y,N^FD#NumeroSap#^FS
^FO65,25^BQN,2,10^FDMM,A#RFID#^FS
^RFW,H,1,2,1^FD3000^FS
^RFW,H,2,8,1^FD#RFID#^FS
^PQ#Copias#,0,1,Y
^XZ
`;
