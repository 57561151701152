import { useContext } from 'react'
import {ConfirmContext} from "../components/shared/Confirm";


const useMessage = () => {
  const { confirmContext, setConfirmContext } = useContext(ConfirmContext)
  return {
    confirm: async (tittle, message) => {
      return new Promise((resolve) => {
        setConfirmContext({
          ...confirmContext,
          open: true,
          title: tittle,
          message: message,
          confirmType:'',
          onConfirmHandler: () => {
            resolve(true)
          }
        })
      })
    },
    confirmDelete: async (tittle, message) => {
      return new Promise((resolve) => {
        setConfirmContext({
          ...confirmContext,
          open: true,
          title: tittle,
          message: message,
          confirmType:'delete',
          onConfirmHandler: () => {
            resolve(true)
          }
        })
      })
    },
    confirmReject: async (tittle, message) => {
      return new Promise((resolve) => {
        setConfirmContext({
          ...confirmContext,
          open: true,
          title: tittle,
          message: message,
          confirmType:'delete',
          onConfirmHandler: () => {
            resolve(true)
          },
          onRejectionHandler:()=>{
            resolve(false)
          }
        })
      })
    },
    alert(messages, severity) {
      if (typeof messages === 'string' || messages instanceof String) {
        messages = [messages]
      }
      setConfirmContext({
        ...confirmContext,
        alert: {
          open: true,
          messages: messages,
          severity: severity
        }
      });
    },
    showLoading: () => {
      setConfirmContext({
        ...confirmContext,
        loading: {
          open: true
        }
      });
    },
    hideLoading: () =>{
      setConfirmContext({
        ...confirmContext,
        loading: {
          open: false
        }
      });
    }
  }
}

export default useMessage
