/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import {UserContext} from "./AuthProvider";

export const ReadOnlyContext = React.createContext({current:false});

const ReadOnlyProvider = ({menuOptionId, readOnly,onReadOnlyChecked,...props}) => {
    const [readOnlyContext, setReadOnlyContext] = useState({current:false});
    const {user} = useContext(UserContext);

    const elements = React.Children.toArray(props.children);
    const body = elements[0];

    useEffect(()=>{
        const menu = user.menu?.find(e=>e.id === menuOptionId);
        const newReadOnly = readOnly?? (menu?.readOnly??true);
        if (onReadOnlyChecked != null)
            onReadOnlyChecked(newReadOnly);
        setReadOnlyContext({...readOnlyContext, current:newReadOnly })

    },[menuOptionId, readOnly])

    return (
        <ReadOnlyContext.Provider value={{readOnlyContext, setReadOnlyContext}}>
            {React.cloneElement(body, {})}
        </ReadOnlyContext.Provider>
    )
}

export default ReadOnlyProvider
