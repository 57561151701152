/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import {VIAJESTATUS_LIST} from "../../../../utility/constants";
import TripReceive from "./TripReceive";

export default function TripGridGuardHouse({viajes, esEnvio, verDetalle}) {
    const {t} = useTranslation();

    const RecibirEnCaseta = ({data, setValue}) => <TripReceive data={data} setValue={setValue} esCaseta/>

    const VerViaje = ({data}) =>
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                {t('Ver')}
            </Button>
        </div>

    return (
        <>
            <Box className="ag-theme-alpine main-grid no-print trip-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            dateFormatter: agGridHelper.DateFormatter,
                            mapArrayFormatter: agGridHelper.MapArrayFormatter,
                            receive: RecibirEnCaseta,
                            viewTrip: VerViaje
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={viajes}
                    localeTextFunc={function (key, defaultValue) {
                        return t(defaultValue);
                    }}
                >
                    <AgGridColumn field="recibidoEnCaseta" headerName="Recepeción de unidad"
                                  cellRenderer="receive" cellClass="center"/>
                    <AgGridColumn field="fechaEstimada" headerName={t('Fecha Estimada')} minWidth={155}
                                  cellRenderer="dateFormatter"/>
                    <AgGridColumn field="fechaReciboEnCaseta" headerName={t('Fecha Recibo')}
                                  cellRenderer="dateFormatter" minWidth={135}/>
                    <AgGridColumn field="fechaReal" headerName={t('Fecha Real')}
                                  cellRenderer="dateFormatter" minWidth={135}/>
                    <AgGridColumn field="numeroEntrega" headerName={t('No Entrega')} minWidth={130}/>
                    <AgGridColumn field="destino" headerName={t('Destino')} hide={!esEnvio} minWidth={300}/>
                    <AgGridColumn field="origen" headerName={t('Origen')} hide={esEnvio} minWidth={300}/>
                    <AgGridColumn field="numeroViaje" headerName={t('No Viaje')} minWidth={130}/>
                    <AgGridColumn field="transportista" headerName={t('Transportista')} minWidth={200}
                                  wrapText={true} autoHeight={true}/>
                    <AgGridColumn field="unidad" headerName={t('Unidad')} minWidth={100}/>
                    <AgGridColumn field="estatus" headerName={t('Estatus')} cellRenderer="mapArrayFormatter"
                                  source={VIAJESTATUS_LIST} minWidth={120}/>
                    <AgGridColumn cellRenderer="viewTrip" width={110} headerClass="center" pinned={'right'}
                                  headerComponent="headerAddButton" cellClass="center"/>
                </AgGridReact>
            </Box>
        </>
    );
}
