/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {Button, ListItem, ListItemButton, ListItemText, Popover, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import useApiTransfer from "../../../api/operation/useApiTransfer";

export default function TransferGridActions({data, verDetalle, verEliminar, copiarRacks, isReadonly}) {
    const {t} = useTranslation();

    return (
        <>
            <div className="ag-grid row-icons">
                <Tooltip title={t('Copiar Lotes')}>
                    <IconButton size="small" onClick={copiarRacks(data.id, true)}>
                        <ContentCopyIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={t('Copiar Racks')}>
                    <IconButton size="small" onClick={copiarRacks(data.id, false)}>
                        <QrCodeIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>

                {!isReadonly &&
                <Tooltip title={t('Eliminar Registro')}>
                    <IconButton color="error" size="small" onClick={() => verEliminar(data.id)}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
                }

                <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                    {t('Ver')}
                </Button>
            </div>
        </>
    )
}

export const TiposRack = ({data}) => {
    const api = useApiTransfer()
    const [tiposRack, setTiposRack] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const getTiposRack = (event) => {
        if (data.totalTiposRack === 0) return;

        api.getTiposRack(data.id).then((result) => {
            setTiposRack(result);
        });

        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box sx={{cursor: data.totalTiposRack === 0 ? '' : 'pointer'}} onClick={getTiposRack}>
                {data.totalTiposRack}
            </Box>

            <Popover open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
                     anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <List dense>
                    {tiposRack.map((tipoRack, index) =>
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <ListItemText primary={tipoRack}/>
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Popover>
        </>
    )
}
