import useServiceFactory from "../useServiceFactory";

const apiUrl = '/operation/viajes';

const useApiViaje = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        getInformacionImpresion: async (id) => service.get(`${apiUrl}/${id}/informacionImpresion`, {showLoading: true}),
        getLotes: async (id) => service.get(`${apiUrl}/${id}/obtenerLotes`, {showLoading: true}),
        getRacks: async (id) => service.get(`${apiUrl}/${id}/obtenerRacks`, {showLoading: true}),
        save: async (params, config) => service.post(`${apiUrl}`, params, config),
        actualizarDatosLogisticos: async (id, params) => service.put(`${apiUrl}/${id}/datosLogisticos`, params),
        recibir: async (params) => service.put(`${apiUrl}/recibir`, params),
        regresaraEnOperacion: async (id) => service.patch(`${apiUrl}/${id}/regresaraEnOperacion`),
        regresaraPorEnviar: async (id) => service.patch(`${apiUrl}/${id}/regresaraPorEnviar`),
        regresaraTransito: async (id, profileId) => service.patch(`${apiUrl}/${id}/regresaraTransito/${profileId}`),
        savePaseSalida: async (id, params) => service.patch(`${apiUrl}/${id}/paseDeSalida`, params),
        eliminar: async (id, params) => service.patch(`${apiUrl}/${id}/eliminar`, params),
        completar: async (id, params) => service.patch(`${apiUrl}/${id}/completar`, params),
        getLog: async (id) => service.get(`${apiUrl}/${id}/log`, {showLoading: true}),
        getAlertas: async (id) => service.patch(`${apiUrl}/${id}/alertas`, null, {showLoading: false}),
        getComentarios: async (id) => service.get(`${apiUrl}/${id}/comentarios`, {showLoading: true}),
        savePedimento: async (id, pedimento) => service.patch(`${apiUrl}/${id}/guardarPedimento/${pedimento}`),
        confirmarCartaPorte: async (id) => service.patch(`${apiUrl}/${id}/confirmarCartaPorte`),
        agregarMateriales: async (id, params) => service.patch(`${apiUrl}/${id}/agregarMateriales`, params),
        guardarComentario: async (id, params) => service.patch(`${apiUrl}/${id}/guardarComentario`, params),
        quitarComentario: async (comentarioId) => service.delete(`${apiUrl}/quitarComentario/${comentarioId}`),
        getInformacionRacks: async (id) => service.get(`${apiUrl}/${id}/informacionRacks`, {showLoading: true}),
        recibirEnCaseta: async (id) => service.patch(`${apiUrl}/${id}/recibirEnCaseta`),
        cancelarReciboCaseta: async (id, params) => service.patch(`${apiUrl}/${id}/cancelarReciboCaseta`, params),
        recibirEnPlanta: async (id) => service.patch(`${apiUrl}/${id}/recibirEnPlanta`),
        cancelarReciboPlanta: async (id, params) => service.patch(`${apiUrl}/${id}/cancelarReciboPlanta`, params),
        completarViajeIncompleto: async (params) => service.put(`${apiUrl}/completar`, params),
        reporte: async (params, nombreReporte) => service.get(`${apiUrl}/reporte`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${nombreReporte}.xlsx`
            })
    }
};

export default useApiViaje;
