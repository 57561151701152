import {createTheme} from "@mui/material/styles";

export default createTheme({

    palette: {
        primary: {
            main: '#2185D0'
        },
        disabled: {
            main: '#BDBDBD'
        }, 
        complete: {
            main: '#4CAF50' 
        }
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '1rem',
                    textTransform: 'none'
                },
            },
            variants: [
                {
                    props: { size: 'mini' },
                    style: {
                        padding: '0 10px',
                    },
                }
            ]
        },
        MuiFormControl:{
            styleOverrides: {
                // Name of the slot
                root: {
                    '& .MuiInputLabel-root': {
                        transform:' translate(14px, 10px) scale(1)'
                    },
                    '& .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiFormLabel-filled': {
                        transform: 'translate(14px, -9px) scale(0.75)'
                    },
                },
            },
        },
        // MuiOutlinedInput : {
        //     styleOverrides: {
        //         // Name of the slot
        //         input: {
        //             // Some CSS
        //             paddingBottom: "0.4rem",
        //             paddingTop: "0.4rem"
        //         },
        //     },
        // },
        MuiCheckbox:{
            styleOverrides: {
                root :{
                    paddingBottom: "2px",
                    paddingTop: "2px"
                }
            }
        },
        // MuiAutocomplete: {
        //     styleOverrides: {
        //         inputRoot :{
        //             paddingBottom: "3px",
        //             paddingTop: "4px"
        //         }
        //     }
        // },

        MuiGrid :{
            variants: [
                {
                    props: { variant: 'main' },
                    style: {
                        marginBottom: '1rem',
                    },
                }
            ],
        },
        MuiFormControlLabel:{
            styleOverrides: {
                root :{
                    '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem'
                    }
                }
            }
        }
    }
});
