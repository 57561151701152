/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import ExHeader from "./exHeader";
import ExCell from "./ExCell";
import "./ExGrid.css"
import {useVirtual} from "react-virtual";
import PropTypes from "prop-types";
import {removeAtIndex, updateAtIndex} from "../../../utility/arrayUtility";
import {IconButton, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ExGrid = ({
                    data,
                    className,
                    style,
                    rowHeight,
                    rowClass,
                    headerClass,
                    onChange,
                    showAddDeleteColumn,
                    defaultNewEntity,
                    name,
                    ...props
                }) => {
    const children = React.Children.toArray(props.children);
    const parentRef = React.useRef();
    const [added, setAdded] = useState(false);
    const {addTitle, buttonsDisabled} = {...props};

    useEffect(() => {
        if (added === true) {
            rowVirtualizer.scrollToIndex(data.length);
            setAdded(false);
        }
    }, [data, added])

    const getGridStyle = () => {
        let template = children.map(e => e.props.width ?? '1fr').join(' ');
        if (showAddDeleteColumn) {
            template += ' 50px';
        }
        return template;
    }

    const rowVirtualizer = useVirtual({
        size: data.length + 1,
        parentRef,
        overscan: 5
    });

    const onCellChanged = (field, value, row, index) => {
        onChange(updateAtIndex(data, index, {...row, [field]: value}), 'change');
    };

    const onAdd = () => {
        onChange([...data, defaultNewEntity ?? {}], 'add');
        // setAdded(true);
    }

    const deleteAtIndex = (index) => {
        const itemRemoved = data[index];
        const dataCopy = removeAtIndex(data, index);
        onChange(dataCopy, 'remove', itemRemoved);
    }

    return (
        <>
            <div className={`ex-grid-container ${className ?? ''}`} style={style} ref={parentRef}>
                <div className={`ex-grid header ${headerClass ?? ''} ${showAddDeleteColumn ? 'with-iconButton' : ''}`}
                     style={{gridTemplateColumns: getGridStyle()}}>
                    {children?.map((item, index) => (
                        <div key={index} className={`cell-header ${item.props.cellHeaderClass ?? ''}`}>
                            <ExHeader {...item.props}/>
                        </div>
                    ))}
                    {showAddDeleteColumn &&
                    <div className={`cell-header`} style={{justifyContent: 'center'}}>
                        <Tooltip title={addTitle}>
                            <span>
                                <IconButton color="primary" onClick={onAdd}
                                            disabled={buttonsDisabled}>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    }
                </div>
                <div style={{
                    height: `${rowVirtualizer.totalSize}px`,
                    width: "100%",
                    position: "relative"
                }}>
                    {rowVirtualizer.virtualItems?.map((virtualRow) => {
                        const rowIndex = virtualRow.index;
                        const row = data[rowIndex];
                        return (
                            <React.Fragment key={`${row?.id ?? 0}_${rowIndex}`}>
                                {row == null ? <></> :
                                    <div className={`ex-grid row ${rowClass ?? ''}`}
                                         style={
                                             {
                                                 position: "absolute",
                                                 top: 0,
                                                 left: 0,
                                                 width: "100%",
                                                 height: `${virtualRow.size}px`,
                                                 transform: `translateY(${virtualRow.start}px)`,
                                                 gridTemplateColumns: getGridStyle()
                                             }
                                         }>
                                        {children?.map((item, index) => (
                                            <div key={index} className={`cell ${item.props?.cellClass ?? ''}`}>
                                                <ExCell {...item.props}
                                                        row={row}
                                                        rowIndex={rowIndex}
                                                        data={data}
                                                        tableName={name}
                                                        onChange={(field, value) => onCellChanged(field, value, row, rowIndex)}
                                                />
                                            </div>
                                        ))}

                                        {showAddDeleteColumn &&
                                        <div className="cell" style={{justifyContent: 'center'}}>
                                            {showAddDeleteColumn && !row.hideDelete &&
                                            <IconButton className="command-button" size="small" color="error"
                                                        onClick={() => deleteAtIndex(rowIndex)}>
                                                <Delete/>
                                            </IconButton>
                                            }
                                        </div>
                                        }
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </>
    )
}


export default ExGrid;

ExGrid.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    rowClass: PropTypes.string,
    headerClass: PropTypes.string,
    rowHeight: PropTypes.number,
    style: PropTypes.object,
    defaultNewEntity: PropTypes.object
};
