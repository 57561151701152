import React from "react";
import {useTranslation} from "react-i18next";

const MapArrayFormatter = ({value, colDef}) => {
    const {t} = useTranslation();

    return (
        <>
            {t(colDef.source.get(value))}
        </>
    );
}

export default MapArrayFormatter;
