/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import usePersistedState from "../../hooks/usePersistedState";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useNotification from "../../hooks/useNotification";
import useApiSecurity from "../../api/security/useApiSecurity";

export const UserContext = React.createContext({});

const unAuthenticatedUser = {
    authenticated: false,
    unauthorized: true,
    token: null,
    menus: [],
    plantas: [],
    sessionExpired: false
};

const AuthProvider = ({...props}) => {
    const elements = React.Children.toArray(props.children);
    const [user, setUser] = usePersistedState('user', unAuthenticatedUser);
    const body = elements[0];
    const history = useHistory();
    const {t} = useTranslation();
    const api = useApiSecurity();
    const notification = useNotification();

    const refreshToken = (token) => {
        if (token === '')
            return;

        const decoded = jwt_decode(token);
        const tokenExpiration = decoded.exp * 1000;
        setUser({
            ...user,
            token: token,
            tokenExpiration,
        })
    }

    const expireSession = () => {
        setUser({
            ...user,
            sessionExpired: true
        });
    }

    const logout = () => {
        setUser(unAuthenticatedUser);
        history?.push('/login');
    }

    const login = async (user) => {
        const result = await api.validate(user);

        if (result.success === false) {
            notification.error(t('Usuario o password inválidos'));
            return false;
        }

        const decoded = jwt_decode(result.token);
        setUser({
            ...result,
            tokenExpiration: decoded.exp * 1000,
            authenticated: true,
            sessionExpired: false
        });
        return true;
    };




    return (
        <UserContext.Provider value={{user, setUser, refreshToken, expireSession, logout, login}}>
            {React.cloneElement(body, {})}
        </UserContext.Provider>
    )
}

export default AuthProvider
