import * as yup from "yup";

const profileValidationSchema = yup.object().shape({
    name: yup.string().trim().required()
});

export default profileValidationSchema



