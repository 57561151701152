/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import Routes from "./navigation/routes";
import {Confirm} from "./shared/Confirm";
import {UserContext} from "./security/AuthProvider";
import MainMenu from "./navigation/MainMenu";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import RefreshPassword from "./security/RefreshPassword";
import isTablet from 'react-device-detect';
import useApiSecurity from "../api/security/useApiSecurity";

export const AppContext = React.createContext({})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Main = () => {
    const api = useApiSecurity();

    const language = localStorage.getItem('languageCode') ?? "es";
    const {user, setUser} = useContext(UserContext);
    const history = useHistory();
    const [appContext, setAppContext] = useState({showTaskBar: true});
    const [formChanged, setFormChanged] = useState(false);
    const [appLanguage, setAppLanguage] = useState(language);

    useEffect(()=>{
        updateUser().then();
    },[]);

    const listener = (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    }

    const onFormChanged = () => {
        if (!formChanged) {
            window.onbeforeunload = listener;
        }
        setFormChanged(true);
    }

    const clearFormChanged = () => {
        setFormChanged(false);
        window.onbeforeunload = null;
    }

    const goTo = (path) => {
        if (formChanged) {
            const leave = window.confirm('Are you sure you want to close?');
            if (!leave) {
                return;
            }
        }
        clearFormChanged();
        history.push(path);
    }

    const historyBack = () => {
        if (formChanged) {
            const leave = window.confirm('Are you sure you want to close?');
            if (!leave) {
                return;
            }
        }
        history.goBack();
    }

    const updateUser = async ()=>{
        if (user.authenticated && !user.sessionExpired){
            const newUser = await api.getUser();
            setUser({...user, ...newUser});
        }
    }

    return (
        <AppContext.Provider
            value={{
                appContext,
                setAppContext,
                formChanged,
                user,
                onFormChanged,
                clearFormChanged,
                historyBack,
                goTo,
                appLanguage,
                setAppLanguage
            }}>
            <>
                <Box className={`main-box ${isTablet ? 'is-tablet' : ''}`}
                     sx={{display: 'flex', backgroundColor: '#f5f5f5'}}>
                    <MainMenu/>

                    <Box component="main" sx={{
                        flexGrow: 1,
                        pl: 2,
                        pr: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        overflow: 'auto'
                    }}
                         className="main-box"
                    >
                        {/*Crea un espacio para que el contenido quede abajo del AppBar(Dentro de MainMenu)*/}
                        <DrawerHeader className="no-print"/>

                        <Routes/>
                    </Box>
                </Box>
                <Confirm/>
                <RefreshPassword/>
            </>
        </AppContext.Provider>
    )
}

export default Main;
