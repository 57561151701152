/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import useApiViaje from "../../../../api/operation/useApiViaje";
import {VIAJESTATUS_LIST, MENUS_IDS, PERFILES_IDS} from "../../../../utility/constants";
import {AppContext} from "../../../Main";
import TripGridActions, {CeldaEstatusViaje} from "./TripGridActions";
import {TripContext} from "../Trip";
import TripRackReport from "../TripRackReport";
import groupBy from "lodash/groupBy";
import TripReceive from "./TripReceive";

export default function TripGrid({viajes, esEnvio, verDetalle, verEliminar}) {
    const {user} = useContext(AppContext);

    const {t} = useTranslation();
    const api = useApiViaje();
    const isReadonly = user.menu?.find(e => e.id === MENUS_IDS.trip)?.readOnly ?? true;
    const esPerfilSeguridad = user.profileId === PERFILES_IDS.seguridad;
    const esAdministrador = [PERFILES_IDS.administrador, PERFILES_IDS.edicionDatosMaestros].includes(user.profileId);

    const [reporteRacksAbierto, setReporteRacksAbierto] = useState(false);
    const [viajeSeleccionado, setViajeSeleccionado] = useState({});

    const copiarRacks = (id, esLote) => async () => {
        let result;
        let texto = "";

        if (esLote) {
            result = await api.getLotes(id);
        } else {
            result = await api.getRacks(id);
        }

        result.forEach(elemento => {
            const match = /[a-zA-Z]/.exec(elemento);
            elemento = esLote && match && match.index <= 2 ? elemento.substring(match.index + 1) : elemento;
            texto += elemento + "\n";
        });

        navigator.clipboard.writeText(texto);
    }

    const getReporteRacks = async (viaje) => {
        const result = await api.getInformacionRacks(viaje.id);

        const tiposRack = [];
        const tiposRackIds = groupBy(result.racks, "tipoRackId");

        for (const tipoRackId in tiposRackIds) {
            const primerRack = tiposRackIds[tipoRackId][0];
            const racksAgrupados = tiposRackIds[tipoRackId];
            const cantidadRecibida = racksAgrupados.reduce((sum, e) => sum + e.cantidadRecibida, 0);
            const id = Number(tipoRackId);

            tiposRack.push({id, numeroSap: primerRack.numeroSap, name: primerRack.tipoRack, cantidadRecibida})
        }

        setReporteRacksAbierto(true);
        setViajeSeleccionado({...viaje, tiposRack})
    }

    const HeaderAddButton = () => {
        return isReadonly ? <></>
            :
            <div className="ag-grid row-icons">
                <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => verDetalle(0)}>
                    {t('Agregar')}
                </Button>
            </div>
    }

    const Actions = ({data, setValue}) => {
        const {filter, section} = useContext(TripContext);

        return (
            <TripGridActions data={data}
                             section={section}
                             setValue={setValue}
                             isReadonly={isReadonly}
                             filter={filter}
                             copiarRacks={copiarRacks}
                             getReporteRacks={getReporteRacks}
                             verDetalle={verDetalle}
                             verEliminar={verEliminar}
                             esPerfilSeguridad={esPerfilSeguridad}
            />
        )
    }

    const estilosEstatus = ({data}) => data.estiloEstatus;

    return (
        <>
            <Box className="ag-theme-alpine main-grid no-print trip-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            dateFormatter: agGridHelper.DateFormatter,
                            mapArrayFormatter: agGridHelper.MapArrayFormatter,
                            headerAddButton: HeaderAddButton,
                            actions: Actions,
                            celdaEstatusViaje: CeldaEstatusViaje,
                            receive: TripReceive
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={viajes}
                    localeTextFunc={function (key, defaultValue) {
                        return t(defaultValue);
                    }}
                >
                    <AgGridColumn field="recibidoEnPlanta" headerName={t('Recepción de unidad')}
                                  cellRenderer="receive" cellClass="center" hide={!esAdministrador}/>
                    <AgGridColumn field="avance" headerName={t('Estatus de Viaje')} cellClass="full-width"
                                  cellRenderer="celdaEstatusViaje" minWidth={150}/>
                    <AgGridColumn field="fechaEstimada" headerName={t('Fecha Estimada')}
                                  cellRenderer="dateFormatter" minWidth={135}/>
                    <AgGridColumn field="fechaReal" headerName={t('Fecha Real')} cellRenderer="dateFormatter"
                                  minWidth={135}/>
                    <AgGridColumn field="turnoSalida" headerName={t('Turno')} minWidth={70} hide={!esEnvio}
                                  cellClass="center"/>
                    <AgGridColumn field="numeroEntrega" headerName={t('No Entrega')} minWidth={130}/>
                    <AgGridColumn field="destino" headerName={t('Destino')} hide={!esEnvio} minWidth={300}/>
                    <AgGridColumn field="origen" headerName={t('Origen')} hide={esEnvio} minWidth={300}/>
                    <AgGridColumn field="numeroViaje" headerName={t('No Viaje')} minWidth={130}/>
                    <AgGridColumn field="transportista" headerName={t('Transportista')} minWidth={200}
                                  wrapText={true} autoHeight={true}/>
                    <AgGridColumn field="unidad" headerName={t('Unidad')} minWidth={100}/>
                    <AgGridColumn field="estatus" headerName={t('Estatus')} cellRenderer="mapArrayFormatter"
                                  source={VIAJESTATUS_LIST} minWidth={120} cellClass={estilosEstatus}/>
                    <AgGridColumn cellRenderer="actions" width={205} headerClass="center" pinned={'right'}
                                  headerComponent="headerAddButton" field="alertasSinVer"/>
                </AgGridReact>
            </Box>

            {reporteRacksAbierto &&
            <TripRackReport viaje={viajeSeleccionado} open={reporteRacksAbierto}
                            close={() => setReporteRacksAbierto(false)}/>
            }
        </>
    );
}
