/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import useApiInventario from "../../../api/operation/useApiInventario";
import {formatDate} from "../../../utility/dateUtility";
import {RACK_TIPO_MOVIMIENTO_LIST} from "../../../utility/constants";
import {Download} from "@mui/icons-material";
import useMessage from "../../../hooks/useMessage";

export default function InventoryDetail({rackId, open, close}) {
    const api = useApiInventario();
    const {t} = useTranslation();
    const message = useMessage();

    const [rack, setRack] = useState({historial: []});

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            setRack(result);
        }

        if (rackId > 0)
            load(rackId).then();
    }, [rackId]);

    const descargarReporte = async () => {
        await message.confirm(t("Descargar reporte"), t("¿Desea Continuar?"));

        const titulosCeldas = {
            A1: t("Datos Generales"),
            A2: t("Fecha de primer registro"),
            A3: t("Fecha de último registro"),
            A4: t("Cantidad de registros de rack"),
            A5: t("Días promedio por ubicación"),
            A6: t("Cantidad de movimientos por viajes"),
            A7: t("Cantidad de movimientos por traspasos"),
            A8: t("Cantidad de movimientos por inventario"),
            A10: t("Resumen de registros"),
            A11: t("Día de registro"),
            B11: t("Ubicación actual"),
            C11: t("Días en ubicación"),
            D11: t("Tipo de movimiento"),
            E11: t("Origen")
        }

        const tiposMovimiento = {
            51: t("Viaje"),
            52: t("Traspaso"),
            53: t("Conteo"),
        };

        const nombreReporte = `${t("Seguimiento")}_${rack.name}`;

        await api.reporteHistorialRack(rackId, {titulosCeldas, tiposMovimiento}, nombreReporte);
    }

    return (
        <>
            <SidebarRight title={t("Seguimiento")} open={open} close={close} size={1000}>
                <table className="table-detail">
                    <thead>
                    <tr>
                        <th style={{width: '12%'}}>{t("Fecha de llegada")}</th>
                        <th style={{width: '35%'}}>{t("Ubicación")}</th>
                        <th style={{width: '6%'}}>{t("Días")}</th>
                        <th style={{width: '12%'}}>{t("Tipo de movimiento")}</th>
                        <th style={{width: '35%'}}>{t("Origen")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rack.historial.map(row => (
                        <tr key={row.id}>
                            <td>{formatDate(row.fechaLlegada)}</td>
                            <td>{row.ubicacion}</td>
                            <td>{row.diasEnUbicacion}</td>
                            <td>{t(RACK_TIPO_MOVIMIENTO_LIST.get(row.tipoMovimiento))}</td>
                            <td>{row.ubicacionAnterior}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        {rack.historial.length > 0 &&
                        <Button variant="contained" color="success" size="small" startIcon={<Download/>}
                                onClick={descargarReporte}>
                            {t('Reporte')}
                        </Button>
                        }
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
