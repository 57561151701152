/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MainMenuOption from "./MainMenuOption";
import {useContext, useEffect, useState} from "react";
import {
    Home,
    HomeOutlined,
    InventoryOutlined,
    LocalShipping,
    Logout,
    Settings,
    SettingsOutlined
} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {UserContext} from "../security/AuthProvider";
import Box from "@mui/material/Box";
import FieldDropDown from "../shared/fields/FieldDropDown";
import {LANGUAGES} from "../../utility/constants";
import usePersistedState from "../../hooks/usePersistedState";
import {useTranslation} from "react-i18next";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {AppContext} from "../Main";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const drawerWidth = 245;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6.7)} + 1px) !important`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export default function MainMenu() {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();
    const {user, logout} = useContext(UserContext);
    const [menus, setMenus] = useState([]);
    const {setAppLanguage} = useContext(AppContext);
    const allMenus = [
        {
            id: 1,
            title: 'Viaje',
            url: '/trip',
            icon: <LocalShippingOutlinedIcon/>,
            activeIcon: <LocalShipping color="primary"/>,
            children: []
        },
        {
            id: 2,
            title: 'Configuración',
            icon: <SettingsOutlined/>,
            activeIcon: <Settings color="primary"/>,
            children: [
                {id: 3, parentId: 2, title: 'Administración de Racks', url: '/racksManagementMain/0'},
                {id: 4, parentId: 2, title: 'Plantas', url: '/plant'},
                {id: 5, parentId: 2, title: 'Administración de Usuarios', url: '/user'},
                {id: 8, parentId: 2, title: 'Materiales', url: '/material'},
                {id: 9, parentId: 2, title: 'Información General', url: '/informacionGeneral'},
                {id: 10, parentId: 2, title: 'Perfiles', url: '/perfiles'},
                {id: 11, parentId: 2, title: 'Transportistas', url: '/transportistas'}
            ]
        },
        {
            id: 6,
            title: 'Inventario',
            url: '/mainInventory/0',
            icon: <InventoryOutlined/>,
            activeIcon: <InventoryOutlined color="primary"/>,
            children: []
        },
    ];

    const [language, setLanguage] = usePersistedState('selected-language', {});

    const {i18n, t} = useTranslation();

    // Esta es la fecha de actualización, cambiarla por la siguiente que se requiera
    const countDownDate = new Date(2022, 4, 15, 18, 0, 0).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    const getReturnValues = () => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return `${days} ${t("días")}: ${hours} ${t("horas")}: ${minutes} ${t("minutos")}: ${seconds} ${t("segundos")}`;
    };

    useEffect(() => {
        if (language.code == null) {
            const languageCodeParams = (i18n.language ?? 'en').split('-');
            let languageCode = 'es';
            if (languageCodeParams.length >= 1) {
                languageCode = languageCodeParams[0];
            }
            const defaultLanguage = LANGUAGES.find(e => e.code === languageCode) ?? {id: 1, code: 'es'};
            localStorage.setItem('languageCode', 'es');
            setLanguage(defaultLanguage);
            setAppLanguage(defaultLanguage.code);
        }

    }, [i18n.language, language.code, setLanguage])

    useEffect(() => {
        if (user?.menus?.length) {
            const menu = [{
                id: 0,
                title: 'Inicio',
                url: '/default',
                icon: <HomeOutlined/>,
                active: false,
                activeIcon: <Home color="primary"/>,
                children: []
            },];

            allMenus.forEach(e => {
                if (user.menus.includes(e.id)) {
                    const children = e.children.filter(c => user.menus.includes(c.id));

                    menu.push({...e, children});
                }
            });

            setMenus(menu);
        }

    }, [user?.menus])

    const handleDrawerOpen = () => {
        setExpanded(true);
    };

    const handleDrawerClose = () => {
        setExpanded(false);
    };

    const onLogout = () => {
        logout();
        history.push('/login');
    }

    const onLanguageChange = (n, value) => {
        localStorage.setItem('languageCode', value.code);
        setLanguage(value);
        i18n.changeLanguage(value.code);
        setAppLanguage(value.code);
    }

    return (
        <div className="no-print">
            <CssBaseline/>
            <AppBar position="fixed" open={expanded} sx={{backgroundColor: 'white'}}>
                <Toolbar variant="dense" style={{paddingLeft: '15px'}}>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            pl: 1.5,
                            marginRight: expanded ? '0' : '20px',
                            ...(expanded && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {/*QUITAR EL ESTILO flexGrow: 1 del Box CUANDO SE QUIERA DEJAR MENSAJE DE ACTUALIZACIÓN
                    REGRESARLO CUANDO YA NO HAYA MENSAJE DE ACTUALIZACIÓN*/}
                    <Box sx={{display: 'flex', flexGrow: 1, position: 'relative'}}>
                        <img src="images/logo.png" alt="" style={{height: '43px', cursor: 'pointer'}}
                             onClick={() => history.push('/default')}/>
                        <span style={{
                            color: '#105776',
                            fontSize: '1.8rem',
                            position: 'absolute',
                            left: '180px',
                            bottom: '-10px'
                        }}>{process.env.REACT_APP_ENVIRONMENT_LABEL}</span>
                    </Box>

                    <Box sx={{display: "none", color: 'black', flexGrow: 1, justifyContent: 'center'}}>
                        <Box color="#ed6c02" fontWeight={700}>
                            {t("TRack tendrá su siguiente actualización el próximo 15-05-22")}.
                        </Box>
                        <BootstrapTooltip title={t("DetalleActualizacion")}>
                            <InfoOutlinedIcon color="warning"/>
                        </BootstrapTooltip>
                        <Box ml={2}>{getReturnValues()}</Box>
                    </Box>

                    <Box style={{width: '150px', paddingRight: '20px'}}>
                        <FieldDropDown options={LANGUAGES} translate={true} value={language} onChange={onLanguageChange}
                                       disableClearable={true}/>
                    </Box>
                    <Box>
                        <span style={{paddingRight: '15px', color: 'black'}}>{user?.fullName}</span>
                        <IconButton onClick={onLogout} color="primary">
                            <Logout/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {/*Barra de menus lateral*/}
            <Drawer variant="permanent" open={expanded}>
                <DrawerHeader style={{minHeight: '48px'}}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>

                <List>
                    {menus.map(menu =>
                        <MainMenuOption key={menu.id} expanded={expanded} menu={menu}/>
                    )}
                </List>
            </Drawer>
        </div>
    );
}
