import React, {useContext, useEffect, useState} from 'react'
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {useTranslation} from "react-i18next";
import {ValidationContext} from "../validationContext";

const FieldTextBox = ({xs, sm, md, lg, xl, mt, label, name, onChange, value, disabled, ignoreAllDisabled}) => {
    const {t} = useTranslation();

    const {readOnlyContext} = useContext(ReadOnlyContext);
    const [readOnly, setReadOnly] = useState(false);
    const {allDisabled} = useContext(ValidationContext);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);

    function onValueChange(event, checked) {
        if (readOnly || (!ignoreAllDisabled && allDisabled))
            return;

        if (onChange != null)
            onChange(name, checked);
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}
                  alignSelf="center" mt={mt || 0}>
                <FormControlLabel control={<Checkbox checked={value ?? false} onChange={onValueChange}/>}
                                  label={t(label)}/>
            </Grid>
        </>
    )
}
export default FieldTextBox;
