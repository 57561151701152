/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {  useState} from "react";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";

import useApiRackManagementPurchase from "../../../api/settings/useApiRackManagmentPurchase";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import Filter from "../../shared/Filter";
import FieldRangeDatePicker from "../../shared/fields/FieldDateRangePicker";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import RackManagementPurchaseDetail from "./RackManagementPurchaseDetail";

export default function RackManagmentPurchase()
{
    const {t} = useTranslation();
    const api = useApiRackManagementPurchase()
    const filtroDefault = {tipoRacksIds: [], activoIds: []};
    const [grid, setGrid] = useState({data: []});
    const [compraId, setCompraId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);

    const buscar = async (filtro) => {
        const nuevoFiltro = {...filtro, tipoRacksIds: filtro.tipoRacksIds.join()};
        const result = await api.filter(nuevoFiltro);
        setGrid({data: result});
    };

    const viewDetail = (id) => {
        setTemporalId(Math.random())
        setCompraId(id);
        setAbrirDetalle(true)
    };

    const HeaderAddButton = ({data}) => (
        <div className="ag-grid row-icons">

            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => viewDetail(0)}>
                {t('Agregar')}
            </Button>

        </div>
    );

    const ViewDetailButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => viewDetail(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    );

    const onSave = async () => {
        setAbrirDetalle(false);

        await buscar();
    };

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    return (
        <>
            <Filter titulo={t('Compras')} valorDefault={filtroDefault} buscar={buscar}>
                <FormaFiltros/>
            </Filter>

            <Box className="ag-theme-alpine main-grid " >
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            headerAddButton: HeaderAddButton,
                            estatusValueCell: EstatusValueCell,
                            viewDetailButton: ViewDetailButton,
                            dateFormatter: agGridHelper.DateFormatter,
                            currencyFormatter: agGridHelper.currencyFormatter
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={grid.data}
                >
                    <AgGridColumn field="ordenCompra" headerName={t('OC')}/>
                    <AgGridColumn field="nombreProveedor" headerName={t('Proveedor')}/>
                    <AgGridColumn field="tipoRack" headerName={t('Tipo de Rack')} />
                    <AgGridColumn field="cantidadString" headerName={t('Cantidad')}  />
                    <AgGridColumn field="fechaCompra" headerName={t('Fecha de Compra')} cellRenderer="dateFormatter"/>
                    <AgGridColumn field="precio" headerName={t('Precio')} cellRenderer="currencyFormatter" cellClass="right"/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>
            <RackManagementPurchaseDetail
                open={abrirDetalle}
                temporalId={temporalId}
                close={() => setAbrirDetalle(false)}
                onSave={onSave}
                compraId={compraId}
                />
        </>);
}

const FormaFiltros = ({filtro, onChange}) => {
    const {t} = useTranslation();
    const estatusActivo = [{id: 1, name: t("Activo")}, {id: 0, name: t("Inactivo")}];

    return (
        <>
            <FieldDropDownMultiselect label={t('Tipo de Rack')} name="tipoRacksIds" source="tiposRack"
                                      value={filtro.tipoRacksIds} onChange={onChange} sm={6} md={3} valueById/>

            <FieldDropDownMultiselect label={t('Proveedor')} name="proveedorIds" source="proveedores" sm={6} md={3}
                                      value={filtro.proveedorIds} onChange={onChange} valueById/>

            <FieldRangeDatePicker label={t('Fecha de Compra')} sm={6} md={3} name="fecha"
                                  value={filtro.fecha} onChange={onChange}/>


            <FieldAutoComplete label={t('OC')} name={`ordenCompraId`} value={filtro.ordenCompraId} sm={6} md={3}
                               source="ordenesCompra" valueById onChange={onChange}/>

            <FieldDropDownMultiselect label={t('Estatus')} name="estatusIds" options={estatusActivo} sm={6} md={3}
                                      value={filtro.estatusIds} onChange={onChange} valueById
                                      showSelectButtons={false}/>
        </>
    )
};
