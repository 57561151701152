/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import {formatDate} from "../../../../utility/dateUtility";
import {VIAJE_TIPO as tipoViaje} from "../../../../utility/constants";

const printBoxStyle = {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 1,
    minHeight: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1px',
    paddingBottom: '1px'
};


export default function ExitPassPrint({viaje}) {
    const {t} = useTranslation();

    const getDescripicionMercancia = () => {
        const descripcionTipo = viaje.tipo === tipoViaje.cargado ? t("cargados") : t("vacios");
        return `${viaje.numeroEntrega} - ${viaje.numeroViaje} - ${viaje.destino} - ${viaje.totalRacks} racks ${descripcionTipo}`
    }

    return (
        <div>
            {[1, 2, 3].map(e =>
                <Grid container columnSpacing={2} rowSpacing={1} columns={24} alignItems="center"
                      sx={{fontSize: 12, mb: 1, lineHeight: 1}} key={e}>
                    <Grid item xs={15}>
                        <Box display="flex" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Typography fontWeight={700} sx={{lineHeight: 1.1}}>
                                    Orden de salida de transportista desde / Carrier exit order
                                </Typography>
                                <Typography sx={{lineHeight: 1.1, textTransform: 'uppercase'}}
                                            fontWeight={700} align="center">
                                    {viaje.origen}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={1}>
                        Folio
                    </Grid>

                    <Grid item xs={2.5}>
                        <Box sx={printBoxStyle} justifyContent="center">{viaje.id}</Box>
                    </Grid>

                    <Grid item xs={1.5} display="flex" flexDirection="column">
                        <span>Fecha/</span>
                        <span>Date</span>
                    </Grid>

                    <Grid item xs={4}>
                        <Box sx={printBoxStyle} justifyContent="center">
                            {formatDate(viaje.fechaRealEnvio, true)}
                        </Box>
                    </Grid>

                    <Grid item xs={10} display="flex" flexDirection="column">
                        <span>Favor de permitirle la salida al transporte número /</span>
                        <span>Please allow exit to the truck with number</span>
                    </Grid>

                    <Grid item xs={5}>
                        <Box sx={printBoxStyle} pl={1}>{viaje.numeroTransporte}</Box>
                    </Grid>

                    <Grid item xs={5} display="flex" flexDirection="column">
                        <span>Número de caja /</span>
                        <span>Trailer Number</span>
                    </Grid>

                    <Grid item xs={4}>
                        <Box sx={printBoxStyle} pl={1}>{viaje.unidad}</Box>
                    </Grid>

                    <Grid item xs={5} display="flex" flexDirection="column">
                        <span>Línea de transporte /</span>
                        <span>Carrier</span>
                    </Grid>

                    <Grid item xs={10}>
                        <Box sx={printBoxStyle} pl={1}>{viaje.transportista?.name}</Box>
                    </Grid>

                    <Grid item xs={5} display="flex" flexDirection="column">
                        <span>Placas / Plate Number</span>
                    </Grid>

                    <Grid item xs={4}>
                        <Box sx={printBoxStyle} pl={1}>{viaje.placasTransporte}</Box>
                    </Grid>

                    <Grid item xs={5} display="flex" flexDirection="column">
                        <span>Nombre de operador /</span>
                        <span>Truck operator</span>
                    </Grid>

                    <Grid item xs={19}>
                        <Box sx={printBoxStyle} pl={1}>{viaje.nombreOperador}</Box>
                    </Grid>

                    <Grid item xs={5} display="flex" flexDirection="column">
                        <Box sx={{whiteSpace: 'nowrap'}}>Descripción de mercancía /</Box>
                        <span>Product information</span>
                    </Grid>

                    <Grid item xs={19}>
                        <Box sx={printBoxStyle} pl={1}>
                            {getDescripicionMercancia()}
                        </Box>
                    </Grid>

                    <Grid container item xs>
                        <Grid container item xs={16} flexDirection="column">
                            <Grid item xs display="flex">
                                <span style={{paddingTop: '15px'}}>Comentarios / Additional Information</span>
                            </Grid>

                            <Grid item xs>
                                <Box sx={printBoxStyle} pl={1} style={{height: '70px'}}>{viaje.comentarios}</Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={1}/>

                        <Grid container item xs={7} flexDirection="column">
                            <Grid item xs textAlign="center">
                                Horario de Salida / Exit time
                            </Grid>

                            <Grid item xs mt={3} mb={1}>
                                <div style={{borderBottom: '2px solid rgba(0, 0, 0, 0.12)'}}/>
                            </Grid>

                            <Grid item xs display="flex" flexDirection="column" textAlign="center">
                                <span>Firma de autorización de salida /</span>
                                <span>Exit authorization signature</span>
                            </Grid>

                            <Grid item xs mt={4}>
                                <div style={{borderBottom: '2px solid rgba(0, 0, 0, 0.12)'}}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {e !== 3 &&
                    <Grid item xs={24} my={1}>
                        <div style={{borderBottom: '1px dashed rgba(0, 0, 0, 0.4)'}}/>
                    </Grid>
                    }
                </Grid>
            )}
        </div>
    );
}
