import axios from 'axios'
import swal from 'sweetalert2'
import * as qs from 'qs'
import FileDownload from 'js-file-download';
import {useTranslation} from 'react-i18next'
import useMessage from "../hooks/useMessage";
import {ALERT_SEVERITY} from "../utility/constants";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../components/security/AuthProvider";
import useNotification from "../hooks/useNotification";


// import { authProvider } from '../authProvider'
//the service factory by default will show loading on post and put, but gets
//to change this default configuration, can set config.showLoading: true
//get example:   async (params) => service.get(`/apiUrl`, {params, showLoading:true}),
//post example:  async (params) => service.post(`apiUrl`, params, {showLoading:false}),
function useServiceFactory(options = {}) {
    const message = useMessage()
    const notification = useNotification();
    const headers = options.headers || {}
    const {t} = useTranslation()
    const {refreshToken, expireSession} = useContext(UserContext);
    const [enabledRefreshToken, setEnabledRefreshToken] = useState(false);

    useEffect(()=>{
        setEnabledRefreshToken(true);
    },[])

    const baseUrl = (options && options.uri) || process.env.REACT_APP_API_URI_V1
    const isMockServer = baseUrl == null || baseUrl.includes('http://localhost:3001')

    const service = axios.create({
        baseURL: baseUrl,
        timeout: (options && options.timeout) || 1000000,
        headers
    })

    const showLoading = (config) => {
        if ((config.showLoading ?? config.method !== 'get') !== true) return
        message.showLoading()
    }

    const showSuccess = (config) => {
        const defaultShow = config.method !== 'get' && typeof config.data === 'string'
        if ((config.showSuccess ?? defaultShow) !== true) return;
        notification.success(t('La operación fue exitosa'));
    }

    const hideLoading = (config) => {
        if (config != null && (config.showLoading ?? config.method !== 'get') !== true) return
        message.hideLoading()
    }

    const getLastToken = () => {
        const storage = localStorage.getItem('user');
        if (storage == null)
            return '';

        return JSON.parse(storage).token
    }

    service.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            config.paramsSerializer = (params) => {
                return qs.stringify(params)
            }
            showLoading(config)

            const token = getLastToken();
            config.headers.authorization = 'Bearer ' + token

            if (config.download === true) {
                config.responseType = 'blob'
            }

            return config
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error)
        }
    )

    service.interceptors.response.use(
        (res) => {
            hideLoading(res.config)

            if (res.config.download === true) {
                FileDownload(res.data, res.config.fileName);
                return null;
            }

            if (refreshToken != null && enabledRefreshToken)
                refreshToken(res.data.refreshToken);

            showSuccess(res.config)

            if (res.data.timeGenerated != null) return res.data.result
            return res.data
        },
        async (error) => {
            hideLoading(error.response?.config)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        if (error.response.data.errorCode != null) {
                            const componets = error.response.data.errorCode.split('|')
                            let messageText = t('Error del Servidor.' + componets[0])
                            for (let i = 1; i < componets.length; i++) {
                                messageText = messageText.replaceAll('${' + (i - 1) + '}', componets[i])
                            }
                            message.alert(messageText, ALERT_SEVERITY.error)
                        } else {
                            const componets = error.response.data.errorMessage.split('|')
                            let messageText = t(componets[0])

                            for (let i = 1; i < componets.length; i++) {
                                messageText = messageText.replaceAll('${' + (i - 1) + '}', t(componets[i]))
                            }

                            await swal.fire({
                                titleText: t('Atención!'),
                                text: messageText,
                                type: 'warning',
                            })
                        }
                        return Promise.reject(error)
                    case 401:
                        if (expireSession != null)
                            expireSession();
                        return Promise.reject(error)
                    case 403:
                        await swal.fire({
                            titleText: error.response.data != null ? error.response.data : t('Sesión Expirada'),
                            text: error.response.data != null ? '' : t('No esta autorizado para realizar esta acción.'),
                            type: 'warning',
                        })
                        return Promise.reject(error)
                    case 404:
                    case 422:
                    case 500:
                    case 502:
                    default:
                        if (isMockServer) return
                        await swal.fire({
                            titleText: t('Error inesperado. Si los problemas continuan, contacte a soporte.'),
                            text: error.response.data.message,
                            type: 'warning',
                        })
                        return Promise.reject(error)
                }
            }
            await swal.fire({
                titleText: t('Comprobar la conexión'),
                text: 'No se ha podido establecer conexión con el servidor.',
                type: 'warning',
            });

            return Promise.reject(error)
        }
    )

    return service
}

export default useServiceFactory
