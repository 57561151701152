/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import {Card, CardActionArea, CardContent, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {AppContext} from "./Main";
import {PERFILES_IDS} from "../utility/constants";
import {
    Inventory2Outlined,
    LocalShippingOutlined,
    MoveDown,
    PlaylistAddCheck,
    Security,
    Warehouse
} from "@mui/icons-material";

const allMenus = [
    {
        id: 1,
        title: 'Operaciones',
        children: [
            {id: 1, menuId: 1, title: 'Viajes', icon: <LocalShippingOutlined/>, url: 'trip'},
            {id: 2, menuId: 6, title: 'Transferencias', icon: <MoveDown/>, url: 'mainInventory/1'},
            {id: 3, menuId: 6, title: 'Inventario', icon: <Inventory2Outlined/>, url: 'mainInventory/0'},
            {id: 4, menuId: 6, title: 'Conteo de Inventario', icon: <PlaylistAddCheck/>, url: 'mainInventory/2'}
        ]
    }
];

const Home = () => {
    const {user} = useContext(AppContext);
    const esPerfilSeguridad = user.profileId === PERFILES_IDS.seguridad;
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        if (user?.menus?.length) {
            const menu = [];

            allMenus.forEach(e => {
                const children = e.children.filter(c => user.menus.includes(c.menuId));

                if (children.length > 0) {
                    menu.push({...e, children});
                }
            });

            setMenus(menu);
        }

    }, [user?.menus])

    return (
        <div className="home">
            <Box textAlign="center" mt={3}>
                <img src="images/logoLandingPage.png" alt="" style={{width: '25%'}}/>
            </Box>

            {esPerfilSeguridad ?
                <Grid spacing={3} columns={24} container alignItems="center" justifyContent="center" my={1}>
                    <Grid item xs={3}>
                        <MenuCard nombre="Seguridad" icono={<Security/>}/>
                    </Grid>
                    <Grid item xs={1}>
                        <img src="images/arrow-green.png" alt="" style={{width: '30px'}}/>
                    </Grid>

                    {user.plantas.map(planta =>
                        <Grid key={planta.id} item xs={5}>
                            <MenuCard nombre={planta.name} icono={<Warehouse/>}
                                      link={`trip/${planta.id}`}/>
                        </Grid>
                    )}
                </Grid>
                :
                <>
                    {menus.map(e =>
                        <Grid key={e.id} spacing={3} container mt={3} alignItems="center" justifyContent="center">
                            {e.children.map(menu =>
                                <Grid key={menu.id} item xs={2}>
                                    <MenuCard key={menu.id} nombre={menu.title} icono={menu.icon} link={menu.url}/>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </>
            }
        </div>
    )
}

const MenuCard = ({nombre, icono, link}) => {
    const history = useHistory();
    const {t} = useTranslation();

    const goToPage = () => {
        if (!!link) {
            history.push("/" + link);
        }
    }

    return (
        <Card sx={{maxWidth: 345, minWidth: 140, height: 147}}>
            <CardActionArea onClick={goToPage} sx={{height: '100%'}}>
                <CardContent sx={{height: '100%'}}>
                    <Box display="flex" alignItems="center" justifyContent="center" textAlign="center"
                         sx={{height: '60%', fontSize: '1.25rem', fontWeight: 500, lineHeight: 1.6}}>
                        {t(nombre)}
                    </Box>
                    <Box textAlign="center">
                        {icono}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Home;
