import React from "react";
import {useTranslation} from "react-i18next";

const BooleanFormatter = ({value}) => {
    const {t} = useTranslation();

    return(
        <>
            {value != null ? (value ? t('Yes'):t('No')) : ''}
        </>
    )
};

export default BooleanFormatter;
