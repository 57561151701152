/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import useApiTransportista from "../../../api/catalogs/useApiTransportista";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import carrierValidationSchema from "./carrierValidation";


export default function CarrierDetail({id, close, onSave}) {
    const api = useApiTransportista();
    const {t} = useTranslation();
    const open = id != null;

    const [titulo, setTitulo] = useState('');
    const [transportista, setTransportista] = useState({});
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(carrierValidationSchema, transportista, setErrors);


    useEffect(() => {
        if (id != null)
            load().then();

        clearValidation();
    }, [id]);

    const load = async () => {
        let result;

        if (id === 0) {
            setTitulo("Agregar");
            result = {activo: true}
        }else{
            setTitulo("Editar");
            result = await api.getById(id);
        }

        setTransportista(result);
    }

    const onChange = (name, value) => {
        console.log(name, value);
        let newValue = {...transportista, [name]:value};
        setTransportista(newValue);
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;
        await api.save(transportista);
        onSave();
    }

    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Transportista")}`} open={open} close={close} size={700}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Grid container spacing={2}>
                        <FieldTextBox label="Nombre" name="name" value={transportista.name} xs={8} onChange={onChange}/>
                        <FieldCheckBox label="Activo" name="activo" value={transportista.activo} xs={8}
                                       onChange={onChange} mt={3}/>
                    </Grid>
                </ValidationContext.Provider>
                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>
                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
