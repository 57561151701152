/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import BasicModal from "../shared/modal/BasicModal";
import {Box, Button} from "@mui/material";
import FieldTextBox from "../shared/fields/FieldTextBox";
import {Check, Logout} from "@mui/icons-material";
import {UserContext} from "./AuthProvider";

const RefreshPassword = () => {
    const {t} = useTranslation();
    const {user, login, logout} = useContext(UserContext);
    const [password, setPassword] = useState('');

    const onLogout = ()=>{
        setPassword('');
        logout();
    }

    const onLogin = ()=>{
        setPassword('');
        login({email: user.userName, password});
    }

    return (
        <>
            <BasicModal open={user.sessionExpired} width={'500px'} title={t('Sesión expirada')} closeHidden={true}>
                <Box p={2} width={'100%'}>
                    <FieldTextBox value={password} label={t('Ingresar Contraseña')} type={"password"}
                                  onChange={(n, value) => setPassword(value)}/>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} gap={2} >
                    <Button variant="outlined"  size="small" startIcon={<Logout/>}
                            onClick={onLogout}>
                        {t('Cerrar Sesión')}
                    </Button>
                    <Button variant="contained" size="small" color="success" startIcon={<Check/>} onClick={onLogin }>
                        {t('Registrar Contraseña')}
                    </Button>
                </Box>
            </BasicModal>
        </>
    )
}

export default RefreshPassword;
