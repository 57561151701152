/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import FieldDateTime from "../../shared/fields/FieldDateTime";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import {VIAJE_ESTATUS as estatusViaje, VIAJE_TIPO as tipoViaje} from "../../../utility/constants";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import TripRequeriments from "./TripRequeriments";

export default function TripDetailGeneral({
                                              viaje,
                                              origen,
                                              section,
                                              camposDeshabilitados,
                                              onChange,
                                              puedeEditarPedimento,
                                              proveedorLogisticoHabilitado,
                                          }) {
    const esEnvio = section.name === "enviosCargados" || section.name === "enviosVacios";
    const esViajeCargado = section.tipo === tipoViaje.cargado;

    const {t} = useTranslation();

    return (
        <>  
            <Grid container spacing={2} mb={2}>
                <Grid item xs={7} fontWeight={700}>
                    {t("Información de viaje")}
                </Grid>

                <Grid item xs={5} textAlign="right">
                    {viaje.notificacionesEnviadas > 0 &&
                    <span>{t("Notificaciones")}: {viaje.notificacionesEnviadas}</span>
                    }
                </Grid>

                {esEnvio ?
                    <>
                        <FieldDateTime label={t("Fecha Estimada")} name="fechaEstimadaEnvio" onChange={onChange}
                                       value={viaje.fechaEstimadaEnvio} xs={6}
                                       disabled={camposDeshabilitados || viaje.puedeEditarViajeIncompleto}/>

                        <FieldDateTime label={t("Fecha Real")} value={viaje.fechaRealEnvio} xs={5} disabled/>

                        <Grid item={1} alignSelf="center" mt={2}>{t("TurnoAbreviado")}{viaje.turnoSalida}</Grid>


                        <FieldTextBox label={t("No Viaje")} name="numeroViaje" value={viaje.numeroViaje}
                                    ignoreAllDisabled={true} xs={6}
                                    onChange={onChange} disabled={camposDeshabilitados && !proveedorLogisticoHabilitado}/>

                       
                    </>
                    :
                    <>
                        <FieldDateTime label={t("Fecha Estimada")} value={viaje.fechaEstimadaLlegada} xs={6}
                                       disabled/>

                        <FieldDateTime label={t("Fecha Real")} value={viaje.fechaRealLlegada} xs={6} disabled/>


                        <FieldTextBox label={t("No Viaje")} name="numeroViaje" value={viaje.numeroViaje}
                                    ignoreAllDisabled={true} xs={6}
                                    onChange={onChange} disabled={camposDeshabilitados && !proveedorLogisticoHabilitado}/>

                                       
                    </>
                }
             

                <FieldTextBox label={t("No Entrega")} name="numeroEntrega" value={viaje.numeroEntrega}
                              xs={6} onChange={onChange}
                              disabled={camposDeshabilitados || !esViajeCargado}/>

                <Grid container item={4} xs={6}>
                    <Grid xs={12}>
                        {esEnvio &&
                        <FieldCheckBox label="Ruta con doble destino" name="esDobleDestino" value={viaje.esDobleDestino}
                                    disabled={(camposDeshabilitados || viaje.puedeEditarViajeIncompleto)}
                                    onChange={onChange}/>
                        }
                    </Grid>

                    <Grid xs={12}>
                        <FieldCheckBox label="Cliente Recoge" name="clienteRecoge" value={viaje.clienteRecoge}
                                        disabled={(camposDeshabilitados || viaje.puedeEditarViajeIncompleto)}
                                        mt={2} onChange={onChange}/>
                    </Grid>
                    <Grid xs={12}>
                        <FieldCheckBox label="Doble Transportista" name="esDobleTransportista" value={viaje.esDobleTransportista}
                                    disabled={(camposDeshabilitados || viaje.puedeEditarViajeIncompleto)}
                                    mt={2} onChange={onChange}/>
                    </Grid>
                    <Grid xs={12}>
                        <FieldCheckBox label="Retorno" name="esRetorno" value={viaje.esRetorno}
                                                disabled={(camposDeshabilitados || viaje.puedeEditarViajeIncompleto)}
                                                mt={2} onChange={onChange}/>

                    </Grid>
                </Grid>

                <Grid container item={2} xs={6}>

                    <Grid xs={12}>
                        <FieldDateTime label={t("Fecha Entrega")} name="fechaEntrega" onChange={onChange}
                                        value={viaje.fechaEntrega} xs={12}
                                        disabled={camposDeshabilitados || viaje.puedeEditarViajeIncompleto}/>
                    </Grid>

                    <Grid xs={12}>
                        {esEnvio ?
                           <>
                            <FieldDropDown label={t('Destino')} xs={12} name="destinoId" source="plantasDestino"
                            valueById value={viaje.destinoId} onChange={onChange}
                            filter={{id: origen?.id}}
                            disabled={camposDeshabilitados || viaje.puedeEditarViajeIncompleto}/>
                          </>
                          :
                          <>
                            <FieldDropDown label={t('Origen')} xs={12} name="origenId" source="plantas"
                                    value={viaje.origenId} onChange={onChange} valueById
                                    filter={{id: origen?.id}} disabled={camposDeshabilitados}/>
                        </>
                        }
                    </Grid>

                    <Grid xs={12}>
                        {viaje.esDobleDestino &&
                        <FieldDropDown label="Planta Intermedia" name="plantaIntermediaId" source="plantasDestino"
                                    valueById value={viaje.plantaIntermediaId} onChange={onChange}
                                    filter={{id: origen?.id}} disabled={camposDeshabilitados}/>
                        }
                    </Grid>
                </Grid>
             



                <Grid item xs={12} fontWeight={700}>
                    {t("Información de transportista")}
                </Grid>

                <FieldDropDown label={t('Transportista')} xs={12} name="transportista" source="transportistas"
                               ignoreAllDisabled={true}
                               value={viaje.transportista} onChange={onChange} disabled={camposDeshabilitados && !proveedorLogisticoHabilitado}/>

                <FieldDropDown label="Tipo de Transporte" xs={6} name="tipoTransporte" onChange={onChange}
                               source="tiposTransporte" value={viaje.tipoTransporte} valueById
                               disabled={camposDeshabilitados} translate/>

                <FieldTextBox label={t("Unidad")} name="unidad" value={viaje.unidad} xs={6} onChange={onChange}
                              ignoreAllDisabled={true}
                              disabled={camposDeshabilitados && !proveedorLogisticoHabilitado} />

                <FieldTextBox label={t("Sello Inicial")} name="selloInicial" value={viaje.selloInicial} xs={6}
                              onChange={onChange} disabled={camposDeshabilitados}/>

                <FieldTextBox label={t("Sello Final")} name="selloFinal" value={viaje.selloFinal} xs={6}
                              onChange={onChange} disabled={camposDeshabilitados}/>

                {!esEnvio && !viaje.creadoEnDestino && (viaje.enTransito || viaje.recibido) &&
                <>
                    <FieldTextBox label={t("Sello Confirmado Inicial")} name="selloRealInicial"
                                  onChange={onChange} value={viaje.selloRealInicial} xs={6}
                                  disabled={viaje.estatus === estatusViaje.recibido} ignoreAllDisabled/>

                    <FieldTextBox label={t("Sello Confirmado Final")} name="selloRealFinal"
                                  value={viaje.selloRealFinal} xs={6} onChange={onChange}
                                  disabled={viaje.estatus === estatusViaje.recibido} ignoreAllDisabled/>
                </>
                }

                <Grid item xs={12} fontWeight={700}>
                    {t("Información Documentación")}
                </Grid>

                <FieldTextBox label="Número de Factura" name="factura" value={viaje.factura}
                              xs={4} onChange={onChange} disabled={camposDeshabilitados}/>

                <FieldTextBox label="SOR/ Referencia" name="referencia" value={viaje.referencia}
                              xs={4} onChange={onChange} disabled={camposDeshabilitados}/>

                <FieldTextBox label="Número PO" name="ordenCompra" value={viaje.ordenCompra}
                              xs={4} onChange={onChange} disabled={camposDeshabilitados}/>

                <FieldDropDown label="Tipo de Envío" xs={4} name="tipoEnvio" source="tiposEnvio"
                               value={viaje.tipoEnvio} onChange={onChange} disabled={camposDeshabilitados}
                               valueById disableClearable translate/>

                <FieldDropDown label="Incoterms 2010" xs={4} name="incoterm" source="incoterms"
                               value={viaje.incoterm} onChange={onChange} disabled={camposDeshabilitados}
                               valueById disableClearable/>

                <FieldTextBox label="Número de Pedimento" name="numeroPedimento" value={viaje.numeroPedimento}
                              xs={4} onChange={onChange} 
                              error={!viaje.numeroPedimento && viaje.errorPedimento}
                              isNumeric precision={0} maxLength={15} thousandSeparator={false}/>

                <FieldCheckBox label="Partes relacionadas" name="esParteRelacionada" xs={4}
                               value={viaje.esParteRelacionada} onChange={onChange}
                               disabled={camposDeshabilitados || viaje.recibido}/>

                <Grid item xs={4}/>

                <FieldCheckBox label="Recepción Carta Porte" xs={4} value={viaje.cartaPorteRecibida} disabled={true}/>
            </Grid>

            <TripRequeriments viaje={viaje} camposDeshabilitados={camposDeshabilitados} onChange={onChange}
                              esViajeCargado={esViajeCargado}/>
        </>
    )
}
