import useServiceFactory from "../useServiceFactory";
const apiUrl = '/settings/rackManagementPurchase';

const useApiRackManagementPurchase = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params),
        validate: async (params) => service.post(`${apiUrl}/validate`, params, {showSuccess:false, showLoading:false})
    }
};

export default useApiRackManagementPurchase;
