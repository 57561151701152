import moment from "moment";

export const formatDate = (date, withHour) => {
    if (date == null || date.trim() === '')
        return ''

    const format = withHour ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
    return moment(date).format(format);
}

export const isoDateString = (date, addZeroHoursString) => {
    const hours = addZeroHoursString ? 'T00:00:00' : ''
    return date.format("YYYY-MM-DD") + hours;
}

export const getYearsAndMonths = (startDate, numberOfMonths, addTotalYear, lastCalendarMonth) => {
    const months = [];
    const years = [];
    let date = moment(startDate);
    let currentDate = moment();
    let currentYear = {year: 0};

    for (let i = 0; i < numberOfMonths; i++) {
        const newDate = new Date(date.toDate());
        if (date.year() !== currentYear.year) {
            currentYear = {year: date.year(), monthCount: 0}
            years.push(currentYear);
        }
        currentYear.monthCount++;
        const momentDate = moment(newDate);
        months.push({
            isTotal: false,
            isCurrentMonth: momentDate.isSame(currentDate, 'month'),
            name: momentDate.format('MMM'),
            id: momentDate.format('YYMM'),
        });
        if (addTotalYear === true && date.month() + 1 === lastCalendarMonth) {
            months.push({
                isTotal: true,
                name: 'TOTAL',
                id: moment(newDate).format('YY'),
            });
            currentYear.monthCount++;
        }
        date.add(1, 'M');
    }
    return {months: months, years: years};
}

export const getMonthsWeekDays = (serverDays) => {
    let months = [];
    let weeks = [];
    let days = [];
    let currentMonth = {date: moment(0)};
    let currentWeek = {};
    for (let day of serverDays) {
        let date = moment(day.date.substring(0, 10));
        if (!date.isSame(currentMonth.date, 'month')) {
            currentMonth = getMonth(date);
            months.push(currentMonth)
        }
        if (day.weekNumber !== currentWeek.weekNumber) {
            currentWeek = getWeek(date, day.weekNumber)
            currentMonth.visibleWeeks++;
            weeks.push(currentWeek);
        }

        currentMonth.visibleDays++;
        currentWeek.visibleDays++;
        days.push(getDay(date));
        date = date.clone().add(1, 'days');
    }

    return {months, weeks, days}
}

export const getDay = (date) => {
    return {
        id: date.format('YYYYMMDD'),
        date: date,
        weekNumber: date.week(),
        dayNumber: date.date(),
        dateString: date.format('YYYY-MM-DD'),
        shortName: date.format('dd'),
        fullName: date.format('dddd'),
        isWeekend: date.day() === 6 || date.day() === 0,
        isToday: moment().isSame(date, 'day')
    }
}

const getWeek = (date, weekNumber) => {
    const week = moment(date).startOf('week');
    return {
        id: week.format('YYYYww'),
        date: week,
        weekNumber: weekNumber ?? week.week(),
        calendarWeek: week.week(),
        dateString: week.format('YYYY-MM-DD'),
        visibleDays: 0,
    }
}

const getMonth = (date) => {
    const month = moment(date).startOf('month');
    return {
        id: month.format('YYYYMM'),
        date: month,
        dateString: month.format('YYYY-MM-DD'),
        shortName: month.format('MMM'),
        fullName: month.format('MMMMM'),
        visibleDays: 0,
        visibleWeeks: 0
    }
}
