import useServiceFactory from "../useServiceFactory";


const api = 'security';
const useApiSecurity = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        validate: async (credentials) => service.post(`/${api}/validate`, credentials, {showSuccess: false}),
        oauth: async (params) => service.post(`/${api}/oauth/accessToken`, params, {showLoading: false}),
        enviarCambioPassword: async (params) => service.patch(`/${api}/enviarCambioPassword`, params),
        cambiarPassword: async (id, params) => service.patch(`/${api}/${id}/cambiarPassword`, params),
        getUser: ()=> service.get(`/${api}/user`, {showLoading: false}),
    }
};

export default useApiSecurity;
