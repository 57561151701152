/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {Button, Grid} from "@mui/material";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import {ViewDetailButton} from "../../shared/ag-grid-helper/goToDetail";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import InventoryDetail from "./InventoryDetail";
import {Download} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DialogUi from "../../shared/modal/Dialog";
import FieldRangeDatePicker from "../../shared/fields/FieldDateRangePicker";
import useApiInventario from "../../../api/operation/useApiInventario";
import moment from "moment";
import {AppContext} from "../../Main";
import {MENUS_IDS} from "../../../utility/constants";

export default function Inventory({datos, filtro, setFiltro}) {
    const api = useApiInventario();
    const {t} = useTranslation();
    const {user} = useContext(AppContext);

    const [rackId, setrackId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);
    const [modalAbierta, setModalAbierta] = useState(false);
    const isReadonly = user.menu?.find(e => e.id === MENUS_IDS.mainInventory)?.readOnly ?? true;

    useEffect(() => {
        setModalAbierta(false);
    }, [])

    const onChange = (name, value) => {
        setFiltro({...filtro, [name]: value});
    };

    const verDetalle = (id) => {
        setrackId(id);
        setAbrirDetalle(true)
    };

    const editar = ({data}) => <ViewDetailButton data={data} verDetalle={verDetalle}/>;

    const abrirModal = () => {
        onChange("fechaReporte", null);
        setModalAbierta(true);
    }

    const descargarReporte = async () => {
        const titulosCeldas = {
            A1: t("Rack"),
            B1: t("Planta Actual"),
            C1: t("Almacén Actual"),
            D1: t("Area Actual"),
            E1: t("Día de registro"),
            F1: `${t("Seguimiento")} ${t("Planta")}`,
            G1: `${t("Seguimiento")} ${t("Almacén")}`,
            H1: `${t("Seguimiento")} ${t("Area")}`,
            I1: t("Días en ubicación"),
            J1: t("Tipo de movimiento"),
            K1: t("Origen")
        }

        const tiposMovimiento = {
            51: t("Viaje"),
            52: t("Traspaso"),
            53: t("Conteo"),
        };

        const fecha = moment(moment().toDate()).format("YYYYMMDDHHmm");
        const nombreReporte = `${t("Seguimiento")}_${fecha}`;
        const filtros = {
            ...filtro,
            tipoRacksIds: filtro.tipoRacksIds?.join(),
            plantasIds: filtro.plantasIds.join(),
            titulosCeldas,
            tiposMovimiento
        };

        await api.reporteHistorial(filtros, nombreReporte);
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center" pt={0}>
                {isReadonly ?
                    <FieldDropDownMultiselect label="Planta" sm={6} md={3} name="plantasIds" options={user.plantas}
                                              value={filtro.plantasIds} onChange={onChange} valueById disabled={false}/>
                    :
                    <FieldDropDownMultiselect label="Planta" sm={6} md={3} name="plantasIds" source="plantas"
                                              value={filtro.plantasIds} onChange={onChange} valueById/>
                }

                <FieldDropDownMultiselect label="Almacén" sm={6} md={3} name="almacenesIds" source="almacenes"
                                          value={filtro.almacenesIds} onChange={onChange} valueById
                                          filter={{plantasIds: filtro.plantasIds}}/>

                <FieldDropDownMultiselect label="Área" sm={6} md={3} name="areasIds" source="areas"
                                          value={filtro.areasIds} onChange={onChange} valueById
                                          filter={{almacenesIds: filtro.almacenesIds}}/>

                <FieldDropDownMultiselect label="Tipo de Rack" sm={6} md={3} name="tipoRacksIds" source="tiposRack"
                                          value={filtro.tipoRacksIds} onChange={onChange} valueById/>

                <FieldAutoComplete label="ID" name="id" value={filtro.id} sm={6} md={3}
                                   source="racks" valueById onChange={onChange}/>

                <Grid item sm={6} md={9} alignSelf="end" textAlign="right">
                    <Button variant="contained" color="success" size="small" startIcon={<Download/>}
                            onClick={abrirModal}>
                        {t('Reporte')}
                    </Button>
                </Grid>
            </Grid>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            dateFormatter: agGridHelper.DateFormatter,
                            viewDetailButton: editar
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={datos}
                    localeTextFunc={function (key, defaultValue) {
                        return t(defaultValue);
                    }}
                >
                    <AgGridColumn field="name" headerName="ID" minWidth={350}/>
                    <AgGridColumn field="planta" headerName={t('Planta')} minWidth={350}
                                  wrapText={true} autoHeight={true}/>
                    <AgGridColumn field="almacen" headerName={t('Almacén')}
                                  wrapText={true} autoHeight={true}/>
                    <AgGridColumn field="area" headerName={t('Área')}/>
                    <AgGridColumn field="fechaLlegada" headerName={t('Fecha de Llegada')} cellRenderer="dateFormatter"/>
                    <AgGridColumn field="diasEnUbicacion" headerName={t('Días')} maxWidth={80}/>
                    <AgGridColumn maxWidth={90} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerClass="center"/>
                </AgGridReact>
            </Box>

            {abrirDetalle &&
            <InventoryDetail rackId={rackId} open={abrirDetalle} close={() => setAbrirDetalle(false)}/>
            }

            <DialogUi open={modalAbierta} title={t("Descargar Reporte")} onClose={() => setModalAbierta(false)}
                      width="md">
                <Grid container>
                    <FieldRangeDatePicker label={t('Fecha')} xs={8} name="fechaReporte" value={filtro.fechaReporte}
                                          onChange={onChange}/>
                </Grid>

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>}
                            onClick={() => setModalAbierta(false)}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="success" size="small" startIcon={<Download/>}
                            onClick={descargarReporte}>
                        {t("Descargar")}
                    </Button>
                </div>
            </DialogUi>
        </>
    );
}
