import {Button, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {useContext} from "react";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";

export const HeaderAddButton = ({verDetalle}) => {
    const {t} = useTranslation();

    return (
        <div className="ag-grid row-icons">
            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => verDetalle(0)}>
                {t('Agregar')}
            </Button>
        </div>
    )
};

export const ViewDetailButton = ({data, verDetalle, eliminar, eliminarDeshabilitado}) => {
    const {t} = useTranslation();
    const {readOnlyContext} = useContext(ReadOnlyContext);

    return (
        <div className="ag-grid row-icons">
            {!readOnlyContext?.current && eliminar &&
            <Tooltip title={t('Eliminar Registro')}>
                <IconButton color="error" size="small" onClick={() => eliminar(data.id)}
                            disabled={eliminarDeshabilitado}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            }

            <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    )
};
