import * as yup from "yup";

const rackManagementPurchaseSchema = yup.object().shape({
    fechaCompra: yup.date().required(),
    ordenCompra: yup.string().trim().required(),
    proveedorId: yup.number().required(),
    precio: yup.number().required(),
    tipoRacks: yup.array().min(1,"Debe agregar al menos un tipo de rack")
        .of(yup.object().shape({
            racks: yup.array().min(1,"Debe agregar al menos un rack")
                .of(yup.object().shape({
                    numeroRack:yup.string().trim().required().matches(/^(\w{4}|\w{8})$/ig , 'Debe ser de 4 u 8 caractéres'),
                }))
        }))
});

export default rackManagementPurchaseSchema;
