/* eslint-disable react-hooks/exhaustive-deps */
import useApiSource from "../../../api/configuration/useApiSource";
import {useEffect, useReducer} from "react";
import _ from "lodash"

const initialState = {options: [], selectedIds: [], selectedOptions: [], lastFilter: '', readOnly: false};

function optionsReducer(state, action) {
    const getOptions = (ids, stateOptions)=>{
        let options=[];
        let selectedOptions = [];
        for (let option of stateOptions){
            const newOption = {...option, selected: ids.indexOf(option.id) >= 0}
            options.push(newOption);
            if (newOption.selected){
                selectedOptions.push(newOption);
            }
        }
        return {options, selectedOptions}
    }

    switch (action.type) {
        case 'setOptions':
            return {...state, ...getOptions(state.selectedIds, action.options)};
        case 'setSelected':
            const ids =  Array.isArray(action.selected) ?  action.selected : [action.selected];
            return {...state,selectedIds: ids, ...getOptions(ids, state.options)};
        default:
            throw new Error();
    }
}


const useSourceUtils = (value, options, source, filter, multiple, valueById, orderBy) => {
    const api = useApiSource();
    const [state, dispatch] = useReducer(optionsReducer, initialState);

    useEffect(()=>{
        if (valueById === true)
            setSelectedId(value??[]);
        else
            setSelectedId(Array.isArray(value) ? (value??[]).map(e=>e.id) : value?.id );
    },[value, valueById])

    useEffect(()=>{
        if (source != null && (filter == null || JSON.stringify(filter) !== state.lastFilter)){
            searchFromSource(source, filter).then();
        }
    },[source, filter])

    useEffect(()=>{
        if (options != null)
            dispatchOptions(options);

    },[options])

    const searchFromSource= async (source, filter)=>{
        const options = await api.get(source, filter);
        dispatchOptions(options);
    }

    const dispatchOptions = (options)=>{
        const params = (orderBy??'name asc').split(' ');
        options = _.orderBy(options, params[0], params[1]);
        dispatch({type:'setOptions', options:options});
    }

    const setSelectedId = (selected)=>{
        dispatch({type:'setSelected', selected})
    }

    const getOptions = (selected)=>{
        const ids =  Array.isArray(selected) ?  selected : [selected];
        return state.options.filter(e=> ids.indexOf(e.id) >= 0);
    }

    const getSelectedId = ()=>{
        return  multiple ? state.selectedIds :
            (state.selectedIds.length === 0 ? 0 : state.selectedIds[0])
    }

    const getSelected = ()=>{
        return  multiple ? state.selectedOptions :
            (state.selectedIds.length === 0 ? 0 : state.selectedOptions[0])
    }

    const toggleSelection =(id)=>{
        const newValues = state.selectedIds.indexOf(id) < 0  ?
            [...state.selectedIds, id] :
            state.selectedIds.filter(e=>e !== id);
        getSelectedId(newValues);
        return newValues;
    }

    return {
        currentOptions : state.options,
        setSelectedId,
        toggleSelection,
        getSelectedId,
        getSelected,
        getOptions
    }
};

export default useSourceUtils;