/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import ExGrid from "../../shared/Grid/ExGrid";
import ExColumn from "../../shared/Grid/ExColumn";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import useMessage from "../../../hooks/useMessage";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";

export default function RackTypeMaterials({tipoRack, onChange}) {
    const {t} = useTranslation();
    const message = useMessage();
    const api = useApiRackManagement();

    const onChangeMateriales = async (materiales, changeType, materialEliminado) => {
        if(changeType === 'remove' && !!materialEliminado.id){
            await message.confirm(t("Se eliminará el material"), t("¿Desea Continuar?"));
            await api.deleteMaterial(materialEliminado.id);
            onChange('materiales', materiales);
        }else{
            onChange('materiales', materiales);
        }
    }

    return (
        <div className="main-grid">
            <ExGrid data={tipoRack.materiales}
                    showAddDeleteColumn
                    addTitle={t('Agregar Material')}
                    onChange={onChangeMateriales}
            >
                <ExColumn
                    field="numero"
                    width="1fr"
                    title={t('Número')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldTextBox value={row.numero} xs variant="standard" name={`materiales[${rowIndex}].numero`}
                                      onChange={onChange}/>
                    }
                />
                <ExColumn
                    field="descripcion"
                    width="1fr"
                    title={t('Descripción')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldTextBox value={row.descripcion} xs variant="standard" onChange={onChange}
                                      name={`materiales[${rowIndex}].descripcion`}/>
                    }
                />
                <ExColumn
                    field="tipoId"
                    width="1fr"
                    title={t('Tipo de Material')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldDropDown value={row.tipoId} xs variant="standard" onChange={onChange} source="generales"
                                       name={`materiales[${rowIndex}].tipoId`} valueById disableClearable
                                       filter={{name: "TipoMaterialRack"}}/>
                    }
                />
                <ExColumn
                    field="estandarPack"
                    width="1fr"
                    title={t('Estándar Pack')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldTextBox value={row.estandarPack} xs variant="standard" onChange={onChange}
                                      name={`materiales[${rowIndex}].estandarPack`}/>
                    }
                />
                <ExColumn
                    field="tipoProducto"
                    width="1fr"
                    title={t('Tipo de Producto')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldTextBox value={row.tipoProducto} xs variant="standard" onChange={onChange}
                                      name={`materiales[${rowIndex}].tipoProducto`}/>
                    }
                />
                <ExColumn
                    field="plantaFabricacionAId"
                    width="1fr"
                    title={t('Planta A de fab')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldDropDown value={row.plantaFabricacionAId} xs variant="standard" onChange={onChange}
                                       source="plantas" name={`materiales[${rowIndex}].plantaFabricacionAId`} valueById
                                       disableClearable/>
                    }
                />
                <ExColumn
                    field="plantaFabricacionBId"
                    width="1fr"
                    title={t('Planta B de fab')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldDropDown value={row.plantaFabricacionBId} xs variant="standard" onChange={onChange}
                                       source="plantas" name={`materiales[${rowIndex}].plantaFabricacionBId`} valueById
                                       disableClearable/>
                    }
                />
                <ExColumn
                    field="plantaClienteId"
                    width="1fr"
                    title={t('Cliente')}
                    cellRenderer={({row, rowIndex}) =>
                        <FieldDropDown value={row.plantaClienteId} xs variant="standard" onChange={onChange}
                                       source="plantas" name={`materiales[${rowIndex}].plantaClienteId`} valueById
                                       disableClearable/>
                    }
                />
            </ExGrid>
        </div>
    );
}
