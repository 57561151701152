/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import produce from "immer";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Checkbox, FormControlLabel, Grid, InputAdornment, Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import rackManagementSchema from "./rackManagementValidation";
import {setDeepValue} from "../../../utility/objectUtility";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import padStart from "lodash/padStart";
import useApiRackManagement from "../../../api/settings/useApiRackManagement";
import {Print} from "@mui/icons-material";
import useMessage from "../../../hooks/useMessage";
import useNotification from "../../../hooks/useNotification";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import {getCoordenada, zplPrintCode} from "../../../utility/zplPrintCode";
import {UserContext} from "../../security/AuthProvider";
import {MENUS_TIPO_OPCION as opciones} from "../../../utility/constants";
import Racks from "./Racks";
import TabPanel from "../../shared/TabPanel";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import RackTypeMaterials from "./RackMaterials";

export default function RackManagementDetail({tipoRackId, open, close, onSave, temporalId}) {
    const defaultEntity = {id: 0, activo: true, racks: [], plantaIds: [], materiales: [], imprimirTodo: false};
    const api = useApiRackManagement()
    const message = useMessage();
    const notification = useNotification();
    const {t} = useTranslation();
    const {user} = useContext(UserContext);
    const puedeEditar = user.menus.includes(opciones.edicionDatosMaestros);

    const [title, setTitle] = useState('');
    const [tipoRack, setTipoRack] = useState(defaultEntity);
    const [allRacks, setAllRacks] = useState([]);
    const [repeatedRack, setRepeatedRack] = useState(false);
    const [errors, setErrors] = useState({});
    const [esEditable, setEsEditable] = useState(false);
    const [validate, clearValidation] = useValidate(rackManagementSchema, tipoRack, setErrors);
    const [numeroTab, setNumeroTab] = React.useState(0);

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            result.imprimirTodo = false;
            setTipoRack(result);
            setAllRacks(result.racks);
        }

        if (tipoRackId > 0) {
            setTitle("Editar Tipo Rack");
            load(tipoRackId).then();
        } else {
            setTitle("Agregar Tipo Rack");
            setTipoRack(defaultEntity);
            setAllRacks([]);
        }

        clearValidation();
        setEsEditable(tipoRackId === 0);
        setNumeroTab(0);
    }, [temporalId]);

    const onChange = (name, value, idChanged) => {
        const newEntity = produce(tipoRack, (draft) => {
            if (name === 'alias' || name === 'descripcion') {
                const alias = name === 'alias' ? value : tipoRack.alias;
                const descripcion = name === 'descripcion' ? value : tipoRack.descripcion;
                const nombre = `${alias || ''} ${descripcion || ''}`

                setDeepValue('name', draft, nombre);
            }

            setDeepValue(name, draft, value);
        });

        // Solo trae valor si cambia alguna propiedad principal del rack (name, activo o genérico)
        if (idChanged) {
            const rackChanged = newEntity.racks.find(e => e.id === idChanged);
            const copyRacks = allRacks.map(e => e.id === idChanged ? {...rackChanged} : e);
            setAllRacks(copyRacks);
        }

        setTipoRack(newEntity);
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;

        let genericosActivos = 0;
        let existeRackRepetido = false;

        const racksCopy = allRacks.map((e, index) => {
            const repeated = allRacks.some((r, i) => index !== i && e.numeroRack === r.numeroRack);
            genericosActivos = e.esGenerico && e.activo ? ++genericosActivos : genericosActivos;

            if (repeated) existeRackRepetido = true;

            return {...e, repeated};
        })

        if (existeRackRepetido || genericosActivos > 2) {
            if (existeRackRepetido) {
                onChange('racks', racksCopy);
                setAllRacks(racksCopy);
                setRepeatedRack(true);
            } else notification.error("No puede haber mas de 2 racks genéricos")
            return;
        }

        if (tipoRack.id > 0 && esEditable)
            await message.confirm(t("Se guardaran los cambios"), t("¿Desea Continuar?"))

        const racks = allRacks.filter(e => e.esNuevo || e.modificado);
        await api.save({...tipoRack, racks});
        onSave();
    }

    const imprimir = async () => {
        const valid = await validate();
        if (!valid) return;

        const totalImpresiones = tipoRack.racks.reduce((sum, e) => sum + (e.copias ? e.copias : 0), 0);
        const racksaImprimir = tipoRack.racks.filter(e => e.imprimir);
        const totalRacks = racksaImprimir.length;

        await message.confirm(t("Imprimir"),
            `${t("Total de racks")}: ${totalRacks}. ${t("Total de etiquetas")}: ${totalImpresiones}, ${t("¿Desea Continuar?")}`)

        // eslint-disable-next-line no-undef
        BrowserPrint.getDefaultDevice("printer", async function (device) {
            if (device.name) {
                racksaImprimir.forEach(e => {
                    let rfid = `B${padStart(e.id, 6, '0')}C${e.numeroRack}`;
                    rfid = e.numeroRack.length > 4 ? rfid : `A${tipoRack.numeroSap}${rfid}`;
                    rfid = padStart(rfid, 24, '0');

                    const variables = {
                        "#Alias#": tipoRack.alias,
                        "#Descripcion#": tipoRack.descripcion,
                        "#NumeroRack#": e.numeroRack,
                        "#NumeroSap#": tipoRack.numeroSap,
                        "#RFID#": rfid,
                        "#Coordenada1#": getCoordenada(tipoRack.alias),
                        "#Coordenada2#": getCoordenada(tipoRack.descripcion),
                        "#Coordenada3#": e.numeroRack.length === 4 ? 130 : 95,
                        "#Coordenada4#": e.numeroRack.length === 4 ? 82 : 42,
                        "#Copias#": e.copias
                    };

                    const expresion = new RegExp(Object.keys(variables).join("|"), "gi");
                    const codigoImpresion = zplPrintCode.replace(expresion, matched => variables[matched]);

                    device.send(codigoImpresion, null, function (errorMessage) {
                        notification.error(errorMessage);
                    });
                })

                const result = await api.saveImpresiones({racks: racksaImprimir});

                const copiasRack = tipoRack.racks.map(e => {
                    const rackActualizado = result.find(r => r.id === e.id)

                    return rackActualizado == null ? {...e} : {...e, estatusEtiqueta: rackActualizado.estatusEtiqueta}
                });

                setTipoRack({...tipoRack, racks: copiasRack});
            } else {
                const result = await api.saveImpresiones({racks: racksaImprimir});

                const copiasRack = tipoRack.racks.map(e => {
                    const rackActualizado = result.find(r => r.id === e.id)

                    return rackActualizado == null ? {...e} : {...e, estatusEtiqueta: rackActualizado.estatusEtiqueta}
                });

                setTipoRack({...tipoRack, racks: copiasRack});

                notification.warning(t("La impresora no esta configurada"));
            }
        });
    };

    return (
        <>
            <SidebarRight title={t(title)} open={open} close={close} size={1150}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Tabs value={numeroTab} onChange={(event, value) => setNumeroTab(value)}>
                        <Tab label={t("Información General")}/>
                        <Tab label={t("Racks")}/>
                        <Tab label={t("Materiales")}/>
                    </Tabs>

                    <TabPanel value={numeroTab} index={0}>
                        <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                            <label style={{fontWeight: 'bold', flexGrow: 1}}>
                                {`${t("Nombre")}: ${tipoRack.name || ''}`}
                            </label>
                            <FormControlLabel label={t("Activo")}
                                              control={<Checkbox checked={tipoRack.activo} onChange={(e, value) => {
                                                  onChange("activo", value)
                                              }}/>
                                              }
                            />
                        </Box>

                        <Grid container spacing={2} mb={2}>
                            <FieldTextBox label={t("Alias")} name="alias" value={tipoRack.alias} xs={6}
                                          onChange={onChange} disabled={!esEditable} maxLength={18}/>

                            <FieldTextBox label={t("Descripción")} name="descripcion" value={tipoRack.descripcion}
                                          xs={6}
                                          onChange={onChange} disabled={!esEditable} maxLength={18}/>

                            <FieldTextBox label={t("Numero Sap")} name="numeroSap" value={tipoRack.numeroSap} xs={6}
                                          onChange={onChange} disabled={tipoRack.id !== 0} maxLength={10}/>

                            <FieldDropDownMultiselect label={t('Plantas')} xs={6} name="plantasIds" source="plantas"
                                                      value={tipoRack.plantasIds} onChange={onChange} multiselect
                                                      valueById disabled={!esEditable}/>

                            <FieldTextBox label={`${t("Largo")} (in)`} name="largo" value={tipoRack.largo} xs={3}
                                          onChange={onChange} isNumeric precision={0} disabled={!esEditable}/>

                            <FieldTextBox label={`${t("Ancho")} (in)`} name="ancho" value={tipoRack.ancho} xs={3}
                                          onChange={onChange} isNumeric precision={0} disabled={!esEditable}/>

                            <FieldTextBox label={`${t("Alto")} (in)`} name="alto" value={tipoRack.alto} xs={3}
                                          onChange={onChange} isNumeric precision={0} disabled={!esEditable}/>

                            <FieldTextBox label={`${t("Peso")} (lbs)`} name="peso" value={tipoRack.peso} xs={3}
                                          onChange={onChange} isNumeric disabled={!esEditable}/>

                            <FieldTextBox label={t("Ocupación en transporte")} name="porcentajeOcupacion" xs={3}
                                          value={tipoRack.porcentajeOcupacion} onChange={onChange} isNumeric
                                          disabled={!esEditable}
                                          endAdornment={<InputAdornment position="end">%</InputAdornment>}/>

                            <FieldTextBox label={`${t("Ocupación en transporte")} (${t("vacío")})`} xs={3}
                                          name="porcentajeOcupacionVacio" value={tipoRack.porcentajeOcupacionVacio}
                                          onChange={onChange} isNumeric disabled={!esEditable}
                                          endAdornment={<InputAdornment position="end">%</InputAdornment>}/>

                            <FieldDropDown label="Tipo de Vidrio" xs={3} name="tipoVidrioId" source="generales"
                                           valueById value={tipoRack.tipoVidrioId} onChange={onChange}
                                           filter={{name: 'TipoVidrio'}} disabled={!esEditable}/>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={numeroTab} index={1} className="full-tab-panel">
                        <Racks tipoRack={tipoRack} setTipoRack={setTipoRack} onChange={onChange} allRacks={allRacks}
                               setAllRacks={setAllRacks} setRepeatedRack={setRepeatedRack}/>
                    </TabPanel>

                    <TabPanel value={numeroTab} index={2} className="full-tab-panel">
                        <RackTypeMaterials tipoRack={tipoRack} onChange={onChange}/>
                    </TabPanel>
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        {!esEditable &&
                        <Button variant="contained" size="small" startIcon={<Print/>}
                                onClick={imprimir} disabled={!tipoRack.racks.some(e => e.imprimir)}>
                            {t('Imprimir')}
                        </Button>
                        }

                        {puedeEditar && !esEditable && numeroTab === 0 &&
                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={() => setEsEditable(true)}>
                            {t('Editar')}
                        </Button>
                        }

                        <Button variant="contained" size="small" startIcon={<SaveIcon/>}
                                onClick={save} disabled={repeatedRack}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
