/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import produce from "immer";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Grid, Tab, Tabs} from "@mui/material";

import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {setDeepValue} from "../../../utility/objectUtility";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import useApiUsuario from "../../../api/catalogs/useApiUsuario";
import userValidationSchema from "./userValidation";
import userUpdateValidationSchema from "./userUpdateValidation";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import FieldMultiselect from "../../shared/fields/FieldMultiselect";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import TabPanel from "../../shared/TabPanel";


export default function UserDetail({usuarioId, open, close, onSave, temporalId}) {
    const defaultEntity = {id: 0, enabled: true, destinosIds: []};
    const api = useApiUsuario();
    const {t} = useTranslation();

    const [titulo, setTitulo] = useState('');
    const [usuario, setUsuario] = useState(defaultEntity);
    const [errors, setErrors] = useState({});
    const [numeroTab, setNumeroTab] = React.useState(0);
    const [, ] = useState(false);
    const [validate, clearValidation] = useValidate(userValidationSchema, usuario, setErrors);
    const [validateUpdate, clearUpdateValidation] = useValidate(userUpdateValidationSchema, usuario, setErrors);
    
    
    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);
            setUsuario(result);
        }

        if (usuarioId > 0) {
            setTitulo("Editar");
            load(usuarioId).then();
        } else {
            setTitulo("Agregar");
            setUsuario(defaultEntity);
        }

        setNumeroTab(0);
        clearValidation();
        clearUpdateValidation();
    }, [temporalId]);

    const onChangeTab = (event, value) => {
        setNumeroTab(value);
    };

    const onChange = (name, value) => {
        const newEntity = produce(usuario, (draft) => {
            setDeepValue(name, draft, value);
        });

        setUsuario(newEntity);
    };

    async function changePassword() {
        
        if (!usuario.password) return;
     
        await api.changePassword(usuario);
        onSave();
    }

    async function save() {
        

        if(usuario.id > 0){

            const validUpdate = await validateUpdate();
            if (!validUpdate) return;

            await api.update(usuario);
        }

        else{
            const valid = await validate();
            if (!valid) return;

            await api.save(usuario);
        }

        onSave();
    }

    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Usuario")}`} open={open} close={close} size={700}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Tabs value={numeroTab} onChange={onChangeTab}>
                        <Tab label={t("Tipo de Perfil")}/>
                        <Tab label={t("Plantas")}/>
                    </Tabs>

                    <TabPanel value={numeroTab} index={0}>

                        <Grid container spacing={2}>    


                            <Grid item xs={8}>
                                <label style={{fontWeight: 'bold', flexGrow: 1}}>
                                    {`${usuario.fullName || ''}`}
                                </label>
                            </Grid>

                            <FieldCheckBox label="Activo" name="enabled" value={usuario.enabled} xs={4}
                                           onChange={onChange} />

                        
                            {/* <FormControlLabel label={t("Activo")} xs={4} name="enabled"
                                            control={<Checkbox style={{alignItems: 'right'}} checked={usuario.enabled} 
                                            onChange={onChange} />} 
                                            /> */}
                                        
                           

                        </Grid>
                        


                        <Grid container spacing={2}>
                            <FieldTextBox label="Nombre" name="name" value={usuario.name} xs={8} onChange={onChange}/>

                            <FieldTextBox label="Apellido" name="lastName" value={usuario.lastName} xs={8} onChange={onChange}/>
                            <FieldTextBox label="Correo" name="email" value={usuario.email} xs={8} onChange={onChange}/>
                          
                            
                            <FieldTextBox label="Contraseña" name="password" value={usuario.password} xs={8} onChange={onChange} />
                            {
                                usuarioId > 0 &&

                                <Grid item xs={4} sx={{mt:3}}>
                                <Button variant="outlined" size="small" onClick={changePassword}>
                                    {t('Cambiar Contraseña')}
                                </Button>
                            </Grid>
                            }
                         
                        
                            <FieldTextBox label="User Name" name="username" value={usuario.username} xs={8} onChange={onChange} />

                            <FieldDropDown label="Tipo de Perfil" xs={8} name="profileId" source="perfiles"
                                           valueById value={usuario.profileId} onChange={onChange}/>


                        </Grid>
                       
                    </TabPanel>

                    <TabPanel value={numeroTab} index={1}>

                        <FieldMultiselect label=" " name="destinosIds" value={usuario.destinosIds} source="plantas"
                                           valueById onChange={onChange} height="0%" filter={{id: usuario.id}}/>
                    </TabPanel>
                   
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
