export const ALERT_SEVERITY = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
}

export const VIAJE_TIPO = {
    cargado: 11,
    vacio: 12
}

export const VIAJE_ESTATUS = {
    guardado: 1,
    programado: 2,
    enOperacion: 3,
    porEnviar: 4,
    enTransito: 5,
    reciboParcial: 6,
    recibido: 7,
    enTransito1: 8
}

export const VIAJESTATUS_LIST = new Map([
    [VIAJE_ESTATUS.guardado, 'Guardado'],
    [VIAJE_ESTATUS.programado, 'Programado'],
    [VIAJE_ESTATUS.enOperacion, 'En Operación'],
    [VIAJE_ESTATUS.porEnviar, 'Por Enviar'],
    [VIAJE_ESTATUS.enTransito, 'En Tránsito'],
    [VIAJE_ESTATUS.reciboParcial, 'Recibo Parcial'],
    [VIAJE_ESTATUS.recibido, 'Recibido'],
    [VIAJE_ESTATUS.enTransito1, 'En Tránsito 1']
]);

export const LANGUAGES = [
    {id: 1, name: 'Español', code: 'es'},
    {id: 2, name: 'Inglés', code: 'en'}
]

export const MENUS_TIPO_OPCION = {
    edicionDatosMaestros: 5,
    proveedorLogistico: 12
}

export const TIPOS_TRANSPORTE = {
    camion: 21,
    furgon: 22,
    aereo: 23,
    maritimo: 24,
    intermodal: 25
}

export const TIPOS_TRANSPORTE_LIST = new Map([
    [TIPOS_TRANSPORTE.camion, 'Camión'],
    [TIPOS_TRANSPORTE.furgon, 'Furgón'],
    [TIPOS_TRANSPORTE.aereo, 'Aereo'],
    [TIPOS_TRANSPORTE.maritimo, 'Marítimo'],
    [TIPOS_TRANSPORTE.intermodal, 'Intermodal']
]);

export const TIPOS_ENVIO = {
    comercial: 31,
    noComercial: 32
}

export const TIPOS_ENVIO_LIST = new Map([
    [TIPOS_ENVIO.comercial, 'Comercial'],
    [TIPOS_ENVIO.noComercial, 'No Comercial']
]);

export const INCOTERMS = {
    exw: 41,
    fca: 42,
    dap: 43
}

export const INCOTERMS_LIST = new Map([
    [INCOTERMS.exw, 'EXW'],
    [INCOTERMS.fca, 'FCA'],
    [INCOTERMS.dap, 'DAP']
]);

export const PAISES = {
    mexico: 1,
    estadosUnidos: 2
}

export const CONTEO_INVENTARIO_ESTATUS = {
    programado: 11,
    enOperacion: 12,
    completadoParcial: 13,
    completado: 14
}

export const CONTEO_INVENTARIO_LIST = new Map([
    [CONTEO_INVENTARIO_ESTATUS.programado, 'Programado'],
    [CONTEO_INVENTARIO_ESTATUS.enOperacion, 'En Operación'],
    [CONTEO_INVENTARIO_ESTATUS.completadoParcial, 'Completado Parcial'],
    [CONTEO_INVENTARIO_ESTATUS.completado, 'Completado'],
]);

export const TRASPASO_ESTATUS = {
    pendiente: 21,
    enProceso: 22,
    completado: 23
}

export const TRASPASO_LIST = new Map([
    [TRASPASO_ESTATUS.pendiente, 'Pendiente'],
    [TRASPASO_ESTATUS.enProceso, 'En Proceso'],
    [TRASPASO_ESTATUS.completado, 'Completado'],
]);

export const RACK_TIPO_MOVIMIENTO = {
    viaje: 51,
    traspaso: 52,
    conteo: 53
}

export const RACK_TIPO_MOVIMIENTO_LIST = new Map([
    [RACK_TIPO_MOVIMIENTO.viaje, 'Viaje'],
    [RACK_TIPO_MOVIMIENTO.traspaso, 'Traspaso'],
    [RACK_TIPO_MOVIMIENTO.conteo, 'Conteo']
]);

export const VIAJE_RETRASO = {
    diasSinCompletar: 1,
    horasPrecaucionEnTransito: 12,
    horasAlertaEnTransito: 24
}

export const RACK_ESTATUS_ETIQUETA = {
    etiquetado: 31,
    pendienteEtiquetar: 32
}

export const RACK_ESTATUS = {
    reparadoDisponible: 41,
    reparadoEnEspera: 42,
    disponiblePorEscaneo: 43,
    danado: 51,
    porRehabilitar: 52,
    enRehabilitacion: 53,
    perdidaDeRack: 54,
    enProduccion: 55
}

export const RACK_ESTATUS_LIST = new Map([
    [RACK_ESTATUS.reparadoDisponible, 'Reparado y disponible'],
    [RACK_ESTATUS.reparadoEnEspera, 'Reparado en espera'],
    [RACK_ESTATUS.disponiblePorEscaneo, 'Disponible por escaneo'],
    [RACK_ESTATUS.danado, 'Dañado'],
    [RACK_ESTATUS.porRehabilitar, 'Por rehabilitar'],
    [RACK_ESTATUS.enRehabilitacion, 'En rehabilitación'],
    [RACK_ESTATUS.perdidaDeRack, 'Perdida de rack'],
    [RACK_ESTATUS.enProduccion, 'En producción']
]);

export const MENUS_IDS = {
    trip: 1,
    racksManagementMain: 3,
    plant: 4,
    mainInventory: 6,
    user: 7
}

export const PERFILES_IDS = {
    administrador: 1,
    operador: 2,
    edicionDatosMaestros: 3,
    seguridad: 5,
    responsableProductoTerminado: 7
}


export const TIPO_MENUS = {
    menu: 1,
    opcion: 2
}
