/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material";
import {Print} from "@mui/icons-material";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import DialogUi from "../../shared/modal/Dialog";
import moment from "moment";

const tiposRack = [
    {numeroSap: '1234567890', destino: 'VITRO-VITROFLEX', descripcion: 'MAC 14013', cantidad: 2, posicion: 1},
    {numeroSap: '0987654321', destino: 'VITRO-WESTLAND', descripcion: 'Paso 12 15013', cantidad: 5, posicion: 2},
];

export default function TripRackReport({viaje, open, close}) {
    const {t} = useTranslation();

    useEffect(() => {
        const load = async () => {
        }

        if (open) {
            load().then();
        }
    }, [open]);

    const imprimir = async () => {
        window.print();
        close();
    }

    const Reporte = () =>
        <>
            <table className="table-custom header">
                <tbody style={{textTransform: 'uppercase', fontSize: 13}}>
                <tr style={{height: "20px"}}>
                    <td rowSpan={4} style={{width: '100px', verticalAlign: "top"}}>
                        <img src="images/logo.png" alt="" style={{height: '23px'}}/>
                    </td>
                    <td colSpan={4}/>
                </tr>

                <tr>
                    <td style={{width: '20%'}}>{t("Realizó Reporte")}</td>
                    <td style={{width: '40%'}}/>
                    <td style={{width: '20%'}}>{t("Fecha")}</td>
                    <td style={{width: '15%'}}>
                        {moment(new Date()).format('DD/MM/YYYY')}
                    </td>
                </tr>

                <tr>
                    <td>{t("Cliente")}</td>
                    <td>{viaje.destino}</td>
                    <td>{t("# De Caja")}</td>
                    <td>{viaje.unidad}</td>
                </tr>

                <tr>
                    <td>{t("Transporte")}</td>
                    <td>{viaje.transportista}</td>
                    <td># {t("De Documento")}</td>
                    <td>{viaje.numeroViaje}</td>
                </tr>
                </tbody>
            </table>

            <br/>

            <table className="trip-documents racks">
                <thead style={{textTransform: 'uppercase'}}>
                <tr>
                    <th># {t("De Sap")}</th>
                    <th>{t("Destino")}</th>
                    <th>{t("Descripción")}</th>
                    <th>{t("Cantidad")}</th>
                    <th>{t("Posición")}</th>
                </tr>
                </thead>

                <tbody>
                {viaje.tiposRack?.map((tiporack, index) =>
                    <tr key={tiposRack.id}>
                        <td>{tiporack.numeroSap}</td>
                        <td>{viaje.destino}</td>
                        <td>{tiporack.name}</td>
                        <td>{tiporack.cantidadRecibida}</td>
                        <td>{index + 1}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </>

    return (
        <>
            <DialogUi open={open} title={t("Vista previa")} onClose={close} width="md" scroll="paper">
                <div className="no-print rack-report">
                    <Reporte/>
                </div>

                <Box display="flex" gap={1} alignItems="center">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="primary" size="small" startIcon={<Print/>} onClick={imprimir}>
                        {t("Imprimir")}
                    </Button>
                </Box>
            </DialogUi>

            {/*IMPRESION*/}
            <div className="only-to-print rack-report">
                <Reporte/>
            </div>
        </>
    );
}
