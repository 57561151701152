/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Grid, Tooltip, Typography} from "@mui/material";
import produce from "immer";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Box from "@mui/material/Box";
import FieldRangeDatePicker from "../../shared/fields/FieldDateRangePicker";
import TripDetail from "./TripDetail";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import useApiViaje from "../../../api/operation/useApiViaje";
import {
    VIAJE_TIPO as tipoViaje,
    VIAJE_ESTATUS as estatusViaje,
    MENUS_IDS,
    PERFILES_IDS
} from "../../../utility/constants";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import {AppContext} from "../../Main";
import {Download, VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import moment from "moment";
import {useHistory, useLocation, useParams} from "react-router-dom";
import TripConfirm from "./TripConfirm";
import TripGrid from "./grid/TripGrid";
import TripGridGuardHouse from "./grid/TripGridGuardHouse";

export const TripContext = React.createContext({section: {}, filter: {}});

export default function Trip() {
    const {user} = useContext(AppContext);
    const params = useParams();
    const history = useHistory();
    const location = useLocation().search;
    const idUrl = new URLSearchParams(location).get('viajeId');
    const plantaIdUrl = new URLSearchParams(location).get('plantaId');
    const tipoViajeId = new URLSearchParams(location).get('tipoViaje');
    const esViajeCargado = tipoViajeId == null || Number(tipoViajeId) === tipoViaje.cargado;
    const esPerfilSeguridad = user.profileId === PERFILES_IDS.seguridad;

    let plantaId = params.plantaId != null ? Number(params.plantaId): 0;
    plantaId = plantaIdUrl != null ? Number(plantaIdUrl) : plantaId;
    const planta = user.plantas.find(e => e.id === plantaId) ?? (user.plantas.length > 0 ? user.plantas[0] : {});

    let defaultEstatusIds = [estatusViaje.guardado, estatusViaje.programado];

    if (!esPerfilSeguridad)
        defaultEstatusIds = [...defaultEstatusIds, estatusViaje.enOperacion, estatusViaje.porEnviar,
            estatusViaje.enTransito, estatusViaje.reciboParcial, estatusViaje.enTransito1]

    const defaultFilter = {
        planta,
        destinosIds: [],
        transportistasIds: [],
        estatusIds: defaultEstatusIds,
        esEnvio: true,
        visible: !esPerfilSeguridad,
        tipo: esViajeCargado ? tipoViaje.cargado : tipoViaje.vacio
    };
    const {t} = useTranslation();
    const api = useApiViaje();
    const isReadonly = user.menu?.find(e => e.id === MENUS_IDS.trip)?.readOnly ?? true;
    const boxStyle = {alignItems: "center", border: '1.6px solid #03a9f4', borderRadius: 1, px: 1};

    const [grid, setGrid] = useState({data: []});
    const [viajeId, setViajeId] = useState(0);
    const [esEnvio, setEsEnvio] = useState(true);
    const [temporalId, setTemporalId] = useState(0);
    const [filtro, setFiltro] = useState(defaultFilter);
    const [detalleAbierto, setDetalleAbierto] = useState(false);
    const [tabs, setTabs] = useState([
        {id: 1, tipo: tipoViaje.cargado, name: 'enviosCargados', title: "Envíos Cargados", selected: esViajeCargado},
        {
            id: 2,
            tipo: tipoViaje.cargado,
            name: 'recepcionesCargadas',
            title: "Recepciones Cargadas",
            selected: false
        },
        {id: 3, tipo: tipoViaje.vacio, name: 'enviosVacios', title: "Envíos Vacíos", selected: !esViajeCargado},
        {id: 4, tipo: tipoViaje.vacio, name: 'recepcionesVacias', title: "Recepciones Vacías", selected: false}
    ]);
    const [section, setSection] = useState(tabs[0]);
    const [totalRacks, setTotalRacks] = useState({enviados: 0, recibidos: 0});
    const [modalEliminarAbierto, setModalEliminarAbierto] = useState(false);

    useEffect(() => {
        search(null, idUrl).then(() => history.replace({search: ""}));
    }, []);

    const getFiltros = (filterCopy, idUrl) => {
        filterCopy = filterCopy ?? filtro;

        const esEnvio = filterCopy.esEnvio;
        const fechaEstimadaEnvio = esEnvio ? filterCopy.fechaEstimada : null;
        const fechaRealEnvio = esEnvio ? filterCopy.fechaReal : null;
        const fechaEstimadaLlegada = !esEnvio ? filterCopy.fechaEstimada : null;
        const fechaRealLlegada = !esEnvio ? filterCopy.fechaReal : null;
        const fechas = {fechaEstimadaEnvio, fechaRealEnvio, fechaEstimadaLlegada, fechaRealLlegada};

        filterCopy = {
            ...filterCopy,
            origenesIds: esEnvio ? "" : filterCopy.origenesIds?.join(),
            destinosIds: esEnvio ? filterCopy.destinosIds?.join() : "",
            transportistasIds: filterCopy.transportistasIds?.join(),
            origenId: esEnvio ? filterCopy.planta.id : 0,
            destinoId: esEnvio ? 0 : filterCopy.planta.id,
            numeroViaje: filterCopy.viaje?.name,
            viajeId: idUrl ?? filterCopy.viajeId,
            ...fechas
        };

        return filterCopy;
    }

    const search = async (filterCopy, idUrl) => {
        filterCopy = getFiltros(filterCopy, idUrl);

        const result = await api.filter(filterCopy);
        const racksEnviados = result.reduce((sum, e) => sum + e.racksEnviados, 0);
        const racksRecibidos = result.reduce((sum, e) => sum + e.racksRecibidos, 0);

        setTotalRacks({recibidos: racksRecibidos, enviados: racksEnviados});
        setGrid({data: result});
    };

    const onChange = async (name, value) => {
        setFiltro(produce(filtro, (draft) => {
            draft[name] = value
        }));

        if (name === "planta") {
            await search({...filtro, planta: value})
        }
    };

    const changeSection = async (section) => {
        const tabsCopy = tabs.map(s => s.id === section.id ? {...s, selected: true} : {...s, selected: false});
        const esEnvio = section.name === "enviosCargados" || section.name === "enviosVacios";
        const filterCopy = produce(filtro, (draft) => {
            draft.esEnvio = esEnvio;
            draft.tipo = section.tipo;
        });

        setFiltro(filterCopy);

        setSection({...section});
        setTabs(tabsCopy);
        setEsEnvio(esEnvio);

        await search(filterCopy);
    };

    const limpiarFiltros = async () => {
        const filterCopia = {...defaultFilter, esEnvio: filtro.esEnvio, tipo: filtro.tipo, visible: filtro.visible};
        setFiltro(filterCopia)
        await search(filterCopia);
    };

    const verDetalle = (id) => {
        setTemporalId(Math.random())
        setViajeId(id);
        setDetalleAbierto(true)
    };

    const abrirModalEliminar = (viajeId) => {
        setViajeId(viajeId);
        setModalEliminarAbierto(true);
    }

    const onSave = async () => {
        setModalEliminarAbierto(false);
        setDetalleAbierto(false);
        await search();
    };

    const descargarReporte = async () => {
        const titulosCeldas = {
            A1: t("Viaje"),
            B1: t("Estatus de Viaje"),
            C1: t("Fecha Estimada"),
            D1: t("Fecha Real"),
            E1: t("No Entrega"),
            F1: t("Origen"),
            G1: t("Destino"),
            H1: t("No Viaje"),
            I1: t("Transportista"),
            J1: t("Unidad"),
            K1: t("Estatus"),
            L1: t("Rack"),
            M1: t("Descripción de Rack"),
            N1: t("Tipo de Rack"),
            O1: t("Descripción de Tipo"),
            P1: t("Número de Factura"),
            Q1: t("Turno"),
        }

        const estatusViaje = {
            1: t("Guardado"),
            2: t("Programado"),
            3: t("En Operación"),
            4: t("Por Enviar"),
            5: t("En Tránsito"),
            6: t("Recibo Parcial"),
            7: t("Recibido"),
            8: t("En Tránsito 1"),
        };

        const fecha = moment(moment().toDate()).format("YYYYMMDDHHmm");
        const nombreReporte = `${t("Viajes")}_${fecha}`;
        const filtros = getFiltros();

        await api.reporte({...filtros, titulosCeldas, estatusViaje}, nombreReporte);
    }

    return (
        <>
            <Grid container className="no-print">
                <Grid display="flex" item xs={12} sm={6} md={4} gap={2} fontSize={17}>
                    <Box display="flex" sx={boxStyle}>
                        <Box pr={1}>{t('Viajes')}:</Box>
                        <Box><b>{grid.data.length}</b></Box>
                    </Box>
                    <Box display="flex" sx={boxStyle}>
                        <Box pr={1}>{t('Racks')}:</Box>
                        <Box><b>{totalRacks.recibidos}/{totalRacks.enviados}</b></Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4} textAlign="center" order={{xs: 3, md: 2}}>
                    <Typography variant="h6" fontWeight={700}>
                        {esPerfilSeguridad && t("Recepción de Viajes")}
                        <span style={{paddingLeft: '20px'}}>{filtro.planta?.name || ''}</span>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} textAlign="right" order={{xs: 2, md: 3}}>
                    <ButtonGroup variant="contained">
                        <Button color="success" startIcon={<Download/>} onClick={() => descargarReporte()}>
                            {t('Reporte')}
                        </Button>
                        <Button startIcon={<SearchIcon/>} onClick={() => search()}>
                            {t('Filtrar')}
                        </Button>
                        <Tooltip title={t(filtro.visible ? 'Ocultar Filtros' : 'MostrarFiltros')}>
                            <Button onClick={() => onChange("visible", !filtro.visible)}>
                                {filtro.visible ?
                                    <VisibilityOutlined sx={{fontSize: 18}}/>
                                    :
                                    <VisibilityOffOutlined sx={{fontSize: 18}}/>
                                }
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('Limpiar Filtros')}>
                            <Button onClick={limpiarFiltros}>
                                <ClearIcon sx={{fontSize: 18}}/>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>

            {/*TABS*/}
            {!esPerfilSeguridad &&
            <Grid container mb={filtro.visible ? 2 : 0} sx={{borderBottom: '1px solid #dee2e6'}} className="no-print">
                {tabs.map(e =>
                    <Grid key={e.id} item xs sm={4} md={3} lg={2}
                          style={e.selected ? {borderBottom: '2px solid green'} : {}}>
                        <Button size="small" onClick={() => changeSection(e)} sx={{width: '100%'}}
                                style={e.selected ? {} : {color: '#757575'}}>
                            <span>{t(e.title)}</span>
                        </Button>
                    </Grid>
                )}
            </Grid>
            }

            {/*FILTROS*/}
            {filtro.visible &&
            <Grid container spacing={2} alignItems="center" className="no-print">
                <FieldDropDown label={t('Planta')} sm={6} md={3} name="planta" options={user.plantas}
                               value={filtro.planta} onChange={onChange} disableClearable/>

                <FieldRangeDatePicker label={t('Fecha Estimada')} sm={6} md={3} name="fechaEstimada"
                                      value={filtro.fechaEstimada} onChange={onChange}/>

                <FieldRangeDatePicker label={t('Fecha Real')} sm={6} md={3} name="fechaReal"
                                      value={filtro.fechaReal} onChange={onChange}/>

                <FieldDropDownMultiselect label={t('Transportista')} sm={6} md={3} name="transportistasIds"
                                          source="transportistas"
                                          value={filtro.transportistasIds} onChange={onChange} valueById/>

                {esEnvio ?
                    <FieldDropDownMultiselect label={t('Destino')} sm={6} md={3} name="destinosIds" onChange={onChange}
                                              source="plantasDestino" value={filtro.destinosIds} valueById
                                              filter={{id: filtro.planta?.id}}/>
                    :
                    <FieldDropDownMultiselect label={t('Origen')} sm={6} md={3} name="origenesIds" source="plantas"
                                              value={filtro.origenesIds} onChange={onChange} valueById/>
                }

                <FieldAutoComplete label={t('No Entrega')} name={`viajeId`} value={filtro.viajeId} sm={6} md={2}
                                   source="viajesPorEntrega" valueById onChange={onChange}/>

                <FieldAutoComplete label={t('No Viaje')} name={`viaje`} value={filtro.viaje} sm={6} md={2}
                                   source="viajesPorNumero" onChange={onChange}/>

                <FieldDropDownMultiselect label={t('Estatus')} sm={6} md={2} name="estatusIds" source="estatus"
                                          value={filtro.estatusIds} onChange={onChange} valueById translate
                                          filter={{name: 'Viaje'}}/>

                <FieldAutoComplete label="Rack" name="rackId" value={filtro.rackId} sm={6} md={3}
                                   source="racks" valueById onChange={onChange}/>
            </Grid>
            }

            <TripContext.Provider value={{section, filter: filtro}}>
                {esPerfilSeguridad ?
                    <TripGridGuardHouse viajes={grid.data} esEnvio={esEnvio} verDetalle={verDetalle}/>
                    :
                    <TripGrid viajes={grid.data} esEnvio={esEnvio} verDetalle={verDetalle}
                              verEliminar={abrirModalEliminar}/>
                }
            </TripContext.Provider>

            {detalleAbierto &&
            <TripDetail viajeId={viajeId} origen={filtro.planta} open={detalleAbierto} temporalId={temporalId}
                        close={() => setDetalleAbierto(false)} onSave={onSave} section={section}
                        isReadonly={isReadonly}/>
            }

            <TripConfirm viajeId={viajeId} close={() => setModalEliminarAbierto(false)}
                         open={modalEliminarAbierto} onSave={onSave} tipo="eliminar"/>
        </>
    );
}
