/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const ExCell =  ({cellRenderer,cellControl, field, row, data, rowIndex, onChange, tableName, ...props}) => {

    const onCellChanged = (value) => {
        onChange(field, value);
    }

    return (
        <>
            {
                cellControl ? React.cloneElement(cellControl, {value:row[field], onChange:onCellChanged, field, rowIndex, tableName, ...cellControl.props}, null) :
                    (
                        cellRenderer ?
                            cellRenderer({field, row, data, rowIndex})
                            :
                            row[field]
                    )
            }
        </>
    )
}

export default ExCell;