/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from "react";
import * as qs from "qs";
import useApiSource from '../../../api/configuration/useApiSource'
import {CircularProgress, Grid, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {Search} from "@mui/icons-material";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import {isString} from "../../../utility/objectUtility";
import {useTranslation} from "react-i18next";

const FieldAutoComplete = ({
                               xs, sm, md, lg, xl, label, name, source, onChange, value, error, params, width,
                               valueById, disabled, required, minLength, freeSolo, variant
                           }) => {

    const {t} = useTranslation();
    const {readOnlyContext} = useContext(ReadOnlyContext);
    const [readOnly, setReadOnly] = useState(false);
    const {errors} = useContext(ValidationContext);

    const [selectedOption, setSelectedOption] = useState({id: 0, name: ''});
    //const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const {allDisabled} = useContext(ValidationContext);

    const apiSource = useApiSource();

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext?.current]);


    async function searchOption(event) {
        const value = (event.target.value ?? '').trim();
        if (value.length < (minLength ?? 2)) {
            return;
        }

        let query = `${value}${params == null ? '' : `?${qs.stringify(params)}`}`;
        setLoading(true);
        const result = await apiSource.search(source, query);
        setLoading(false);
        if (result != null) {
            setFilteredOptions(result);
        }
    }

    useEffect(() => {
        updateSelectedOption(value).then();
    }, [value]);

    function onSelectedItem(event, value) {
        setFilteredOptions([]);
        setSelectedOption(value ?? {id: 0, name: ''});
        fireChange(name, value);
    }

    function fireChange(itemName, itemValue) {
        //clearTimeout(timer);
        if (onChange != null) {
            const value = itemValue == null ? null : (valueById ? itemValue.id : itemValue);
            onChange(itemName, value, itemValue)
        }
    }

    const updateSelectedOption = async (value) => {
        const emptyString = isString(value) && value.trim() !== '';
        if (value != null && !isNaN(value) && valueById && selectedOption?.id !== value && !emptyString) {
            const option = await apiSource.getById(source, value);
            setFilteredOptions([option]);
            setSelectedOption(option ?? {id: 0, name: ''});
            return;
        }

        if ((!valueById && selectedOption?.id !== value?.id) || !value) {
            setSelectedOption(value ?? {id: 0, name: ''});
        }
    }

    const onInputChange = (event, value, reason) => {

        setInputValue(value);

        if (freeSolo !== true)
            return;

        if (value !== selectedOption?.name) {
            fireChange(name, null);
        }
    }

    const onBlur = () => {
        if (freeSolo !== true)
            return;

        if (inputValue === selectedOption?.name) {
            return;
        }

        onChange(name, valueById ? inputValue : {id: inputValue});

    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{t(label)}</label>
                }
                <Autocomplete
                    id="asynchronous-demo"
                    freeSolo={freeSolo}
                    style={{width: width ?? '100%'}}
                    className={`${readOnly ? 'disabled' : ''} ${(error || errors?.[name]) ? 'error' : ''}`}
                    value={selectedOption}
                    inputValue={inputValue}
                    getOptionLabel={(option) => option?.name}
                    filterOptions={(options, state) => filteredOptions}
                    options={filteredOptions}
                    onChange={onSelectedItem}
                    onInputChange={onInputChange}
                    onBlur={onBlur}
                    loading={loading}
                    disabled={readOnly || allDisabled}
                    popupIcon={<Search/>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={variant || "outlined"}
                            value={selectedOption?.name}
                            onChange={searchOption}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        </>
    )
};


export default FieldAutoComplete;

