import React, {useState, Suspense} from 'react';
import './App.css';
import {ConfirmContext} from "./components/shared/Confirm";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './components/shared/fields/fields.css';
import {ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from "notistack";
import theme from "./materialTheme"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AuthProvider from "./components/security/AuthProvider";
import Main from "./components/Main";
import {BrowserRouter} from "react-router-dom";
import InMaintenance from "./components/navigation/InMaitenance";

function App() {
    const inMaintenance = process.env.REACT_APP_EN_MANTENIMIENTO === "true";
    const [confirmContext, setConfirmContext] = useState({
        open: false,
        alert: {
            open: false,
            messages: [],
            severity: '',
        },
        loading: {
            open: false,
        },
    })

    return (
        <Suspense fallback="loading">
            {!inMaintenance ?
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SnackbarProvider maxSnack={3}>
                            <ConfirmContext.Provider value={{confirmContext, setConfirmContext}}>
                                <AuthProvider>
                                    <BrowserRouter>
                                        <div className="App">
                                            <Main/>
                                        </div>
                                    </BrowserRouter>
                                </AuthProvider>
                            </ConfirmContext.Provider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
                :
                <InMaintenance/>
            }
        </Suspense>
    );
}

export default App;
