import * as yup from "yup";

const carrierValidationSchema = yup.object().shape({
    name: yup.string().trim().required()
});

export default carrierValidationSchema



