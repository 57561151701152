/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

export default function InputClipboardFile({title, color, variant, size, icon, accept, onChange}) {
    const {t} = useTranslation();
    const inputEl = useRef(null);

    const handleUpload = (event) => {
        const file = event.target.files[0];
        const components = file.name.split('.');

        onChange({
            isEmpty: false,
            file: file,
            nombre: file.name,
            extension: components[components.length - 1]
        });

        inputEl.current.value = "";
    }

    const onClick = () => {
        inputEl.current.click();
    };

    return (
        <>
            <Button variant={variant || "contained"} color={color || "success"} size={size || "small"} startIcon={icon}
                    onClick={onClick}>
                {t(title)}
            </Button>
            <input ref={inputEl} style={{display: 'none'}} type="file" onChange={handleUpload}
                   accept={accept || ".gif,.jpg,.jpeg,.png,.pdf,.xlsx"}/>
        </>
    )
};
