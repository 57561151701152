import {useSnackbar} from "notistack";

const useNotification = () => {
    const { enqueueSnackbar } = useSnackbar();
    return {
        success: ( message) => {
            enqueueSnackbar( message, {variant :'success', anchorOrigin: { vertical: 'top', horizontal: 'right'}});
        },
        error: ( message) => {
            enqueueSnackbar( message, {variant :'error', anchorOrigin: { vertical: 'top', horizontal: 'right'}});
        },
        warning: ( message) => {
            enqueueSnackbar( message, {variant :'warning', anchorOrigin: { vertical: 'top', horizontal: 'right'}});
        },
        info: ( message) => {
            enqueueSnackbar( message, {variant :'info', anchorOrigin: { vertical: 'top', horizontal: 'right'}});
        },
    }
}

export default useNotification
