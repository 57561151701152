
export const removeAtIndex = (array, index ) => {
    return array.filter((e,i)=> i !== index);
}

export const removeFromArray = (array, func ) => {
    return array.filter(e=> !func(e));
}

export const updateAtIndex = (array, index, newItem ) => {
    return array.map((e,i)=> i === index ? {...newItem } : e);
}

export const updateArray = (array, newItem, func ) => {
    const index = array.findIndex(e=> func(e, newItem));
    if (index >= 0 ){
        return updateAtIndex(array, index, newItem);
    }
    return [...array, {...newItem}];
}

export const sumArrayBy = (array, fields) => {
    const isArray = Array.isArray(fields);
    fields = isArray? fields : [fields];

    const result = [];
    for (let i = 0; i < fields.length; i++){
        result.push(0);
    }

    for(let item of array) {
        for (let i = 0; i < fields.length; i++){
            result[i] += (item[fields[i]]??0);
        }
    }
    return isArray ? result : result[0];
}