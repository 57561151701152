/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button, Tooltip} from "@mui/material";
import useApiViaje from "../../../../api/operation/useApiViaje";
import TripConfirm from "../TripConfirm";
import CloseIcon from "@mui/icons-material/Close";
import DialogUi from "../../../shared/modal/Dialog";
import {Check} from "@mui/icons-material";
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import IconButton from "@mui/material/IconButton";
import {VIAJE_ESTATUS} from "../../../../utility/constants";

export default function TripReceive({data, setValue, esCaseta}) {
    const {t} = useTranslation();
    const api = useApiViaje();
    const recibido = esCaseta ? data.recibidoEnCaseta : data.recibidoEnPlanta;
    const puedeRecibir = [VIAJE_ESTATUS.guardado, VIAJE_ESTATUS.programado, VIAJE_ESTATUS.enOperacion, VIAJE_ESTATUS.porEnviar].includes(data.estatus);
    const puedeConfirmar = [VIAJE_ESTATUS.enTransito, VIAJE_ESTATUS.recibido].includes(data.estatus);

    const titulo = recibido ? "Cancelar Recibo" : "Recibir Unidad";
    const [modalConfirmar, setModalConfirmar] = useState({recibir: false, cancelar: false});

    const abrirConfirm = () => {
        const modalAAbrir = recibido ? "cancelar" : "recibir";
        setModalConfirmar({...modalConfirmar, [modalAAbrir]: true});
    }

    const cerrarConfirm = (recibir) => {
        const modalACerrar = recibir ? "recibir" : "cancelar";
        setModalConfirmar({...modalConfirmar, [modalACerrar]: false});
    }

    const onSaveRecibo = (recibir) => {
        cerrarConfirm(recibir);
        setValue(recibir);
    }

    const recibir = async () => {
        if (esCaseta)
            await api.recibirEnCaseta(data.id);
        else
            await api.recibirEnPlanta(data.id);

        onSaveRecibo(true);
    }

    return (
        <>
            {puedeRecibir && (!recibido || !data.seCanceloRecibo) &&
            <Tooltip title={t(titulo)}>
                <IconButton onClick={abrirConfirm} color={recibido ? "primary" : "disabled"} size="small">
                    {<LightbulbIcon fontSize="inherit"/>}
                </IconButton>
            </Tooltip>
            }

            {puedeConfirmar && (!recibido || !data.seCanceloRecibo) &&
            <Tooltip title={t(titulo)}>
                <IconButton onClick={abrirConfirm} color={recibido ? "complete" : "disabled"} size="small">
                    {<LightbulbIcon fontSize="inherit"/>}
                </IconButton>
            </Tooltip>
            }

            {modalConfirmar.cancelar &&
            <TripConfirm viajeId={data.id} open={modalConfirmar.cancelar}
                         tipo={esCaseta ? "cancelarReciboCaseta" : "cancelarReciboPlanta"}
                         close={() => cerrarConfirm(false)}
                         onSave={() => onSaveRecibo(false)}
            />
            }

            {modalConfirmar.recibir &&
            <DialogUi open={modalConfirmar.recibir} title={"Recibir"} width="sm" scroll="paper"
                      onClose={() => cerrarConfirm(true)}>
                <div>
                    {`${t("Se recibirá la caja con numero de entrega")}: ${data.numeroEntrega}. ${t("¿Desea Continuar?")}`}
                </div>

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>}
                            onClick={() => cerrarConfirm(true)}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="success" size="small" startIcon={<Check/>}
                            onClick={recibir}>
                        {t("Recibir")}
                    </Button>
                </div>
            </DialogUi>
            }
        </>
    );
}
