/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import produce from "immer";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Checkbox, Grid, Tab, Tabs, Collapse, InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {newGuid, setDeepValue} from "../../../utility/objectUtility";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import useApiPlanta from "../../../api/catalogs/useApiPlanta";
import plantValidationSchema from "./plantValidation";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import FieldDropDown from "../../shared/fields/FieldDropDown";
import TabPanel from "../../shared/TabPanel";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import useMessage from "../../../hooks/useMessage";
import {removeAtIndex} from "../../../utility/arrayUtility";
import useNotification from "../../../hooks/useNotification";
import {MENUS_TIPO_OPCION as opciones} from "../../../utility/constants";
import {UserContext} from "../../security/AuthProvider";
import PlantDestiny from "./PlantDestiny";
import PlantWorkShift from "./PlantWorkShift";

export default function PlantDetail({plantaId, open, close, onSave, temporalId, plantas}) {
    const defaultEntity = {
        id: 0,
        activo: true,
        destinos: [],
        esCliente: true,
        almacenes: [],
        turnos: [{turno: 1, activo: false}, {turno: 2, activo: false}, {turno: 3, activo: false}]
    };
    const api = useApiPlanta();
    const {t} = useTranslation();
    const {user} = useContext(UserContext);
    const esEditarDatosMaestros = user.menus.includes(opciones.edicionDatosMaestros);

    const [titulo, setTitulo] = useState('');
    const [planta, setPlanta] = useState(defaultEntity);
    const [errors, setErrors] = useState({});
    const [numeroTab, setNumeroTab] = React.useState(0);
    const [, setRepeatedArea] = useState(false);
    const [validate, clearValidation] = useValidate(plantValidationSchema, planta, setErrors);
    const message = useMessage();
    const notification = useNotification();

    useEffect(() => {
        const load = async (id) => {
            const result = await api.getById(id);

            //Del api solo se traen destinos seleccionados, estos se combinan con todos los destinos existentes
            const destinos = plantas.filter(e => e.id !== plantaId).map(e => {
                const destino = result.destinos.find(d => e.id === d.plantaId);
                const id = Math.floor(Math.random() * 1000000);
                return {
                    id: destino != null ? destino.id : -id,
                    name: e.name,
                    plantaId: e.id,
                    seleccionado: destino != null,
                    tiempoLlegada: destino?.tiempoLlegada,
                    ruta: destino?.ruta,
                    tipoId: destino?.tipoId,
                    tiempoAlertaCartaPorte: destino?.tiempoAlertaCartaPorte,
                }
            });

            const turnos = result.turnos.length === 0 ?
                [{turno: 1, activo: false}, {turno: 2, activo: false}, {turno: 3, activo: false}]
                : result.turnos;

            setPlanta({...result, destinos, turnos});
        }

        if (plantaId > 0) {
            setTitulo("Editar");
            load(plantaId).then();
        } else {
            const destinos = plantas.map(e => {
                const id = Math.floor(Math.random() * 1000000);
                return {id: -id, name: e.name, plantaId: e.id, seleccionado: false}
            });

            setTitulo("Agregar");
            setPlanta({...defaultEntity, destinos});
        }

        setNumeroTab(0);
        clearValidation();
    }, [temporalId]);

    const onChangeTab = (event, value) => {
        setNumeroTab(value);
    };

    const onChange = (name, value) => {
        const newEntity = produce(planta, (draft) => {
            setDeepValue(name, draft, value);
        });

        setPlanta(newEntity);
    };

    const onChangeActivo = (index, value) => {

        let nuevosAlmacenes = produce(planta.almacenes, (draft) => {
            draft.forEach((a, i) => {
                if (index === i) {
                    a.areas.forEach((b, j) => {
                        b.activo = value;
                        b.disableActivo = (!value)
                    })
                }

            })
            draft[index]["activo"] = value;
        });
        onChange("almacenes", nuevosAlmacenes)

    }

    async function save() {
        const valid = await validate();
        if (!valid) return;

        let existeAreaRepetido = false;
        let existeAlmacenRepetido = false;
        planta.almacenes.map((almacen, index) => {
            let repeatedAlmacen = planta.almacenes.some((r, i) => index !== i && almacen.nombre === r.nombre)
            if (repeatedAlmacen)
                existeAlmacenRepetido = true;

            almacen.areas.map((area, index2) => {
                let repeatedArea = almacen.areas.some((r, i) => index2 !== i && area.nombre === r.nombre)
                if (repeatedArea)
                    existeAreaRepetido = true;
                return true;
            })
            return true;
        })

        if (existeAreaRepetido) {
            notification.warning(t("Existen areas repetidas, favor de verificar la información"))
            return
        }

        if (existeAlmacenRepetido) {
            notification.warning(t("Existen almacenes repetidas, favor de verificar la información"))
            return
        }

        await api.save({...planta, destinos: planta.destinos.filter(e => e.seleccionado)});
        onSave();
    }

    function addAlmacen() {
        onChange('almacenes', [
            {
                _guid: newGuid(),
                nombre: "",
                activo: true,
                expandido: true,
                esDefault: false,
                areas: []
            }, ...planta.almacenes])
    }

    function addArea(index) {
        onChange(`almacenes[${index}].areas`, [{
            _guid: newGuid(),
            nombre: "",
            activo: true,
            esDefault: false,
        }, ...planta.almacenes[index].areas])

    }

    function onChangeNombreArea(index, name, value) {
        const areaRepetida = planta.almacenes.some((e, i) => i !== index && e.nombre === value);

        const areasCopy = planta.almacenes.map((e, i) => {
                return i === index ? {...e, nombre: value, repeated: areaRepetida} : {...e, repeated: false};
            }
        );

        setRepeatedArea(areasCopy.some(e => e.repeated));
        onChange(name, value);
    }

    function onChangeEsDefault(esAlmacen, index, indexDetail, name, value) {

        if (esAlmacen) {
            let defaultArea = false;
            if (value && planta.almacenes[index].areas.length === 1)
                defaultArea = true
            let nuevosAlmacenes = produce(planta.almacenes, (draft) => {
                draft.forEach((a, i) => {
                    a.esDefault = false;
                    a.areas.forEach((b, j) => {
                        b.esDefault = (index === i) ? defaultArea : false;
                    })
                })
                draft[index][name] = value;
            });
            onChange("almacenes", nuevosAlmacenes)
        } else {

            let nuevosAlmacenes = produce(planta.almacenes, (draft) => {
                draft.forEach((a, i) => {
                    a.esDefault = false;
                    a.areas.forEach((b, j) => {
                        if (index === i && indexDetail === j)
                            b.esDefault = value;
                        else
                            b.esDefault = false;
                    })
                })
                draft[index][name] = value;
            });
            onChange("almacenes", nuevosAlmacenes)
        }
    }


    async function deleteAlmacen(index) {
        let response = await message.confirmReject(t("¿Está seguro que desea eliminar el almacen?"), t("¿Desea Continuar?"));
        if (response === true) {
            onChange(`almacenes`, removeAtIndex(planta.almacenes, index))
        }
    }

    async function deleteArea(index, indexDetail) {
        let response = await message.confirmReject(t("¿Está seguro que desea eliminar el área?"), t("¿Desea Continuar?"));
        if (response === true) {
            if (planta.almacenes[index].areas.length === 1) {
                onChange(`almacenes`, removeAtIndex(planta.almacenes, index))
            } else
                onChange(`almacenes[${index}].areas`, removeAtIndex(planta.almacenes[index].areas, indexDetail))

        }
    }

    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Planta")}`} open={open} close={close} size={900}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Tabs value={numeroTab} onChange={onChangeTab}>
                        <Tab label={t("Información General")}/>
                        <Tab label={t("Destinos")}/>
                        <Tab label={t("Almacenes y áreas")}/>
                    </Tabs>

                    <TabPanel value={numeroTab} index={0}>
                        <Grid container spacing={2}>
                            <FieldTextBox label="Nombre" name="name" value={planta.name} xs={8} onChange={onChange}/>

                            <FieldTextBox label="Abreviación" name="abreviacion" value={planta.abreviacion}
                                          xs={2} onChange={onChange}/>

                            <FieldCheckBox label="Activo" name="activo" value={planta.activo} xs={2}
                                           onChange={onChange} mt={3}/>

                            <FieldDropDown label="Zona Horaria" xs={8} name="zonaHorariaId" source="zonasHorarias"
                                           valueById value={planta.zonaHorariaId} onChange={onChange}/>

                            <FieldCheckBox label="Cliente" name="esCliente" value={planta.esCliente} xs={3}
                                           onChange={onChange} mt={3}/>

                        </Grid>

                        <div className="subtitle">
                            {t("Dirección")}
                        </div>

                        <Grid container spacing={2}>
                            <FieldTextBox label="Calle y Número" name="calle" value={planta.calle}
                                          xs={8} onChange={onChange} maxLength={100}/>

                            <FieldTextBox label="Código Postal" name="codigoPostal" value={planta.codigoPostal}
                                          xs={4} onChange={onChange} maxLength={10}/>

                            <FieldDropDown label="País" xs={4} name="paisId" source="paises"
                                           valueById value={planta.paisId} onChange={onChange}/>

                            <FieldTextBox label="Estado" name="estado" value={planta.estado}
                                          xs={4} onChange={onChange}/>

                            <FieldTextBox label="Municipio" name="municipio" value={planta.municipio}
                                          xs={4} onChange={onChange}/>
                        </Grid>

                        <div className="subtitle">
                            {t("Contacto")}
                        </div>

                        <Grid container spacing={2}>
                            <FieldTextBox label="Nombre" name="contacto" value={planta.contacto}
                                          xs={5} onChange={onChange}/>

                            <FieldTextBox label="Correo" name="email" value={planta.email}
                                          xs={4} onChange={onChange}/>

                            <FieldTextBox label="Teléfono" name="telefono" value={planta.telefono}
                                          xs={3} onChange={onChange} maxLength={30}/>
                        </Grid>

                        <div className="subtitle">
                            {t("Configuración")}
                        </div>

                        <FieldCheckBox label="Impresión de Pro-Forma al enviar viaje" name="imprimirProforma"
                                       value={planta.imprimirProforma} onChange={onChange}/>

                        <FieldCheckBox label="Impresión de Packing List al enviar viaje" name="imprimirPackingList"
                                       value={planta.imprimirPackingList} onChange={onChange}/>

                        <Grid container spacing={2} mt={1}>
                            <FieldTextBox label="Proveedor Logístico" name="correoProveedor" xs={12} onChange={onChange}
                                          value={planta.correoProveedor} disabled={!esEditarDatosMaestros}
                                          maxLength={200}/>
                        </Grid>
                        <Box sx={{fontSize: 11}}>{t('InstruccionesCorreo')}</Box>

                        {/*TURNOS*/}
                        <PlantWorkShift planta={planta} onChange={onChange}/>
                    </TabPanel>

                    <TabPanel value={numeroTab} index={1}>
                        <PlantDestiny planta={planta} onChange={onChange}/>
                    </TabPanel>

                    <TabPanel value={numeroTab} index={2}>

                        {t('Agregar Almacén')}
                        <IconButton color="primary" xs={1} onClick={() => {
                            addAlmacen()
                        }}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '90px'}}/>
                                    <TableCell sx={{width: '220px'}}/>
                                    <TableCell sx={{width: '100px'}}>{t("Almacén principal")}</TableCell>
                                    <TableCell sx={{width: '100px'}}>{t("Activo")}</TableCell>
                                    <TableCell sx={{width: '120px'}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {planta.almacenes?.map((almacen, index) => (
                                    <>
                                        <TableRow key={index}>
                                            <TableCell>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        onChange(`almacenes[${index}].expandido`, !almacen.expandido)
                                                    }}>
                                                    {almacen.expandido ? <KeyboardArrowUpIcon/> :
                                                        <KeyboardArrowDownIcon/>}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>

                                                <FieldTextBox
                                                    name={`almacenes[${index}].nombre`}
                                                    value={almacen.nombre}
                                                    variant="standard"
                                                    onChange={(name, value) => {
                                                        onChange(`almacenes[${index}].nombre`, value)
                                                    }}/>
                                            </TableCell>
                                            <TableCell align={"center"} sx={{width: '100px'}}>
                                                <Checkbox checked={almacen.esDefault}
                                                          onChange={(e, value) => {
                                                              //onChange(`almacenes[${index}].esDefault`, value)
                                                              onChangeEsDefault(true, index, -1, 'esDefault', value);
                                                          }}
                                                />
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <Checkbox checked={almacen.activo}
                                                          onChange={(e, value) => {
                                                              //onChange(`almacenes[${index}].activo`, value)
                                                              onChangeActivo(index, value)
                                                          }}
                                                />
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <IconButton color="primary" xs={1} onClick={() => {
                                                    addArea(index)
                                                }}>
                                                    <AddCircleOutlineIcon/>
                                                </IconButton>

                                                <IconButton color="error" disabled={almacen.deshabilitaEliminar}
                                                            onClick={() => deleteAlmacen(index)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{padding: 0}} colSpan={5}>
                                                <Collapse in={almacen.expandido} timeout="auto" unmountOnExit>
                                                    <Table size="small">
                                                        <TableBody sx={{'tr:last-child td': {borderBottom: 0}}}>
                                                            {almacen.areas?.map((area, indexDetail) => (
                                                                <>
                                                                    <TableRow key={indexDetail}>
                                                                        <TableCell sx={{width: '90px'}}>

                                                                        </TableCell>
                                                                        <TableCell sx={{width: '218px'}}>
                                                                            <FieldTextBox value={area.nombre}

                                                                                          variant="standard"
                                                                                          error={area.repeated}
                                                                                          name={`almacenes[${index}].areas[${indexDetail}].nombre`}
                                                                                          onChange={(name, value) => {
                                                                                              onChangeNombreArea(indexDetail, name, value)
                                                                                          }}
                                                                                          helperText={area.repeated ? t("El nombre del area esta repetido") : ''}
                                                                                          startAdornment={
                                                                                              <InputAdornment
                                                                                                  position="start"
                                                                                                  sx={{pb: 0.1}}>
                                                                                                  {almacen.nombre}-</InputAdornment>}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '100px'}}
                                                                                   align={"center"}>
                                                                            <Checkbox checked={area.esDefault}
                                                                                      onChange={(e, value) => {
                                                                                          //onChange(`almacenes[${index}].areas[${indexDetail}].esDefault`, value)
                                                                                          onChangeEsDefault(false, index, indexDetail, 'esDefault', value);
                                                                                      }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '100px'}}
                                                                                   align={"center"}>
                                                                            <Checkbox checked={area.activo}
                                                                                      disabled={area.disableActivo}
                                                                                      onChange={(e, value) => {
                                                                                          onChange(`almacenes[${index}].areas[${indexDetail}].activo`, value)
                                                                                      }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '120px'}}
                                                                                   align={"right"}>

                                                                            <IconButton color="error"
                                                                                        disabled={area.deshabilitaEliminar}
                                                                                        onClick={() => deleteArea(index, indexDetail)}>
                                                                                <DeleteIcon/>
                                                                            </IconButton>
                                                                        </TableCell>


                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}

                            </TableBody>
                        </Table>

                    </TabPanel>
                </ValidationContext.Provider>

                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>

                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
