/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import produce from "immer";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Tab, Tabs} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {setDeepValue} from "../../../utility/objectUtility";
import {ValidationContext} from "../../shared/validationContext";
import SendIcon from '@mui/icons-material/Send';
import ReplayIcon from '@mui/icons-material/Replay';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
    VIAJE_ESTATUS as estatusViaje,
    VIAJE_TIPO as tipoViaje,
    TIPOS_TRANSPORTE as tiposTransporte,
    TIPOS_ENVIO as tiposEnvio,
    INCOTERMS as incoterms, PERFILES_IDS, MENUS_TIPO_OPCION as opciones
} from "../../../utility/constants";
import useApiViaje from "../../../api/operation/useApiViaje";
import viajeSchema from "./TripValidation";
import groupBy from 'lodash/groupBy';
import useMessage from "../../../hooks/useMessage";
import PrintTrip from "./print/PrintTrip";
import TripRacks from "./TripRacks";
import {Check, Print} from "@mui/icons-material";
import TripConfirm from "./TripConfirm";
import TripDetailGeneral from "./TripDetalGeneral";
import TabPanel from "../../shared/TabPanel";
import {formatDate} from "../../../utility/dateUtility";
import {UserContext} from "../../security/AuthProvider";
import useNotification from "../../../hooks/useNotification";
import TripComments from "./TripComments";

export default function TripDetail({viajeId, section, origen, open, close, onSave, temporalId, isReadonly}) {
    const defaultEntity = {
        id: 0,
        tipoTransporte: tiposTransporte.camion,
        tipoEnvio: tiposEnvio.comercial,
        incoterm: incoterms.dap,
        esParteRelacionada: true,
        requerimientos: [],
        tiposRack: [],
        materiales: [],
        ocupacionRacks: 0,
        ocupacionMateriales: 0,
        esDobleDestino: false
    };

    
    const esEnvio = section.name === "enviosCargados" || section.name === "enviosVacios";
    const esViajeCargado = section.tipo === tipoViaje.cargado;
    const esRecepcion = section.name === "recepcionesCargadas" || section.name === "recepcionesVacias";

    const {user} = useContext(UserContext);
    const perfilesEdicionPedimento = [PERFILES_IDS.administrador, PERFILES_IDS.edicionDatosMaestros];
    const perfilResponsableProductoTerminado = [PERFILES_IDS.responsableProductoTerminado];
    const esResponsableProductoTerminado = perfilResponsableProductoTerminado.includes(user.profileId);
    const puedeEditarPedimento = perfilesEdicionPedimento.includes(user.profileId);
    
    const esDataMaster = user.menus.includes(opciones.edicionDatosMaestros);
    const esProveedorLogistico = user.menus.includes(opciones.proveedorLogistico);
    const {t} = useTranslation();
    const message = useMessage();
    const api = useApiViaje();
    const notification = useNotification();

    const [titulo, setTitulo] = useState('');
    const [viaje, setViaje] = useState(defaultEntity);
    const [camposDeshabilitados, setCamposDeshabilitados] = useState(false);
    const [proveedorLogisticoHabilitado, setProveedorLogisticoHabilitado] = useState(true);
    const [impresionAbierta, setImpresionAbierta] = useState(false);
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(viajeSchema, viaje, setErrors);
    const [modalCompletarAbierto, setModalCompletarAbierto] = useState(false);
    const [viajeLog, setViajeLog] = React.useState([]);
    const [numeroTab, setNumeroTab] = React.useState(0);
    const [esPlantaOrigen, setEsPlantaOrigen] = React.useState(false);
    const [puedeRegresarAOrigen, setPuedeRegresarAOrigen] = React.useState(false);

    useEffect(() => {
        const load = async (id) => {
            let nuevoViaje;

            if (id > 0) {
                setTitulo("Editar Envío");
                nuevoViaje = await api.getById(id);
                nuevoViaje.tiposRack = getTiposRack(nuevoViaje.racks);
                getResumenRacks(nuevoViaje);

                nuevoViaje.materiales.forEach(e => {
                    e.deshabilitado = e.origenId !== 0 && e.origenId !== origen.id;
                })

                const plantaOrigen = nuevoViaje.origenId === origen.id;

                setPuedeRegresarAOrigen(!plantaOrigen && nuevoViaje.enTransito1 && esEnvio);
                setEsPlantaOrigen(plantaOrigen);
            } else {
                setTitulo("Agregar Envío");
                nuevoViaje = {...defaultEntity, creadoEnDestino: esRecepcion};

                //El origen es la planta que se selecciono en los filtros, si es una recepción se refiere a el destino
                if (esEnvio) nuevoViaje.origenId = origen.id;
                else {
                    nuevoViaje.destinoId = origen.id;
                }
            }

            nuevoViaje.section = section.name;
            nuevoViaje.esRecepcion = esRecepcion;

            setViaje(nuevoViaje);
            setNumeroTab(0);
            deshabilitarCampos(nuevoViaje);
        }

        clearValidation();
        load(viajeId).then();

    }, [temporalId]);


    //Si el viaje no se creo en destino y esta en tránsito o se ve desde alguna pantalla de recepción (!esEnvio),
    // se deshabilitan los campos
    const deshabilitarCampos = (viaje) => {
        const esCreadoEnDestino = (esRecepcion && viaje.id === 0) || viaje.creadoEnDestino;
        const plantaOrigen = viaje.origenId === origen.id;

        const disabled = (esEnvio && !plantaOrigen)
            || viaje.recibido
            || (!esCreadoEnDestino && (viaje.enTransito || !esEnvio))
            || viaje.enTransito1;

        const disabledFields = !viaje.puedeEditarViajeIncompleto
            && (isReadonly || disabled);

        setProveedorLogisticoHabilitado(isReadonly && !disabled && esProveedorLogistico);

        setCamposDeshabilitados(disabledFields);
    };

    const getTiposRack = (racks) => {
        const tiposRack = [];
        const tiposRackIds = groupBy(racks, "tipoRackId");

        for (const tipoRackId in tiposRackIds) {
            const primerRack = tiposRackIds[tipoRackId][0];
            const racksAgrupados = tiposRackIds[tipoRackId];
            const cantidad = racksAgrupados.reduce((sum, e) => sum + e.cantidad, 0);
            const cantidadRecibida = racksAgrupados.reduce((sum, e) => sum + e.cantidadRecibida, 0);
            const id = Number(tipoRackId);

            tiposRack.push({
                id,
                name: primerRack.tipoRack,
                largo: primerRack.largo,
                ancho: primerRack.ancho,
                alto: primerRack.alto,
                peso: primerRack.peso,
                pesoTotal: primerRack.peso * cantidad,
                cantidad,
                cantidadRecibida,
                racks: racksAgrupados,
                expandido: true
            })
        }

        return tiposRack;
    };

    const getResumenRacks = (viaje) => {
        viaje.racksRecibidosCorrectos = 0;
        viaje.racksNoCargados = 0;
        viaje.racksNoRecibidos = 0;

        if (!viaje.recibido) return;

        viaje.racks.forEach(e => {
            const hayDiferenciaEnvio = e.cantidadRecibida > e.cantidad;

            viaje.racksNoCargados += hayDiferenciaEnvio ? (e.cantidadRecibida - e.cantidad) : 0;
            viaje.racksRecibidosCorrectos += hayDiferenciaEnvio ? e.cantidad : e.cantidadRecibida;
            viaje.racksNoRecibidos += hayDiferenciaEnvio ? 0 : (e.cantidad - e.cantidadRecibida);
        });
    }

    const onChangeTab = async (event, value) => {
        setNumeroTab(value);

        //Log
        if (value === 2 && viaje.id > 0) {
            const result = await api.getLog(viaje.id);
            setViajeLog(result.log);
        }
    };

    const onChange = (name, value) => {
        const viajeCopia = produce(viaje, (draft) => {
            if (name === "esDobleDestino" && !value) {
                setDeepValue("plantaIntermediaId", draft, 0);
            }

            setDeepValue(name, draft, value);
        });

        setViaje(viajeCopia);
    };

    async function save(programar, enviaraTransito) {
        const valid = await validate();
        if (!valid) return;

        if (viaje.esDobleDestino && viaje.plantaIntermediaId === viaje.destinoId) {
            notification.error("El destino no puede ser el mismo que la planta intermedia");
            return;
        }

        const origenId = esRecepcion ? viaje.origenId : origen.id;
        const viajeCopia = {...viaje, origenId, programar, enviaraTransito, esViajeCargado}

        if (enviaraTransito) {
            setViaje(viajeCopia);
            setImpresionAbierta(true);
        } else {
            viajeCopia.numeroPedimento = (viajeCopia.numeroPedimento != null && viajeCopia.numeroPedimento != "") ? viajeCopia.numeroPedimento.toString() : "";
            await api.save(viajeCopia);
            onSave();
        }
    }

    async function actualizarDatosLogisticos() {
        const valid = await validate();
        if (!valid) return;

        await api.actualizarDatosLogisticos(viaje.id,{
                numeroViaje: viaje.numeroViaje,
                transportistaId: viaje.transportista.id,
                unidad:viaje.unidad
            }
        );
        onSave();
    }


    const recibir = async () => {
        let racks = [];

        viaje.tiposRack.forEach(e => {
            const newRacks = e.racks.map(r => ({id: r.id, recibido: r.recibido, cantidadRecibida: r.cantidadRecibida}));
            racks = [...racks, ...newRacks]
        })

        await api.recibir({...viaje, racks});
        onSave();
    }

    const regresaraEnOperacion = async () => {
        await message.confirm(t("Se regresará a Operación"), t("¿Desea Continuar?"))

        await api.regresaraEnOperacion(viaje.id);
        onSave();
    }

    const regresaraPorEnviar = async () => {
        const titulo = puedeRegresarAOrigen ? "Se regresará el viaje al origen" : "Se regresará a estatus Por Enviar";
        await message.confirm(t(titulo), t("¿Desea Continuar?"))

        await api.regresaraPorEnviar(viaje.id);
        onSave();
    }

    const enviaraTransito = async () => {
        if (esEnvio) {
            await save(false, true);
        } else {
            await message.confirm(t("Se enviará a Transito"), t("¿Desea Continuar?"))
            await api.regresaraTransito(viaje.id, user.profileId);
            onSave();
        }
    }

    const cerrarModalImpresion = () => {
        setImpresionAbierta(false);
    }

    const sePuedeGuardar = () => {
        return !isReadonly
            && (viaje.id === 0 || esRecepcion || esPlantaOrigen)
            && !viaje.recibido
            && !viaje.enTransito1
            && ((esEnvio && !viaje.enTransito)
                || (esRecepcion && viaje.creadoEnDestino));
    }

    const sePuedeRecibir = () => {
        return !isReadonly
            && !esEnvio
            && !viaje.creadoEnDestino
            && (viaje.enTransito || viaje.estatus === estatusViaje.reciboParcial)
    }

    const sePuedeRegresaraPorEnviar = () => {
        return !isReadonly
            && !viaje.recibido
            && (!viaje.enTransito1 || (esPlantaOrigen && !viaje.estaEnPlantaIntermedia));
    }

    const sePuedeEnviarTransito = () => {
        return !isReadonly
            && !viaje.esIncompleto
            && (esRecepcion || esPlantaOrigen)
            && ((viaje.porEnviar && esEnvio) || (viaje.estatus === estatusViaje.reciboParcial && esRecepcion) || 
            ((viaje.estatus === estatusViaje.reciboParcial || viaje.estatus === estatusViaje.recibido) && esResponsableProductoTerminado))
            
    }

    const habilitarPedimento = () => {
        return esEnvio
            && !viaje.esIncompleto
            && esPlantaOrigen
            && (
                ((esDataMaster || esResponsableProductoTerminado) && (viaje.enTransito || viaje.recibido))
                || (puedeEditarPedimento && viaje.pedimento === "" && viaje.enTransito && viaje.alertasPedimento > 0)
            );
    }

    const guardarPedimento = async () => {
        if (!!!viaje.numeroPedimento) {
            setViaje({...viaje, errorPedimento: true});
            notification.error(t('Favor de revisar la información requerida o inválida'));
            return;
        }
        await api.savePedimento(viajeId, viaje.numeroPedimento);

        onSave();
    }

    const agregarMateriales = async () => {
        const valid = await validate();
        if (!valid) return;

        await api.agregarMateriales(viajeId, {materiales: viaje.materiales.filter(e => !e.deshabilitado)});
        onSave();
    }

    const abrirConfirmCompletar = async () => {
        const valid = await validate();
        if (!valid) return;

        await message.confirm(t("Se completará el viaje"), t("¿Desea Continuar?"))

        const numeroPedimento = !viaje.numeroPedimento ? "" : viaje.numeroPedimento.toString();
        await api.completarViajeIncompleto({...viaje, numeroPedimento});

        onSave();
    }

    return (
        <>
            <SidebarRight title={t(titulo)} open={open} close={close} size={700}>
                {/*FORMA PRINCIPAL*/}
                <ValidationContext.Provider
                    value={{
                        errors,
                        setErrors,
                        allDisabled: !viaje.puedeEditarViajeIncompleto && (viaje.recibido || isReadonly)
                    }}>
                    <Tabs value={numeroTab} onChange={onChangeTab}>
                        <Tab label={t("Información General")}/>
                        <Tab label={`${t("Racks")}/${t("Materiales")}`}/>
                        <Tab label={t("Log")} disabled={viaje.id === 0}/>
                        <Tab label={t("Comentarios")} disabled={viaje.id === 0}/>
                    </Tabs>

                    <TabPanel value={numeroTab} index={0}>
                        <TripDetailGeneral viaje={viaje} origen={origen} section={section} onChange={onChange}
                                           camposDeshabilitados={camposDeshabilitados}
                                           proveedorLogisticoHabilitado={proveedorLogisticoHabilitado}
                                           puedeEditarPedimento={habilitarPedimento()}/>
                    </TabPanel>

                    {/*RACKS CARGADOS*/}
                    <TabPanel value={numeroTab} index={1}>
                        <TripRacks viaje={viaje}
                                   setViaje={setViaje}
                                   section={section}
                                   onChange={onChange}
                                   viajeEnTransito={viaje.enTransito}
                                   viajeRecibido={viaje.recibido}
                                   camposDeshabilitados={camposDeshabilitados}
                                   isReadonly={isReadonly}
                                   esPlantaOrigen={esPlantaOrigen}
                        />
                    </TabPanel>

                    {/*LOG*/}
                    <TabPanel value={numeroTab} index={2}>
                        <table className="table-custom">
                            <thead>
                            <tr>
                                <th>{t("Usuario")}</th>
                                <th>{t("Fecha")}</th>
                                <th>{t("Estatus")}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {viajeLog?.map(row => (
                                <tr key={row.id}>
                                    <td>{row.usuario}</td>
                                    <td>{formatDate(row.fecha, true)}</td>
                                    <td>{t(row.operacion)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </TabPanel>

                    {/*COMENTARIOS*/}
                    <TabPanel value={numeroTab} index={3}>
                        <TripComments viajeId={viajeId} plantaId={origen.id} isReadonly={isReadonly}/>
                    </TabPanel>
                </ValidationContext.Provider>

                {/*BOTONES*/}
                <div className="buttons">
                    {/*GUARDADO GENERAL (TODA LA INFORMACIÓN)*/}
                    {proveedorLogisticoHabilitado &&
                    <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon/>}
                            onClick={() => actualizarDatosLogisticos()}>
                        {t('Guardar')}
                    </Button>
                    }

                    {/*GUARDADO GENERAL (TODA LA INFORMACIÓN)*/}
                    {sePuedeGuardar() &&
                    <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon/>}
                            onClick={() => save(false, false)}>
                        {t('Guardar')}
                    </Button>
                    }

                    {/*REGRESAR A EN OPERACION*/}
                    {!isReadonly && viaje.porEnviar && esEnvio && esPlantaOrigen &&
                    <Button variant="contained" color="warning" size="small" startIcon={<ReplayIcon/>}
                            onClick={regresaraEnOperacion}>
                        {t('En Operación')}
                    </Button>
                    }

                    {!isReadonly && puedeRegresarAOrigen &&
                    <>
                        {/*REGRESAR A POR ENVIAR*/}
                        <Button variant="contained" color="warning" size="small" startIcon={<ReplayIcon/>}
                                onClick={regresaraPorEnviar}>
                            {t('Regresar viaje a origen')}
                        </Button>

                        {/*SOLO GUARDA MATERIALES*/}
                        <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon/>}
                                onClick={agregarMateriales}>
                            {t('Guardar')}
                        </Button>
                    </>
                    }

                    {(viaje.enTransito || viaje.enTransito1 || viaje.recibido) && !viaje.esIncompleto && esEnvio && esPlantaOrigen &&
                    <>
                        {/*IMPRIMIR*/}
                        <Button variant="contained" color="primary" size="small" startIcon={<Print/>}
                                onClick={() => setImpresionAbierta(true)}>
                            {t('Imprimir')}
                        </Button>

                        {/*REGRESAR A POR ENVIAR*/}
                        {sePuedeRegresaraPorEnviar() &&
                        <Button variant="contained" color="warning" size="small" startIcon={<ReplayIcon/>}
                                onClick={regresaraPorEnviar}>
                            {t('Por Enviar')}
                        </Button>
                        }

                        {/*GUARDAR PEDIMENTO*/}
                        {habilitarPedimento() &&
                        <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon/>}
                                onClick={guardarPedimento}>
                            {t('Guardar')}
                        </Button>
                        }
                    </>
                    }

                    {/*ENVIAR A TRANSITO*/}
                    {sePuedeEnviarTransito() &&
                    <>
                        <Button variant="contained" color={esEnvio ? "success" : "warning"} size="small"
                                startIcon={esEnvio ? <SendIcon/> : <ReplayIcon/>}
                                onClick={() => enviaraTransito(esEnvio)}>
                            {t('En Tránsito')}
                        </Button>
                    </>
                    }

                    {/*RECIBIR CUANDO HAY DIFERENCIA DE RACKS*/}
                    {sePuedeRecibir() &&
                    <Button variant="contained" color="primary" size="small" startIcon={<LocalShippingIcon/>}
                            onClick={recibir}>
                        {t('Recibir')}
                    </Button>
                    }

                    {/*COMPLETAR VIAJES POR DIFERENCIA DE RACKS*/}
                    {!isReadonly && !esEnvio && viaje.estatus === estatusViaje.reciboParcial && !viaje.esIncompleto &&
                    <Button variant="contained" color="success" size="small" startIcon={<Check/>}
                            onClick={() => setModalCompletarAbierto(true)}>
                        {t('Completar')}
                    </Button>
                    }

                    {/*COMPLETAR VIAJES CREADOS EN HAND HELD Y EN EL DESTINO*/}
                    {viaje.puedeEditarViajeIncompleto &&
                    <Button variant="contained" color="success" size="small" startIcon={<Check/>}
                            onClick={abrirConfirmCompletar}>
                        {t('Completar')}
                    </Button>
                    }

                    {/*PROGRAMAR (GUARDA TODA LA INFORMACIÓN)*/}
                    {!isReadonly && !esRecepcion && (viaje.id === 0 || viaje.estatus === estatusViaje.guardado) &&
                    <Button variant="contained" color="success" size="small" startIcon={<SendIcon/>}
                            onClick={() => save(true, false)}>
                        {t('Programar')}
                    </Button>
                    }
                </div>
            </SidebarRight>

            <PrintTrip viaje={viaje} setViaje={setViaje} open={impresionAbierta} close={cerrarModalImpresion}
                       onSave={onSave} isReadonly={isReadonly}/>

            <TripConfirm viajeId={viaje.id} close={() => setModalCompletarAbierto(false)}
                         open={modalCompletarAbierto} onSave={onSave} tipo="completar"/>
        </>
    )
}
