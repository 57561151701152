import useServiceFactory from "../useServiceFactory";
const apiUrl = '/operation/traspasos';

const useApiTransfer = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params),
        getLotes: async (id) => service.get(`${apiUrl}/${id}/obtenerLotes`, {showLoading: true}),
        getRacks: async (id) => service.get(`${apiUrl}/${id}/obtenerRacks`, {showLoading: true}),
        getTiposRack: async (id) => service.get(`${apiUrl}/${id}/tiposRack`, {showLoading: true}),
    }
}

export default useApiTransfer;
