import React, {useContext, useEffect, useState} from 'react'
import {Grid, TextField} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {ValidationContext} from "../validationContext";
import TimeField from 'react-simple-timefield';
import Box from "@mui/material/Box";

const FieldDateTime = ({
                           xs, sm, md, lg, xl, label, name, onChange, value, required, disabled, error, className
                       }) => {

    const [currentDate, setCurrentDate] = useState(null);
    const [currentTime, setCurrentTime] = useState();
    const {readOnlyContext} = useContext(ReadOnlyContext);
    const {errors} = useContext(ValidationContext);
    const {allDisabled} = useContext(ValidationContext);

    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setReadOnly(disabled ?? readOnlyContext?.current ?? false);
    }, [disabled, readOnlyContext]);

    useEffect(() => {
        let newValue = value != null ? moment(value).toDate() : null;
        const newTime = value != null ? moment(value).format("HH:mm") : '';
        const year = newValue != null ? newValue.getFullYear() : null;
        newValue = newValue != null && year >= 1900 ? newValue : year != null ? new Date(1900, 0, 1) : null;
        setCurrentDate(newValue);
        setCurrentTime(newTime);
    }, [value]);

    const getDateWithTime = (date, time) => {
        if (!!date) {
            time = !time ? '00:00' : time;

            const hour = Number(time.split(":")[0]);
            const minute = Number(time.split(":")[1]);
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute);
        }

        onChange(name, date != null ? moment(date).format('YYYY-MM-DDTHH:mm:00') : null);
    }

    const onDateChange = (newValue) => {
        getDateWithTime(newValue, currentTime);
    };

    const onTimeChange = (event, value) => {
        const newTime = value.replace(/-/g, ':');
        const time = newTime.substr(0, 5);

        if (currentDate)
            getDateWithTime(currentDate, time);
        else
            setCurrentTime(time);
    }

    return (
        <>
            {label &&
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{label}</label>
                }
                <Box display="flex">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disabled={disabled || allDisabled}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} fullWidth className={className}
                                                                error={error || errors?.[name]} size="small"/>}
                            value={currentDate}
                            onChange={onDateChange}
                        />
                    </LocalizationProvider>
                    <Box width='70px'>
                        <TimeField
                            value={currentTime}
                            onChange={onTimeChange}
                            style={{width: '70px'}}
                            input={<TextField value={currentTime} size="small" disabled={disabled || allDisabled}/>}
                        />
                    </Box>
                </Box>
            </Grid>
            }
            {!label &&
            <Box display="flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disabled={readOnly}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} fullWidth className={className}
                                                            error={error || errors?.[name]} size="small"/>}
                        value={currentDate}
                        onChange={onDateChange}
                    />
                </LocalizationProvider>
                <TimeField
                    value={currentTime}
                    onChange={onTimeChange}
                    style={{width: 88}}
                    input={<TextField value={currentTime} size="small" disabled={disabled || allDisabled}/>}
                />
            </Box>
            }
        </>
    )
};
export default FieldDateTime;
