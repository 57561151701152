/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from "react";
import {Button, Grid, InputAdornment, TextField} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useTranslation} from "react-i18next";
import {Email, Lock, Person} from "@mui/icons-material";
import styled from "@emotion/styled";
import * as yup from "yup";
import useValidate from "../../hooks/useValidate";
import useApiSecurity from "../../api/security/useApiSecurity";
import useNotification from "../../hooks/useNotification";
import {UserContext} from "../security/AuthProvider";
import {useHistory, useLocation} from "react-router-dom";
import * as React from "react";
import useApiViaje from "../../api/operation/useApiViaje";


const textFieldStyle = {
    '& .MuiInputLabel-root': {
        transform: ' translate(30px, 20px) scale(1)'
    }
}

const StyledTextField = styled(TextField)(() => textFieldStyle);
const StyledTextField2 = styled(TextField)(() => textFieldStyle);

const mainImage = {
    height: '99vh',
    backgroundImage: "url('images/Background.png')",
    backgroundRepeat: "no-repeat"
}

const backgroundImage = {
    height: '100vh',
    backgroundImage: "url('images/Transparencia.png')",
    backgroundRepeat: "no-repeat"
}

const validationSchema = yup.object().shape({
    email: yup.string().trim()
        .when(["forgetPassword", "id"], {
            is: (a, b) => !a && b === 0,
            then: yup.string().required()
        }),
    password: yup.string().trim()
        .when(["forgetPassword", "id"], {
            is: (a, b) => !a && b === 0,
            then: yup.string().required()
        }),
    emailToReset: yup.string().trim()
        .when(["forgetPassword", "id"], {
            is: (a, b) => a && b === 0,
            then: yup.string().email().required()
        }),
    nuevoPassword: yup.string().trim()
        .when(["id"], {
            is: (a) => a > 0,
            then: yup.string().required()
        })
});

const estiloTitulo = {textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase'};

export default function Login() {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const id = new URLSearchParams(search).get('id');
    const viajeId = new URLSearchParams(search).get('viajeId');
    const confirmacionCartaPorte = new URLSearchParams(search).get('confirmacionCartaPorte');
    const userId = id != null ? Number(id) : 0;

    const {t} = useTranslation();
    const {login} = useContext(UserContext);
    const history = useHistory();
    const [newUser, setNewUser] = useState({id: userId, showPassword: false, forgetPassword: false});
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(validationSchema, newUser, setErrors);
    const [emailSent, setEmailSent] = useState(false);
    const api = useApiSecurity();
    const apiViaje = useApiViaje();
    const notification = useNotification();

    useEffect(() => {
        const confirmarCartaPorte = async () => {
            await apiViaje.confirmarCartaPorte(viajeId);
            notification.success("La recepción de la carta porte ah sido confirmada, gracias")
            history.replace({search: ""})
        }

        if (confirmacionCartaPorte != null) {
            confirmarCartaPorte().then();
        }
    }, [])

    const handleChange = (prop, value) => ({target}) => {
        value = value ?? target.value;
        setNewUser({...newUser, [prop]: value});

        if (prop === "id") history.replace({search: ""})
    };

    const handleClickShowPassword = (prop) => () => {
        setNewUser({
            ...newUser,
            [prop]: !newUser[prop],
        });
    };

    const onLogin = async () => {
        const valid = await validate();
        if (!valid) return;
        const validated = await login(newUser);

        if (validated)
            history.push('/');
    };

    const resetPassword = async () => {
        const valid = await validate();
        if (!valid) return;

        const informacionCorreo = {
            subject: t("Cambiar contraseña"),
            indicaciones: t("Ir al siguiente link para cambiar su contraseña")
        };

        await api.enviarCambioPassword({correo: newUser.emailToReset, informacionCorreo});
        setEmailSent(true);
    }

    const changePassword = async () => {
        const valid = await validate();
        if (!valid) return;

        await api.cambiarPassword(newUser.id, {nuevoPassword: newUser.nuevoPassword, token})
        clearValidation();
        setNewUser({...newUser, id: 0});
        history.replace({search: ""})
    }

    return (
        <div className="login-container">
            <Grid container>
                <Grid item xs={0} sm={6} lg={8}>
                    <div style={mainImage}>
                        <div style={backgroundImage}/>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} p={4} pr={5}>
                    <Grid container justifyContent="center" mt={5}>
                        <Grid item xs={12} align="center" style={{height: '20vh'}}>
                            <img src="images/logo.png" alt="" style={{height: '60px'}}/>
                        </Grid>

                        {newUser.id > 0 ?
                            <>
                                <Grid item xs={12} sx={estiloTitulo}>
                                    {t("Restablecer tu contraseña")}
                                </Grid>

                                <Grid item xs={12} my={4}>
                                    <StyledTextField2
                                        fullWidth
                                        label={t('Nueva Contraseña')}
                                        type={newUser.showNuevoPassword ? 'text' : 'password'}
                                        value={newUser.nuevoPassword}
                                        onChange={handleChange('nuevoPassword')}
                                        error={errors.hasOwnProperty("nuevoPassword")}
                                        InputProps={{
                                            startAdornment: <InputAdornment
                                                position="start"><Lock/></InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword("showNuevoPassword")}>
                                                    {newUser.showNuevoPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid className="buttons" item xs={8}>
                                    <Button variant="contained" onClick={handleChange("id", 0)}
                                            sx={{width: '100%'}}>
                                        {t('Cancelar')}
                                    </Button>
                                    <Button variant="contained" color="success" onClick={changePassword}
                                            sx={{width: '100%'}}>
                                        {t('Confirmar')}
                                    </Button>
                                </Grid>
                            </>
                            :
                            <>
                                {newUser.forgetPassword ?
                                    <>
                                        <Grid item xs={12} sx={estiloTitulo}>
                                            {t("Restablecer tu contraseña")}
                                        </Grid>

                                        <Grid item xs={12} my={1}>
                                            {t("Ingresa tu correo electrónico")}.
                                        </Grid>

                                        <Grid item xs={12}>
                                            {t("InstruccionesResetPassword")}.
                                        </Grid>

                                        {emailSent ?
                                            <Grid item xs={12} mt={2} fontWeight={700}>
                                                {t("El correo ah sido enviado")}.
                                            </Grid>
                                            :
                                            <>
                                                <Grid item xs={12} my={4}>
                                                    <StyledTextField
                                                        fullWidth
                                                        label={t('CORREO')}
                                                        value={newUser.emailToReset}
                                                        onChange={handleChange('emailToReset')}
                                                        error={errors.hasOwnProperty("emailToReset")}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment
                                                                position="start"><Email/></InputAdornment>,
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Grid>

                                                <Grid className="buttons" item xs={12}>
                                                    <Button variant="outlined"
                                                            onClick={handleChange("forgetPassword", false)}
                                                            sx={{width: '100%'}}>
                                                        {t('Regresar')}
                                                    </Button>
                                                    <Button variant="contained" onClick={resetPassword}
                                                            sx={{width: '100%'}}>
                                                        {t('Enviar Correo')}
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12}>
                                            <StyledTextField
                                                fullWidth
                                                label={t('USUARIO')}
                                                value={newUser.email}
                                                onChange={handleChange('email')}
                                                error={errors.hasOwnProperty("email")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start"><Person/></InputAdornment>,
                                                }}
                                                variant="standard"
                                            />
                                        </Grid>

                                        <Grid item xs={12} my={4}>
                                            <StyledTextField
                                                fullWidth
                                                label={t('CONTRASEÑA')}
                                                type={newUser.showPassword ? 'text' : 'password'}
                                                value={newUser.password}
                                                onChange={handleChange('password')}
                                                error={errors.hasOwnProperty("password")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment
                                                        position="start"><Lock/></InputAdornment>,
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowPassword("showPassword")}>
                                                            {newUser.showPassword ? <VisibilityOff/> :
                                                                <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                variant="standard"
                                            />
                                            <Button variant="text" onClick={handleChange("forgetPassword", true)}>
                                                {t("Olvidé mi contraseña")}
                                            </Button>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Button variant="contained" onClick={onLogin} sx={{width: '100%'}}>
                                                {t('Entrar')}
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
