/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import Filter from "../../shared/Filter";
import useApiUsuario from "../../../api/catalogs/useApiUsuario";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import UserDetail from "./UserDetail";

export default function User() {
    const {t} = useTranslation();
    const api = useApiUsuario();

    const filtroDefault = {users: [], profiles: [], status: [], email: '', lastName: '', name: ''};

    const [grid, setGrid] = useState({data: []});
    const [usuarioId, setUsuarioId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);

    const buscar = async (filtro) => {
        const result = await api.filter(filtro);
        setGrid({data: result});
    };

    const onSave = async () => {
        setAbrirDetalle(false);
        await buscar();
    };

    const verDetalle = (id) => {
        setTemporalId(Math.random())
        setUsuarioId(id);
        setAbrirDetalle(true)
    };

    const HeaderAddButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => verDetalle(0)}>
                {t('Agregar')}
            </Button>
        </div>
    );

    const ViewDetailButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    );

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    return (
        <>
            <Filter titulo={t('Catálogo de Usuarios')} valorDefault={filtroDefault} buscar={buscar}>
                <FormaFiltros/>
            </Filter>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            headerAddButton: HeaderAddButton,
                            estatusValueCell: EstatusValueCell,
                            viewDetailButton: ViewDetailButton,
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={grid.data}
                >
                    <AgGridColumn field="email" headerName={t('Email')}/>
                    <AgGridColumn field="name" headerName={t('Nombre')}/>
                    <AgGridColumn field="lastName" headerName={t('Apellido')}/>
                    <AgGridColumn field="profile" headerName={t('Tipo de Perfil')}/>
                    <AgGridColumn field="planta" headerName={t('Planta')} flex={6}/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <UserDetail usuarioId={usuarioId} open={abrirDetalle} temporalId={temporalId}
                                  close={() => setAbrirDetalle(false)} onSave={onSave}/>
        </>
    );
}

const FormaFiltros = ({filtro, onChange}) => {
    const {t} = useTranslation();

    return (
        <>
            <FieldTextBox label={t("Email")} name="email" value={filtro.email} sm={6} md={3}
                                      onChange={onChange}/>

            <FieldTextBox label={t("Nombre")} name="name" value={filtro.name} sm={6} md={3}
                                      onChange={onChange}/>

            <FieldTextBox label={t("Apellido")} name="lastName" value={filtro.lastName} sm={6} md={3}
                                      onChange={onChange}/>
        </>
    )
};
