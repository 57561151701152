import * as React from 'react';
import {Divider,  Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

const sizes = {
    sm: 300,
    md: 600,
    lg: 1000
}

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(
    ({theme, size}) => ({
        '& .MuiDrawer-paper': {
            width: !isNaN(size) ? size : !size || !sizes.hasOwnProperty(size) ? sizes.md : sizes[size]
        }
    }),
);

export default function SidebarRight({title, size, open, close, ...props}) {
    const elements = React.Children.toArray(props.children);
    const totalElements = elements.length;
    const body = elements[0];
    const footer = totalElements > 1 ? elements[1] : null;

    return (
        <>
            <Drawer anchor="right" size={size} open={open}>
                <DrawerHeader/>

                <Box display="flex" flexDirection="column" sx={{ height: '90%'}}>
                    {/*HEADER*/}
                    <Box display="flex" px={2}>
                        <Typography flexGrow={1} variant="h6">
                            {title}
                        </Typography>

                        {close &&
                        <IconButton onClick={close} color="primary" sx={{top: '-10px'}}>
                            <CloseIcon/>
                        </IconButton>
                        }
                    </Box>

                    {/*BODY*/}
                    <Box px={2} flexGrow={1} sx={{ overflowY: 'auto' }} display="flex" flexDirection="column">
                        {React.cloneElement(body)}
                    </Box>

                    {/*FOOTER*/}
                    {footer &&
                    <Box px={2}>
                        <Divider/>
                        <Box textAlign="right" mt={1}>
                            {React.cloneElement(footer)}
                        </Box>
                    </Box>
                    }
                </Box>
            </Drawer>
        </>
    );
}
