/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import './modal.css'
import {Grid, Modal} from "@mui/material";
import {Close} from "@mui/icons-material";


const BasicModal = ({open, title, subTitle, width, onClose, closeHidden,...props}) => {
    const elements = React.Children.toArray(props.children)
    const body = elements[0]
    const footer = elements.length > 1 ? elements[1] : null

    return (
        <>
            <Modal
                open={open}
                //onClose={handleClose}
            >
                <div className="modal-container" style={{width: width ?? '50vw'}}>
                    <div className="header">
                        {title}
                        {!closeHidden &&
                            <span className="close-icon" onClick={onClose}>
                                <Close/>
                            </span>
                        }
                    </div>
                    {/*<div className="body">*/}
                    {/*    {React.cloneElement(body, {})}*/}
                    {/*</div>*/}
                    <Grid className="body" container direction="row" justify="space-between" spacing={3}>
                        {React.cloneElement(body, {})}
                    </Grid>
                    <div className="footer">
                        {footer != null && React.cloneElement(footer)}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default BasicModal
