/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import DialogUi from "../../shared/modal/Dialog";
import useApiViaje from "../../../api/operation/useApiViaje";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Grid, TextField} from "@mui/material";
import {Delete, RemoveDone, Save} from "@mui/icons-material";
import {useEffect, useState} from "react";
import * as yup from "yup";
import useValidate from "../../../hooks/useValidate";
import {ValidationContext} from "../../shared/validationContext";
import FieldDropDown from "../../shared/fields/FieldDropDown";

const validationSchema = yup.object().shape({
    motivoId: yup.number().required(),
    comentarios: yup.string().trim().required()
});

export default function TripConfirm({viajeId, open, close, onSave, tipo}) {
    const {t} = useTranslation();
    const api = useApiViaje();

    const [datos, setDatos] = useState({});
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(validationSchema, datos, setErrors);

    const tiposConfirmacion = {
        eliminar: {
            titulo: "Eliminar Viaje",
            accion: api.eliminar,
            nombreCatalogo: 'motivoEliminacionViaje',
            icono: <Delete/>,
            nombreAccion: "Eliminar"
        },
        completar: {
            titulo: "Completar Viaje",
            accion: api.completar,
            nombreCatalogo: 'motivoRecepcionParcial',
            icono: <Save/>,
            nombreAccion: "Completar"
        },
        cancelarReciboCaseta: {
            titulo: "Cancelar Llegada",
            accion: api.cancelarReciboCaseta,
            nombreCatalogo: 'CancelacionReciboEnCaseta',
            icono: <RemoveDone/>,
            nombreAccion: "Confirmar"
        },
        cancelarReciboPlanta: {
            titulo: "Cancelar Llegada",
            accion: api.cancelarReciboPlanta,
            nombreCatalogo: 'CancelacionReciboEnPlanta',
            icono: <RemoveDone/>,
            nombreAccion: "Confirmar"
        }
    }

    const tipoConfirmacion = tiposConfirmacion[tipo];

    useEffect(() => {
        setDatos({});
        clearValidation();
    }, [open]);

    const onChange = (name, value) => {
        setDatos({...datos, [name]: value});
    }

    const onChangeComentarios = ({target}) => {
        onChange("comentarios", target.value);
    }

    const confirmar = async () => {
        const valid = await validate();
        if (!valid) return;

        await tipoConfirmacion.accion(viajeId, datos);

        onSave();
    }

    return (
        <>
            <DialogUi open={open} title={tipoConfirmacion.titulo} onClose={close} width="md" scroll="paper">
                <ValidationContext.Provider value={{errors: errors, setErrors: setErrors}}>
                    <Grid container spacing={2}>
                        <FieldDropDown label={t('Motivo')} name="motivoId" onChange={onChange} source="generales"
                                       filter={{name: tipoConfirmacion.nombreCatalogo}}
                                       value={datos.motivoId} xs={6} valueById translate/>

                        <Grid item xs={6}/>

                        <Grid item xs>
                            <label>{t("Comentarios")}</label>
                            <TextField onChange={onChangeComentarios} multiline rows={2} fullWidth size="small"
                                       value={datos.comentarios} error={errors.comentarios}
                                       inputProps={{maxLength: 200}}/>
                        </Grid>
                    </Grid>
                </ValidationContext.Provider>

                <div className="buttons">
                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="contained" color="success" size="small" startIcon={tipoConfirmacion.icono}
                            onClick={confirmar}>
                        {t(tipoConfirmacion.nombreAccion)}
                    </Button>
                </div>
            </DialogUi>
        </>
    );
}
