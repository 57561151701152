import React, {useEffect, useState} from 'react'
import {Grid, TextField} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useTranslation} from "react-i18next";
import moment from "moment";
import Box from "@mui/material/Box";

const FieldRangeDatePicker = ({
                                  xs, sm, md, lg, xl, label, name, onChange, value,  disabled, error
                              }) => {

    const {t} = useTranslation();
    const [currentValue, setCurrentValue] = useState([null, null]);
    const [open, setOpen] = useState({from:false, to:false});

    useEffect(() => {
        const dateFrom = value?.dateFrom != null ? moment(value?.dateFrom).toDate() : null;
        const dateTo = value?.dateTo != null ? moment(value?.dateTo).toDate() : null;
        setCurrentValue([dateFrom, dateTo])
    }, [value])


    const startChanged = (newValue) => {
        console.log(newValue);
        setCurrentValue([newValue, currentValue[1]]);
        onChange(name, {
            dateFrom: newValue != null ? moment(newValue).format('YYYY-MM-DDT00:00:00') : null,
            dateTo: currentValue[1] != null ? moment(currentValue[1]).format('YYYY-MM-DDT00:00:00') : null
        });
    }

    const fromClose = ()=>{
        setOpen({...open, from:false});
    }

    const endChanged = (newValue) => {
        if (moment(newValue).isSame(currentValue[1], 'days'))
            return;

        setCurrentValue([currentValue[0], newValue]);
        onChange(name, {
            dateFrom: currentValue[0] != null ? moment(currentValue[0]).format('YYYY-MM-DDT00:00:00') : null,
            dateTo: newValue != null ? moment(newValue).format('YYYY-MM-DDT00:00:00') : null,
        });
    }

    return (
        <>
            <Grid item xs={xs || 12} sm={sm || xs} md={md || xs} lg={lg || xs} xl={xl || xs}>
                {label &&
                <label>{label}</label>
                }
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <DatePicker
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} fullWidth size="small" onClick={(e) => setOpen({...open, from:true})} />}
                            value={currentValue[0]}
                            onChange={startChanged}
                            open={open.from}
                            onOpen={() => setOpen({...open, from:true})}
                            onClose={fromClose}
                            maxDate={currentValue[1]}
                            clearable
                        />
                        <label>{t('Al')}</label>
                        <DatePicker
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} fullWidth size="small" onClick={(e) => setOpen({...open, to:true})} />}
                            value={currentValue[1]}
                            onChange={endChanged}
                            open={open.to}
                            onOpen={() => setOpen({...open, to:true})}
                            onClose={() => setOpen({...open, to:false})}
                            minDate={currentValue[0]}
                            clearable
                        />
                    </Box>
                </LocalizationProvider>
            </Grid>
        </>
    )
}
export default FieldRangeDatePicker;
