/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from 'react'
import {Checkbox, Grid, Typography} from "@mui/material";
import useWindowState from "../../../hooks/useWindowState";
import _ from "lodash"
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import { useVirtual } from "react-virtual";
import useApiSource from "../../../api/configuration/useApiSource";

const getIds = (multiple, valueByIds, values) =>{
    if (values == null){
        return multiple? []:0;
    }

    if (multiple){
        if (valueByIds){
            return values;
        }
        return values.map(e=>e.id);
    }

    if (valueByIds){
        return values
    }

    return values.id;
}



const Option = React.memo(({id, defaultChecked, name, onChanged}) => {
    const [checked, setChecked] = useState(false);
    const elementId = window.uniqueId++;

    useEffect(() => {
        if (defaultChecked === checked)
            return;
        setChecked(defaultChecked ?? false);
    }, [defaultChecked]);

    const toggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        onChanged(newValue, id);
    }

    return (
        <div className="multiselect-option">
            <Checkbox
                id={`${elementId}`}
                checked={checked}
                onClick={toggle}
                inputProps={{'aria-label': 'controlled'}}
            />
            <label htmlFor={`${elementId}`} >{name}</label>
        </div>
    );
});

const FieldMultiselect = ({
                              xs, label, name, options, source, onChange, value, className, required,
                              placeholder, valueById, error, errors, filter, disabled, height, attributeId,
                          }) => {
    const {t} = useTranslation();
    const api = useApiSource();

    const [dropOptions, setDropOptions] = useWindowState(`${'drop' + source??''}`, options == null ? [] : options);
    const [event, setEvent] = useState(null);
    const [lastFilter, setLastFilter] = useState('');
    const parentRef = React.useRef();


    useEffect(() => {
        getOptions(source, options, filter).then((newOptions) => {
                updateSelectedIds(value, newOptions);
            }
        );
    }, [options, source])

    useEffect(() => {
        if (filter != null) {
            const nextFilter = JSON.stringify(filter);
            if (lastFilter !== nextFilter) {
                getOptions(source, options, filter).then((newOptions) => {
                        updateSelectedIds(value, newOptions);
                    }
                );
                setLastFilter(nextFilter);
            }
        }
    }, [filter]);


    useEffect(() => {
        updateSelectedIds(value, dropOptions);
    }, [value]);

    const updateSelectedIds = (value, options) => {
        const ids = getIds(true, valueById, value);
        setChecked(ids, options);
    }

    useEffect(() => {
        if (event != null) {
            onChanged(event.checked, event.id);
        }
    }, [event])

    const setChecked = (ids, options) => {
        const currentOptions = options ?? dropOptions;
        setDropOptions(currentOptions.map(e => {
            return {
                ...e,
                checked: ids.indexOf(e.id) >= 0
            }
        }));
    }

    function onChanged(checked, id) {
        const newValues = dropOptions.map(e => {
            return e.id === id ? {...e, checked} : e
        });
        setDropOptions(newValues);
        const valuesChecked = newValues.filter(e => e.checked === true);
        onChange(name, valueById ? valuesChecked.map(e => e.id) : valuesChecked);
    }

    const checkedChanged = useCallback((checked, id) => {
        setEvent({checked, id});
    }, []);

    function selectAll() {
        const all = dropOptions.map(e => {
            return {...e, checked: true}
        });
        setDropOptions(all);
        onChange(name, valueById ? all.map(e => e.id) : all);
    }

    function selectNone() {
        const all = dropOptions.map(e => {
            return {...e, checked: false}
        });
        setDropOptions(all);
        onChange(name, []);
    }

    const rowVirtualizer = useVirtual({
        size: dropOptions.length,
        parentRef,
        estimateSize: React.useCallback(() => 35, []),
        overscan: 5
    });

    const getOptions = async (source, options, filter) => {
        if (options != null) {
            return _.orderBy(options, 'name','asc') ;
        }
        return await api.get(source, filter);
    }

    return (
        <>
            {label && (
                <Grid item xs={xs} className="field-multiselect">
                    <div className="select-all-none">
                        <Link href="#" underline="hover" onClick={selectAll}>
                            {t('Seleccionar Todo')}
                        </Link>
                        /
                        <Link href="#" underline="hover" onClick={selectNone}>
                            {t('Ninguno')}
                        </Link>
                    </div>
                    <Typography variant="body2" style={{marginBottom: '0.5rem', fontWeight: 'bold'}}>
                        {required && <span style={{color: 'red'}}> * </span>}
                        {t(label)}
                    </Typography>
                    <div className="multiselect-container" ref={parentRef} style={{height: height ?? '265px'}}>
                        <div
                            style={{
                                height: `${rowVirtualizer.totalSize}px`,
                                width: "100%",
                                position: "relative"
                            }}
                        >
                        {rowVirtualizer.virtualItems.map(virtualRow => (
                            <div
                                key={virtualRow.index}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${virtualRow.start}px)`
                                }}
                            >
                                <Option key={dropOptions[virtualRow.index].id}
                                        id={dropOptions[virtualRow.index].id}
                                        defaultChecked={dropOptions[virtualRow.index].checked}
                                        name={dropOptions[virtualRow.index].name}
                                        onChanged={checkedChanged}

                                />
                            </div>
                        ))}
                        </div>


                    </div>
                </Grid>
            )}
            {/*{!label && (*/}

            {/*)}*/}
        </>
    )
}

export default FieldMultiselect
