/* eslint-disable react-hooks/exhaustive-deps */
import {Grid} from "@mui/material";
import FieldDropDownMultiselect from "../../shared/fields/FieldDropDownMultiselect";
import FieldAutoComplete from "../../shared/fields/FieldAutoComplete";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import {TRASPASO_LIST} from "../../../utility/constants";
import Box from "@mui/material/Box";
import {HeaderAddButton} from "../../shared/ag-grid-helper/goToDetail";
import {useContext, useState} from "react";
import TransferDetail from "./TransferDetail";
import {ReadOnlyContext} from "../../security/ReadOnlyProvider";
import {AppContext} from "../../Main";
import TransferGridActions, {TiposRack} from "./TransferGridActions";
import useApiTransfer from "../../../api/operation/useApiTransfer";

export default function Transfer({datos, filtro, setFiltro, buscar}) {
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const api = useApiTransfer()
    const onChange = (name, value) => {
        setFiltro({...filtro, [name]: value});
    };

    const [traspasoId, setTraspasoId] = useState(0);
    const [temporalId, setTemporalId] = useState(0);
    const [abrirDetalle, setAbrirDetalle] = useState(false);

    const verDetalle = (id) => {
        setTemporalId(Math.random())
        setTraspasoId(id);
        setAbrirDetalle(true)
    };

    const onSave = async () => {
        setAbrirDetalle(false);
        await buscar();
    };

    const Agregar = () => {
        const {readOnlyContext} = useContext(ReadOnlyContext);

        return readOnlyContext?.current ? <></> : <HeaderAddButton verDetalle={verDetalle}/>
    };

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    const copiarRacks = (id, esLote) => async () => {
        let result;
        let texto = "";

        if (esLote) {
            result = await api.getLotes(id);
        } else {
            result = await api.getRacks(id);
        }

        result.forEach(elemento => {
            const totalCaracteres = elemento.length;
            elemento = esLote && totalCaracteres > 10 ? elemento.substring(totalCaracteres - 10) : elemento;
            texto += elemento + "\n";
        });

        navigator.clipboard.writeText(texto);
    }

    const Actions = ({data}) => <TransferGridActions data={data} copiarRacks={copiarRacks} verDetalle={verDetalle}
                                                     isReadonly/>

    return (
        <>
            <Grid container spacing={2} alignItems="center" pt={0}>
                <FieldDropDownMultiselect label="Planta" sm={6} md={3} name="plantasIds" options={user.plantas}
                                          value={filtro.plantasIds} onChange={onChange} valueById disabled={false}/>

                <FieldDropDownMultiselect label="Almacén" sm={6} md={3} name="almacenIds" source="almacenes"
                                          value={filtro.almacenIds} onChange={onChange} valueById
                                          filter={{plantasIds: filtro.plantaIds}} disabled={false}
                />

                <FieldDropDownMultiselect label="Area" sm={6} md={3} name="areaIds" source="areas"
                                          value={filtro.areaIds} onChange={onChange} valueById
                                          filter={{almacenesIds: filtro.almacenIds}} disabled={false}
                />

                <FieldAutoComplete label="ID" name={`ordenId`} value={filtro.ordenId} sm={3}
                                   source="traspasos" valueById onChange={onChange} disabled={false}/>

                <FieldDropDownMultiselect label={t('Estatus')} sm={3} name="estatusIds" source="estatus"
                                          value={filtro.estatusIds} onChange={onChange} valueById translate
                                          filter={{name: 'Traspaso'}} disabled={false}/>
            </Grid>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            dateFormatter: agGridHelper.DateFormatter,
                            mapArrayFormatter: agGridHelper.MapArrayFormatter,
                            headerAddButton: Agregar,
                            estatusValueCell: EstatusValueCell,
                            tiposRack: TiposRack,
                            actions: Actions,
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={datos}
                >
                    <AgGridColumn field="ordenId" headerName={t('Orden Id')}/>
                    <AgGridColumn field="origen" headerName={t('Origen')} minWidth={400}/>
                    <AgGridColumn field="destino" headerName={t('Destino')} minWidth={400}/>
                    <AgGridColumn field="tiposRack" cellRenderer="tiposRack" headerName={t('Tipo de Rack')}
                                  cellClass="right"/>
                    <AgGridColumn field="totalRacks" headerName={t('Cantidad')} cellClass="right"/>
                    <AgGridColumn field="fechaTraspaso" headerName={t('Fecha de Transferencia')}
                                  cellRenderer="dateFormatter"/>
                    <AgGridColumn field="estatus" headerName={t('Estatus')} cellRenderer="mapArrayFormatter"
                                  source={TRASPASO_LIST}/>
                    <AgGridColumn width={160} cellRenderer="actions" cellClass={'center'} pinned={'right'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <TransferDetail traspasoId={traspasoId} open={abrirDetalle} temporalId={temporalId}
                            close={() => setAbrirDetalle(false)} onSave={onSave}/>
        </>
    );

}
