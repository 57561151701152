import React from "react";
import moment from "moment";

const RangeDateFormatter = ({value}) => (
    <>
        { value?.dateFrom != null ? moment(value?.dateFrom).format('MMM D, YYYY') : '' }
        {value!= null ? '-' : '' }
        { value?.dateTo !=  null ? moment(value?.dateTo).format('MMM D, YYYY') : '' }
    </>
);

export default RangeDateFormatter;
