import React from "react";
import {useTranslation} from "react-i18next";
import {Button, TextField, Typography} from "@mui/material";
import {Check, Clear} from "@mui/icons-material";
import {AutoCompleteContext} from "./FieldDropDownMultiselect";

export const CustomInput = ({params,isOpen}) => {
    // const startAdornments = isOpen ?
    //     `${params?.InputProps?.startAdornment?.length??0} selected`:
    //     params?.InputProps?.startAdornment;
    //
    // console.log(params?.InputProps?.startAdornment);
    //
    // const customParams = {
    //     ...params,
    //     InputProps: {
    //       ...params.InputProps,
    //       startAdornment : startAdornments,
    //     }
    // }
    return (<TextField {...params} label="Texcto" size="small"/>)
}



const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const {t} = useTranslation()
    const {
        selectAll,
        unSelect,
        showSelectButtons,
    } = React.useContext(AutoCompleteContext);



    return (
        <div ref={ref}  {...other} >
            <div className="field-select multiselect-options">
                {showSelectButtons &&
                <>
                    <Button startIcon={<Check/>} variant="contained"
                            onClick={selectAll}>{t('Seleccionar Todo')}</Button>
                    <Button startIcon={<Clear/>} variant="contained"
                            onClick={unSelect}>{t('Deseleccionar')}</Button>
                </>
                }
            </div>
            {children.map((item, index) => (
                <Typography key={index} component="li" {...item.props} noWrap>
                    {item.props.children}
                </Typography>
            ))}
        </div>
    );
});

export default ListboxComponent;
