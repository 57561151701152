import * as yup from "yup";

const rackManagementSchema = yup.object().shape({
    alias: yup.string().trim().required(),
    largo: yup.number().required(),
    ancho: yup.number().required(),
    alto: yup.number().required(),
    peso: yup.number().required(),
    tipoVidrioId: yup.number().required(),
    porcentajeOcupacion: yup.number().max(100, ""),
    porcentajeOcupacionVacio: yup.number().max(100, ""),
    numeroSap: yup.string().trim().required().min(10, "debe ser de 10 caractéres").max(10, "debe ser de 10 caractéres"),
    racks: yup.array()
        .of(
            yup.object().shape({
                numeroRack: yup.string().trim().required()
                    .when("esGenerico", {
                        is: false,
                        then: yup.string().matches(/^(\w{4}|\w{8})$/ig, 'Debe ser de 4 u 8 caractéres')
                    })
                    .when("esGenerico", {
                        is: true,
                        then: yup.string().matches(/^\w{4}$/ig, 'Debe ser de 4 caractéres')
                    }),
            })
        ),
    materiales: yup.array()
        .of(
            yup.object().shape({
                numero: yup.string().trim().required(),
                descripcion: yup.string().trim().required(),
                tipoId: yup.number().required(),
                estandarPack: yup.string().trim().required(),
                tipoProducto: yup.string().trim().required(),
                plantaFabricacionAId: yup.number().required(),
                plantaFabricacionBId: yup.number().required(),
                plantaClienteId: yup.number().required(),
            })
        )
});

export default rackManagementSchema;


