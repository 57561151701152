import useServiceFactory from "../useServiceFactory";
const apiUrl = '/users';

const useApiUsuario = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        filter: async (params) => service.get(`${apiUrl}`, {params, showLoading: true}),
        getById: async (id) => service.get(`${apiUrl}/${id}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params),
        update: async (params) => service.put(`${apiUrl}`, params),
        changePassword: async (params) => service.patch(`${apiUrl}`, params)
    }
};

export default useApiUsuario;
