/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import DialogUi from "../../../shared/modal/Dialog";
import useApiViaje from "../../../../api/operation/useApiViaje";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Tab, Tabs, TextField} from "@mui/material";
import {Check, Edit, Print, Save, Send} from "@mui/icons-material";
import useMessage from "../../../../hooks/useMessage";
import moment from "moment";
import {useEffect, useState} from "react";
import * as yup from "yup";
import useValidate from "../../../../hooks/useValidate";
import TabPanel from "../../../shared/TabPanel";
import ExitPassPreview from "./ExitPassPreview";
import ExitPassPrint from "./ExitPassPrint";
import {PAISES} from "../../../../utility/constants";
import {chunk, pick} from "lodash";
import {HeaderInvoice, HeaderPackingList, TripDocument} from "./Documents";
import produce from "immer";
import {setDeepValue} from "../../../../utility/objectUtility";
import Box from "@mui/material/Box";
import useNotification from "../../../../hooks/useNotification";

const validationSchema = yup.object().shape({
    nombreOperador: yup.string().required(),
});

const costoRack = 100;

export default function PrintTrip({viaje, setViaje, open, close, onSave, isReadonly}) {
    const {t} = useTranslation();
    const api = useApiViaje();
    const message = useMessage();
    const soloImprimir = viaje.enTransito || viaje.enTransito1 || viaje.recibido;
    const paisOrigenId = viaje.plantaOrigen?.paisId ?? PAISES.estadosUnidos;
    const paisDestinoId = viaje.plantaDestino?.paisId ?? PAISES.estadosUnidos;
    const idioma = paisOrigenId === PAISES.estadosUnidos || paisDestinoId === PAISES.estadosUnidos ? "en" : "es";
    const camposPaseSalida = ["numeroTransporte", "placasTransporte", "nombreOperador", "comentarios"];
    const notification = useNotification();
    const limiteOcupacion = 115;

    const [titulo, setTitulo] = React.useState("");
    const [numeroTab, setNumeroTab] = React.useState(0);
    const [resumenDocumento, setResumenDocumento] = React.useState({});
    const [errors, setErrors] = useState({});
    const [infoPaseSalida, setInfoPaseSalida] = useState({});
    const [validate, clearValidation] = useValidate(validationSchema, viaje, setErrors);
    const [esEditar, setEsEditar] = useState(false);
    const [enviarTransitoAbierto, setEnviarTransitoAbierto] = useState(false);
    const [porcentajeOcupacion, setPorcentajeOcupacion] = useState(false);
    const [errorMotivoOcupacion, setErrorMotivoOcupacion] = useState(false);

    useEffect(() => {
        const load = async () => {
            const informacionAdicional = await api.getInformacionImpresion(viaje.id);
            const tiposRack = [...viaje.tiposRack, ...viaje.materiales];

            setViaje({...viaje, ...informacionAdicional});
            //Se guardan los datos iniciales del pase de salida, por si editan, y cancelan, regresar a la info original
            setInfoPaseSalida(pick(informacionAdicional, camposPaseSalida));
            clearValidation();
            setNumeroTab(0);
            setEsEditar(false);
            setEnviarTransitoAbierto(false);
            setTitulo(soloImprimir ? "Imprimir" : "Enviar a Tránsito");
            setErrorMotivoOcupacion(false);
            getResumenDocumento(tiposRack);
        }

        if (open) {
            load().then();
        }
    }, [open]);

    const getResumenDocumento = (tiposRack) => {
        if (!tiposRack) return;

        tiposRack = tiposRack.map((e, i) => {
            return {...e, consecutivo: i + 1, valor: e.esMaterial ? e.valor : costoRack}
        });
        const totalRacks = tiposRack.reduce((sum, e) => sum + e.cantidad, 0);
        const racksPrimerPagina = tiposRack.slice(0, 11);
        const racksRestantes = tiposRack.slice(11);
        const racksPorPagina = chunk(racksRestantes, 15);
        const paginas = [{id: 1, racks: racksPrimerPagina}];
        const pesoTotal = tiposRack.reduce((sum, e) => sum + (e.pesoTotal ?? 0), 0);
        const valorTotal = tiposRack.reduce((sum, e) => sum + (e.cantidad * (e.valor ?? 0)), 0);
        let porcentajeOcupacion = 0;

        tiposRack.forEach(e => {
            porcentajeOcupacion += e.racks ?
                e.racks.reduce((sum, r) => sum + r.cantidad * r.porcentajeOcupacion, 0)
                : e.cantidad * e.porcentajeOcupacion;
        })

        porcentajeOcupacion = parseFloat(porcentajeOcupacion).toFixed(2);

        racksPorPagina.forEach((e, i) => {
            paginas.push({id: i + 2, racks: e});
        })

        setPorcentajeOcupacion(porcentajeOcupacion);
        setResumenDocumento({tiposRack, totalRacks, paginas, pesoTotal, valorTotal});
    };

    const onChangeTab = (event, value) => {
        setNumeroTab(value);
    };

    const onChange = (name, value) => {
        const viajeCopia = produce(viaje, (draft) => {
            setDeepValue(name, draft, value);
        });

        setErrorMotivoOcupacion(false);
        setViaje(viajeCopia);
    }

    const imprimir = async () => {
        const esDocumento = numeroTab !== 0;
        const valid = esDocumento || await validate();

        if (!valid) return;

        if (soloImprimir || esDocumento) {
            window.print();
            return;
        }

        setEnviarTransitoAbierto(true);
    }

    const enviaraTransito = async () => {
        if (porcentajeOcupacion >= limiteOcupacion && !!!viaje.motivoOcupacionExcesiva) {
            setErrorMotivoOcupacion(true);
            notification.error(t('Favor de revisar la información requerida o inválida'));
            return;
        }

        await api.save(viaje, {showSuccess: false});

        onChange("fechaRealEnvio", moment(new Date()).format('YYYY-MM-DDTHH:mm:00'));

        window.print();
        close();
        onSave();
    }

    const editar = async () => {
        if (!esEditar) {
            setEsEditar(true);
            return;
        }

        const valid = await validate();
        if (!valid) return;

        await message.confirm(t("Se harán cambios en el pase de salida"), t("¿Desea Continuar?"));
        await api.savePaseSalida(viaje.id, viaje);

        const copiaInfoPaseSalida = {};
        camposPaseSalida.forEach(e => copiaInfoPaseSalida[e] = viaje[e]);

        setInfoPaseSalida(copiaInfoPaseSalida);
        setEsEditar(false);
    }

    const cancelar = () => {
        if (!esEditar) {
            close();
        }

        const copiaViaje = {...viaje};

        camposPaseSalida.forEach(e => copiaViaje[e] = infoPaseSalida[e]);
        setViaje(copiaViaje);

        setEsEditar(false);
    }

    const cancelarEnvioTransito = () => {
        setEnviarTransitoAbierto(false);
    }

    const getColorPorcentaje = () => {
        return porcentajeOcupacion >= 95 ? "#43a047" : porcentajeOcupacion >= 85 ? "#fbc02d" : "#d32f2f";
    }

    return (
        <>
            <DialogUi open={open} title={t(titulo)} onClose={close} width="md" scroll="paper" footerInline>
                <div>
                    <div className="no-print">
                        <Tabs value={numeroTab} onChange={onChangeTab} sx={{mt: -2}} className="no-print">
                            <Tab label={t("Pase de Salida")}/>
                            <Tab label={t("Pro-Forma Invoice")}/>
                            <Tab label={t("Packing List")}/>
                        </Tabs>

                        <TabPanel value={numeroTab} index={0}>
                            <ExitPassPreview viaje={viaje} onChange={onChange} errors={errors}
                                             disabled={soloImprimir && !esEditar}/>
                        </TabPanel>

                        <TabPanel value={numeroTab} index={1}>
                            <HeaderInvoice idioma={idioma} viaje={viaje}/>

                            <TripDocument idioma={idioma} viaje={viaje} tiposRack={resumenDocumento.tiposRack}
                                          resumenDocumento={resumenDocumento} numeroPagina={1} esFactura={true}/>
                        </TabPanel>

                        <TabPanel value={numeroTab} index={2}>
                            <HeaderPackingList idioma={idioma}/>

                            <TripDocument idioma={idioma} viaje={viaje} tiposRack={resumenDocumento.tiposRack}
                                          resumenDocumento={resumenDocumento} numeroPagina={1} esFactura={false}/>
                        </TabPanel>
                    </div>
                </div>

                <Box display="flex" gap={1} alignItems="center">
                    <Box alignSelf="left" fontWeight="bold" flexGrow={1} pl={2}>
                        {viaje.cambiosPaseSalida > 0 ? `${t("Modificaciones")}: ${viaje.cambiosPaseSalida}` : ""}
                    </Box>

                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={cancelar}>
                        {esEditar || !soloImprimir ? t('Cancelar') : t('Cerrar')}
                    </Button>

                    {soloImprimir && numeroTab === 0 && !isReadonly &&
                    <Button variant="contained" color="success" size="small" onClick={editar}
                            startIcon={esEditar ? <Save/> : <Edit/>}>
                        {esEditar ? t("Guardar") : t('Editar')}
                    </Button>
                    }

                    {!esEditar &&
                    <Button variant="contained" color="primary" size="small"
                            startIcon={soloImprimir ? <Print/> : <Send/>}
                            onClick={imprimir}>
                        {soloImprimir || numeroTab !== 0 ? t("Imprimir") : t('En Tránsito')}
                    </Button>
                    }
                </Box>
            </DialogUi>

            {/*CONFIRMAR ENVIO a TRANSITO*/}
            <DialogUi open={enviarTransitoAbierto} title={t("Se enviará a Transito")} width="sm" scroll="paper"
                      onClose={cancelarEnvioTransito} footerInline>
                <div>
                    {porcentajeOcupacion >= limiteOcupacion &&
                    <Box mb={2}>
                        <label>{t("Motivo Ocupación")}</label>
                        <TextField
                            onChange={({target}) => onChange("motivoOcupacionExcesiva", target.value)}
                            multiline rows={2} fullWidth size="small" value={viaje.motivoOcupacionExcesiva}
                            error={errorMotivoOcupacion} inputProps={{maxLength: 200}}/>
                    </Box>
                    }

                    {t("¿Desea Continuar?")}
                </div>

                <Box display="flex" gap={1} alignItems="center">
                    <Box alignSelf="left" fontWeight="bold" flexGrow={1} pl={2} color={getColorPorcentaje()}>
                        {t("Ocupación en transporte")}: {porcentajeOcupacion}%
                    </Box>

                    <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={cancelarEnvioTransito}>
                        {t('Cancelar')}
                    </Button>

                    <Button variant="outlined" size="small" onClick={enviaraTransito} startIcon={<Check/>}>
                        Ok
                    </Button>
                </Box>
            </DialogUi>

            {/*IMPRESION*/}
            <div className="only-to-print">
                {/*El viaje esta en transito o recibido, solo se imprime la pestaña en la que se encuentre*/}
                {soloImprimir &&
                <>
                    {numeroTab === 0 ?
                        <ExitPassPrint viaje={viaje}/>
                        :
                        <div>
                            {numeroTab === 1 ?
                                <HeaderInvoice idioma={idioma} viaje={viaje}/> :
                                <div style={{marginTop: '-10px'}}><HeaderPackingList idioma={idioma}/></div>
                            }

                            {resumenDocumento.paginas.map(e =>
                                <div key={e.id}
                                     style={{paddingTop: e.id === 1 ? '0' : '40px', marginBottom: '90px'}}>
                                    <TripDocument idioma={idioma} viaje={viaje} tiposRack={e.racks}
                                                  numeroPagina={e.id} resumenDocumento={resumenDocumento}
                                                  esFactura={numeroTab === 1}/>
                                </div>
                            )}
                        </div>
                    }
                </>
                }

                {/*El viaje se enviará a transito, dependiendo de la planta son los documentos que se imprimirán*/}
                {!soloImprimir &&
                <>
                    <div>
                        <ExitPassPrint viaje={viaje}/>
                    </div>

                    {viaje.plantaOrigen?.imprimirProforma &&
                    <div className="print-break-page"
                         style={{marginTop: '25px'}}>
                        <HeaderInvoice idioma={idioma} viaje={viaje}/>

                        {resumenDocumento.paginas?.map(e =>
                            <div key={e.id}
                                 style={{paddingTop: e.id === 1 ? '0' : '40px', marginBottom: '90px'}}>
                                <TripDocument idioma={idioma} viaje={viaje} tiposRack={e.racks}
                                              numeroPagina={e.id} resumenDocumento={resumenDocumento}
                                              esFactura={true}/>
                            </div>
                        )}
                    </div>
                    }

                    {viaje.plantaOrigen?.imprimirPackingList &&
                    <div className="print-break-page" style={{marginTop: '25px'}}>
                        <HeaderPackingList idioma={idioma}/>

                        {resumenDocumento.paginas?.map(e =>
                            <div key={e.id}
                                 style={{paddingTop: e.id === 1 ? '0' : '40px', marginBottom: '90px'}}>
                                <TripDocument idioma={idioma} viaje={viaje} tiposRack={e.racks}
                                              numeroPagina={e.id} resumenDocumento={resumenDocumento}
                                              esFactura={numeroTab === 1}/>
                            </div>
                        )}
                    </div>
                    }
                </>
                }
            </div>
        </>
    );
}
