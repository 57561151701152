import TabPanel from "../../shared/TabPanel";
import {Tab, Tabs} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import RackManagement from "../rackManagment/RackManagement";
import RackManagmentPurchase from "../rackManagmentPurchase/RackManagmentPurchase";
import {useParams} from "react-router-dom";

export default function RackManagementMain() {
    const {t} = useTranslation();
    const tabInicial = Number(useParams().tab);
    const [numeroTab, setNumeroTab] = React.useState(tabInicial);

    const onChangeTab = (event, value) => {
        setNumeroTab(value);
    };

    return (
        <>
            <Tabs value={numeroTab} onChange={onChangeTab} sx={{mt: -2.5}}>
                <Tab label={t("Modelos")}/>
                <Tab label={t("Compras")}/>
            </Tabs>

            <TabPanel value={numeroTab} index={0} className="full-tab-panel">
                <RackManagement/>
            </TabPanel>

            <TabPanel value={numeroTab} index={1} className="full-tab-panel">
                <RackManagmentPurchase/>
            </TabPanel>
        </>
    )

}
