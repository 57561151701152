import useServiceFactory from "../useServiceFactory";
const apiUrl = '/settings/informacionGeneral';

const useApiInformacionGeneral = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URI_V1});
    return {
        get: async () => service.get(`${apiUrl}`, {showLoading: true}),
        save: async (params) => service.post(`${apiUrl}`, params)
    }
};

export default useApiInformacionGeneral;
