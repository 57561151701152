/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {formatDate} from "../../../../utility/dateUtility";
import {INCOTERMS_LIST, TIPOS_ENVIO_LIST, TIPOS_TRANSPORTE_LIST} from "../../../../utility/constants";
import {formatDecimal} from "../../../../utility/numberUtility";

const QRCode = require('qrcode.react');

export const HeaderInvoice = ({idioma, viaje}) => {
    const {t} = useTranslation();

    const getQrValue = () => {
        return `${t("No Entrega")}: ${viaje.numeroEntrega}, ${t("No Viaje")}: ${viaje.numeroViaje}, ${t("Caja")}: ${viaje.unidad}`
    }

    return (
        <table style={{width: '100%'}}>
            <tbody>
            <tr>
                <td style={{width: '38%'}}>
                    <QRCode value={getQrValue()} size={90}/>
                </td>
                <td>
                    <Typography variant="h6">
                        {t(`${idioma}.FacturaProForma`)}
                    </Typography>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export const HeaderPackingList = ({idioma}) => {
    const {t} = useTranslation();

    return (
        <table style={{width: '100%', fontSize: 9}}>
            <tbody>
            <tr>
                <td style={{width: '38%', verticalAlign: 'Bottom'}}>USPPI Tax ID: 26-299822800</td>
                <td style={{width: '40%'}}>
                    <Typography variant="h6">
                        {t(`${idioma}.ListaEmpaque`)}
                    </Typography>
                </td>
                <td>
                    <table>
                        <tbody>
                        <tr>
                            <td>Pittsburgh Glass Works, LLC</td>
                        </tr>
                        <tr>
                            <td>323 North Shore Drive, Sixth Floor</td>
                        </tr>
                        <tr>
                            <td>Pittsburgh, PA 15212</td>
                        </tr>
                        <tr>
                            <td>T: 412-995-6500</td>
                        </tr>
                        <tr>
                            <td>E: PGW_Intl_Logistics@Vitro.com</td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export const TripDocument = ({idioma, viaje, tiposRack, resumenDocumento, numeroPagina, esFactura}) => {
    const {t} = useTranslation();

    const getDireccion = (planta) => {
        if (!planta) return '';

        return `${planta.municipio}, ${planta.estado} ${planta.pais} CP ${planta.codigoPostal}`
    }

    const obtenerPrecio = (tipoRack, valor) => {
        return valor == null || valor === 0 || isNaN(valor) ? "" : "$" + formatDecimal(valor);
    }

    return (
        <table className="trip-documents">
            <tbody>
            <tr>
                <td>{t(`${idioma}.Fecha`)}</td>
                <td style={{width: '14%'}}>{t(`${idioma}.TipoEnvio`)}</td>
                <td style={{width: '19%'}} colSpan={2}>{t(`${idioma}.NumeroFactura`)}</td>
                <td style={{width: '20%'}} colSpan={2}>{t(`${idioma}.Referencia`)}</td>
                <td colSpan={2}>{t(`${idioma}.NumeroPO`)}</td>
                <td colSpan={2}>{t(`${idioma}.MetodoEnvio`)}</td>
            </tr>

            <tr>
                <td>{formatDate(viaje.fechaRealEnvio)}</td>
                <td>{t(`${idioma}.${TIPOS_ENVIO_LIST.get(viaje.tipoEnvio)}`)}</td>
                <td colSpan={2}>{viaje.factura}</td>
                <td colSpan={2}>{viaje.referencia}</td>
                <td colSpan={2}>{viaje.ordenCompra}</td>
                <td colSpan={2}>{t(`${idioma}.${TIPOS_TRANSPORTE_LIST.get(viaje.tipoTransporte)}`)}</td>
            </tr>

            <tr>
                <td colSpan={2}>{t(`${idioma}.PartesRelacionadas`)}</td>
                <td colSpan={4}>{t(`${idioma}.PaisDestino`)}</td>
                <td colSpan={2}>{t(`${idioma}.Transportista`)}</td>
                <td colSpan={2}>Incoterms 2010</td>
            </tr>

            <tr>
                <td colSpan={2}>{viaje.esParteRelacionada ? idioma === "en" ? 'Yes' : 'Si' : 'No'}</td>
                <td colSpan={4}>{viaje.plantaDestino?.pais}</td>
                <td colSpan={2}>{`${viaje.transportista?.name} #: ${viaje.unidad}`}</td>
                <td colSpan={2}>{INCOTERMS_LIST.get(viaje.incoterm)}</td>
            </tr>

            <tr>
                <td colSpan={2}>{t(`${idioma}.SeEnviaDesde`)}</td>
                <td colSpan={4}>{t(`${idioma}.SeEnviA`)}</td>
                <td colSpan={4}>{t(`${idioma}.AgenteAduana`)}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{viaje.origen}</td>
                <td colSpan={4}>{viaje.destino}</td>
                <td colSpan={4}>{t("Vitro C/O Gontor Forwarding Co")}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{viaje.plantaOrigen?.calle}</td>
                <td colSpan={4}>{viaje.plantaDestino?.calle}</td>
                <td colSpan={4}>{t("13713 North Unitec Drive")}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{getDireccion(viaje.plantaOrigen)}</td>
                <td colSpan={4}>{getDireccion(viaje.plantaDestino)}</td>
                <td colSpan={4}>{t("Laredo, TX 78045")}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}/>
                <td colSpan={4}>TAX ID CIM520117TG0</td>
                <td colSpan={4}>{t("PH")}: 867-711-1664</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}/>
                <td colSpan={4}>{t(`${idioma}.Contacto`)}: {viaje.plantaDestino?.contacto}</td>
                <td colSpan={4}>Attn: Arianna Mariscal</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{t(`${idioma}.HoraEnvio`)}: 6a-10p</td>
                <td colSpan={4}>{t(`${idioma}.Telefono`)}: {viaje.plantaDestino?.telefono}</td>
                <td colSpan={4}>{t("")}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{t(`${idioma}.Contacto`)}: {viaje.plantaOrigen?.contacto}</td>
                <td colSpan={4}>{t(`${idioma}.Correo`)}: {viaje.plantaDestino?.email}</td>
                <td colSpan={4}>{t(`${idioma}.VendidoA`)}</td>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{t(`${idioma}.Telefono`)}: {viaje.plantaOrigen?.telefono}</td>
                <td colSpan={4} style={{fontWeight: 700, textAlign: 'center'}}>
                    {t(`${idioma}.TambienNotificarA`)}
                </td>
                <td colSpan={4}/>
            </tr>

            <tr className="not-center">
                <td colSpan={2}>{t(`${idioma}.Correo`)}: {viaje.plantaOrigen?.email}</td>
                <td colSpan={4} style={{textAlign: 'center'}}>PGW_Intl_Logistics@Vitro.com</td>
                <td colSpan={4}/>
            </tr>

            {esFactura ?
                <tr>
                    <td style={{width: '10%'}}>{t(`${idioma}.NumeroLinea`)}</td>
                    <td style={{width: '25%'}} colSpan={2}>{t(`${idioma}.NumeroParte`)}</td>
                    <td style={{width: '15%'}} colSpan={2}>{t(`${idioma}.HorarioB`)}</td>
                    <td style={{width: '13%'}}>ECCN</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.PaisOrigen`)}</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.Cantidad`)}</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.ValorUnitario`)} (USD)</td>
                    <td style={{width: '10%'}}>{t(`${idioma}.ValorTotal`)} (USD)</td>
                </tr>
                :
                <tr>
                    <td style={{width: '10%'}}>{t(`${idioma}.NumeroLinea`)}</td>
                    <td style={{width: '25%'}} colSpan={2}>{t(`${idioma}.NumeroParte`)}</td>
                    <td style={{width: '15%'}} colSpan={2}>{t(`${idioma}.Cantidad`)}</td>
                    <td style={{width: '13%'}}>{t(`${idioma}.LargoPalet`)} (in)</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.AnchoPalet`)} (in)</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.AlturaPalet`)} (in)</td>
                    <td style={{width: '9%'}}>{t(`${idioma}.PesoNeto`)} (lbs)</td>
                    <td style={{width: '10%'}}>{t(`${idioma}.PesoBruto`)} (lbs)</td>
                </tr>
            }

            {tiposRack.map((tipoRack, index) =>
                <React.Fragment key={tipoRack.consecutivo}>
                    {esFactura ?
                        <tr style={{height: 34}}>
                            <td>{tipoRack.consecutivo}</td>
                            <td colSpan={2} style={{textAlign: 'left'}}>{tipoRack.name}</td>
                            <td colSpan={2}>{tipoRack.horarioB}</td>
                            <td>{tipoRack.eccn}</td>
                            <td>{viaje.plantaOrigen?.pais}</td>
                            <td>{tipoRack.cantidad}</td>
                            <td style={{textAlign: 'right'}}>{obtenerPrecio(tipoRack, tipoRack.valor)}</td>
                            <td style={{textAlign: 'right'}}>
                                {obtenerPrecio(tipoRack, tipoRack.cantidad * tipoRack.valor)}
                            </td>
                        </tr>
                        :
                        <tr style={{height: 34}}>
                            <td>{index + 1}</td>
                            <td colSpan={2} style={{textAlign: 'left'}}>{tipoRack.name}</td>
                            <td colSpan={2}>{tipoRack.cantidad}</td>
                            <td>{tipoRack.largo}</td>
                            <td>{tipoRack.ancho}</td>
                            <td>{tipoRack.alto}</td>
                            <td>{tipoRack.peso}</td>
                            <td style={{textAlign: 'right'}}>{tipoRack.pesoTotal}</td>
                        </tr>
                    }
                </React.Fragment>
            )}

            <tr style={{height: '17.5px'}}>
                {esFactura ?
                    <>
                        <td colSpan={9} style={{fontWeight: 700, textAlign: 'right'}}>{t("TOTAL")}</td>
                        <td style={{fontWeight: 700, textAlign: 'right'}}>
                            ${formatDecimal(resumenDocumento.valorTotal)}
                        </td>
                    </>
                    :
                    <td colSpan={10}/>
                }
            </tr>

            <tr>
                <td>{t(`${idioma}.TotalPaquetes`)}</td>
                <td colSpan={8} rowSpan={3}>
                    {idioma === 'en' &&
                    <span>
                        These items are controlled by the U.S. government and authorized for export only
                        to the country of ultimate destination for use by the ultimate consignee or
                        end-user(s) herein identified. They may not be resold, transferred, or otherwise
                        disposed of, to any other country or to any person other than the authorized
                        ultimate consignee or end-user(s), either in their original form or after being
                        incorporated into other items, without first obtaining approval from the U.S.
                        government or as otherwise authorized by U.S. law and regulations.
                        </span>
                    }
                </td>
                <td>{t(`${idioma}.PesoTotal`)} (lbs)</td>
            </tr>

            <tr>
                <td>{resumenDocumento.totalRacks}</td>
                <td>{resumenDocumento.pesoTotal}</td>
            </tr>

            <tr>
                <td>{t(`${idioma}.Pagina`)}</td>
                <td>{t(`${idioma}.PesoTotal`)} (kg)</td>
            </tr>

            <tr>
                <td>{numeroPagina}</td>
                <td colSpan={8}>{t(`${idioma}.DeclaracionDocumento`)}
                </td>
                <td>{(resumenDocumento.pesoTotal * 0.453592).toFixed(0)}</td>
            </tr>
            </tbody>
        </table>
    )
}
