/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SidebarRight from "../../shared/modal/SidebarRight";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import useValidate from "../../../hooks/useValidate";
import {ValidationContext} from "../../shared/validationContext";
import FieldTextBox from "../../shared/fields/FieldTextBox";
import useApiPerfiles from "../../../api/catalogs/useApiPerfil";
import profileValidationSchema from "./profileValidation";
import FieldCheckBox from "../../shared/fields/FieldCheckBox";
import {TIPO_MENUS} from "../../../utility/constants";
import produce from "immer";
import {ChevronRight,  ExpandLess} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";


export default function ProfileDetail({id, listaMenus, close, onSave}) {
    const api = useApiPerfiles();
    const {t} = useTranslation();
    const open = id != null;

    const [nodes, setNodes] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [perfil, setPerfil] = useState({});
    const [errors, setErrors] = useState({});
    const [validate, clearValidation] = useValidate(profileValidationSchema, perfil, setErrors);

    useEffect(() => {
        setNodes(listaMenus);
    }, [listaMenus]);


    useEffect(() => {
        if (id != null)
            load().then();

        clearValidation();
    }, [id]);

    const load = async () => {
        let result;

        if (id === 0) {
            setTitulo("Agregar");
            result = {activo: true}
            seleccionarMenus([]);
        } else {
            setTitulo("Editar");
            result = await api.getById(id);
            seleccionarMenus(result.menus);
        }

        setPerfil(result);
    }

    const onChange = (name, value) => {
        console.log(name, value);
        let newValue = {...perfil, [name]: value};
        setPerfil(newValue);
    };

    async function save() {
        const valid = await validate();
        if (!valid) return;
        await api.save(perfil);
        onSave();
    }

    const seleccionarMenus = (menus) => {
        const newNodes = produce(nodes, (draft) => {
            draft.forEach(m => {
                const menu = menus.find(n => n.id === m.id);
                m.consulta = menu != null;
                m.readOnly = menu?.readOnly??false;
            });
        });

        setNodes(newNodes);
    };

    const obtenerPadding = (item) => {
        let padding = 0;
        if (item.level > 0) {
            padding = 16 * item.level;
            padding = item.isLeaf ? padding + 50 : padding;
        } else if (item.isLeaf) {
            padding = 35;
        }

        return padding + "px";
    };

    const ocultarFilas = (padreExpandido, filas, draft) => {
        filas.forEach(f => {
            const index = nodes.findIndex(n => n.id === f.id);
            draft[index].visible = padreExpandido;

            const filaHija = nodes[index];

            if (filaHija.expandido || !padreExpandido) {
                const filasVisibles = padreExpandido && filaHija.expandido;
                const filasHijas = nodes.filter(n => n.parentId === filaHija.id);
                ocultarFilas(filasVisibles, filasHijas, draft);
            }
        })
    };

    const expandir = (item, index) => {
        const expandido = !item.expandido;

        const newNodes = produce(nodes, (draft) => {
            draft[index].expandido = expandido;

            const filasHijas = nodes.filter(n => n.parentId === item.id);
            ocultarFilas(expandido, filasHijas, draft);
        });

        setNodes(newNodes);
    };

    const seleccionarHijos = (filas, name, seleccionado, draft) => {
        filas.filter(e=>e.type === TIPO_MENUS.menu).forEach(f => {
            const index = nodes.findIndex(n => n.id === f.id );
            draft[index][name] = seleccionado;

            const filasHijas = nodes.filter(n => n.parentId === f.id);
            seleccionarHijos(filasHijas, name, seleccionado, draft)
        });
    };

    const seleccionaPadre = (item, name, seleccionado, draft) => {
        const indexFilaPadre = nodes.findIndex(n => n.id === item.parentId);

        if (indexFilaPadre >= 0) {
            const filaPadre = nodes[indexFilaPadre];
            const filasHijas = nodes.filter(n => item.id !== n.id && n.parentId === filaPadre.id);
            const padreSeleccionado = seleccionado || filasHijas.some(f => f[name]);

            draft[indexFilaPadre][name] = padreSeleccionado;
            seleccionaPadre(filaPadre, name, padreSeleccionado, draft);
        }
    };

    const seleccionar = (name, checked, item, index) => {

        const newNodes = produce(nodes, (draft) => {
            draft[index][name] = checked;
            if (!draft[index].consulta){
                draft[index].readOnly = false;
            }
            const filasHijas = nodes.filter(n => n.parentId === item.id);
            seleccionarHijos(filasHijas, name, checked, draft);
            seleccionaPadre(item, name, checked, draft);
        });


        setNodes(newNodes);

        const menusSeleccionados = newNodes.filter(n => n.consulta || n.readOnly);
        onChange("menus", menusSeleccionados)
    };


    return (
        <>
            <SidebarRight title={`${t(titulo)} ${t("Perfil")}`} open={open} close={close} size={700}>
                <ValidationContext.Provider value={{errors, setErrors}}>
                    <Grid container spacing={2}>
                        <FieldTextBox label="Nombre" name="name" value={perfil.name} xs={8} onChange={onChange}/>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12}>
                            <table className="table-detail">
                                <thead>
                                <tr>
                                    <th style={{width: '40%'}}>{t('Menu')}</th>
                                    <th style={{width: '25%'}}>
                                        <label htmlFor="consulta">{t('')}</label>
                                    </th>
                                    <th scope="col" className="center" style={{width: '25%'}}>
                                        <label htmlFor="readOnly">{t('Solo Lectura')}</label>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {nodes?.map((item, index) => {
                                    return (
                                        <>
                                            {(item.level === 0 || item.visible) &&
                                                <tr key={index}>
                                                    <td>
                                                        <div style={{paddingLeft: obtenerPadding(item)}}>
                                                            {!item.isLeaf &&
                                                                <IconButton aria-label="delete"  size="small"
                                                                            onClick={() => expandir(item, index)}>
                                                                    {item.expandido ?
                                                                        <ExpandLess/> :
                                                                        <ChevronRight/>
                                                                    }
                                                                </IconButton>
                                                            }
                                                            {t(item.name)}
                                                        </div>
                                                    </td>
                                                    <td className="center option ok">
                                                            <FieldCheckBox name="consulta"
                                                                           onChange={(name, checked) => seleccionar(name, checked, item, index)}
                                                                           value={item.consulta}/>
                                                    </td>
                                                    <td className="center option no-ok">
                                                        {!item.hasSubMenus && item.type === TIPO_MENUS.menu &&
                                                            <FieldCheckBox name="readOnly"
                                                                           disabled={!item.consulta}
                                                                           onChange={(name, checked) => seleccionar(name, checked, item, index)}
                                                                           value={item.readOnly}/>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </ValidationContext.Provider>
                <div>
                    <Box display="flex" justifyContent="flex-end" sx={{gap: '10px'}}>
                        <Button variant="outlined" size="small" startIcon={<CloseIcon/>} onClick={close}>
                            {t('Cerrar')}
                        </Button>
                        <Button variant="contained" color="success" size="small" startIcon={<SaveIcon/>}
                                onClick={save}>
                            {t('Guardar')}
                        </Button>
                    </Box>
                </div>
            </SidebarRight>
        </>
    );
}
