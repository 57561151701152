/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import Box from "@mui/material/Box";
import Filter from "../../shared/Filter";
import useApiTransportista from "../../../api/catalogs/useApiTransportista";
import CarrierDetail from "./CarrierDetail";
import FieldTextBox from "../../shared/fields/FieldTextBox";

export default function Carrier() {
    const {t} = useTranslation();
    const api = useApiTransportista();

    const [grid, setGrid] = useState({data: []});
    const [filtro, setFiltro] = useState({});
    const filtroDefault = {};
    const [id, setId] = useState(null);


    const buscar = async (filtro) => {
        const result = await api.filter(filtro);
        setFiltro(filtro);
        setGrid({data: result});
    };

    const onSave = async () => {
        setId(null)
        await buscar(filtro);
    };

    const verDetalle = (id) => {
        setId(id);
    };

    const HeaderAddButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="contained" size="small" startIcon={<AddIcon/>} onClick={() => verDetalle(0)}>
                {t('Agregar')}
            </Button>
        </div>
    );

    const ViewDetailButton = ({data}) => (
        <div className="ag-grid row-icons">
            <Button variant="outlined" size="mini" onClick={() => verDetalle(data.id)}>
                {t('Ver')}
            </Button>
        </div>
    );

    const EstatusValueCell = ({data}) => <span>{data.activo ? t("SI") : t("NO")}</span>;

    return (
        <>
            <Filter titulo={t('Plantas')} valorDefault={filtroDefault} buscar={buscar}>
                <FormaFiltros/>
            </Filter>

            <Box className="ag-theme-alpine main-grid">
                <AgGridReact
                    defaultColDef={agGridHelper.defaultColumns}
                    frameworkComponents={
                        {
                            goToDetail: agGridHelper.GoToDetailFormatter,
                            headerAddButton: HeaderAddButton,
                            estatusValueCell: EstatusValueCell,
                            viewDetailButton: ViewDetailButton,
                        }
                    }
                    getRowNodeId={(data) => data.id}
                    rowData={grid.data}
                >
                    <AgGridColumn field="name" headerName={t('Planta')}/>
                    <AgGridColumn field="activo" headerName={t('Activo')} cellRenderer="estatusValueCell"/>
                    <AgGridColumn maxWidth={150} cellRenderer="viewDetailButton" cellClass={'center'}
                                  headerComponent="headerAddButton" headerClass="center"/>
                </AgGridReact>
            </Box>

            <CarrierDetail id={id}  close={() => setId(null)} onSave={onSave}/>
        </>
    );
}

const FormaFiltros = ({filtro, onChange}) => {
    const {t} = useTranslation();
    return (
        <>
            <FieldTextBox label={t('Nombre')} name="name" sm={6} md={3}
                                      value={filtro.name} onChange={onChange}/>
        </>
    )
};
