import * as yup from "yup";

const materialSchema = yup.object().shape({
    name: yup.string().trim()
        .when("nameTraducido", {
            is: "",
            then: yup.string().required()
        }),
    valor: yup.number().required(),
    porcentajeOcupacion: yup.number().required().max(100, ""),
    descripcionLogistica: yup.string().trim().required(),
    materialSat: yup.string().trim().required(),
    descripcionMaterialSat: yup.string().trim().required(),
    fraccionArancelaria: yup.string().trim().required()
});

export default materialSchema;


